import React, { useState } from 'react';
import { useListReportedChatMessagesQuery } from '../../generated/gql';
import { useErrorModal } from '../../hooks/useErrorModal';
import Loader from '../Main/Loader';
import ReportedMessagesTable from './ReportedMessagesTable';

const ReportedMessages = () => {
	const [showProcessed, setShowProcessed] = useState(false);
	const { error, data, loading, refetch } = useListReportedChatMessagesQuery({
		variables: {
			processed: showProcessed,
		},
	});
	useErrorModal({ error });

	if (loading) return <Loader />;

	return (
		<div>
			<p className="text-white text-right">
				Show processed{' '}
				<input
					type="checkbox"
					checked={showProcessed}
					onChange={() => {
						setShowProcessed(!showProcessed);
					}}
				/>
			</p>
			<ReportedMessagesTable
				loading={loading}
				refetch={refetch}
				limit={data?.listReportedChatMessages?.limit!}
				offset={data?.listReportedChatMessages?.offset!}
				total={data?.listReportedChatMessages?.total!}
				messages={data?.listReportedChatMessages?.result!}
			/>
		</div>
	);
};

export default ReportedMessages;
