import Loader from 'react-loader-spinner';
import React from 'react';

const RingsLoader = () => (
	<div className="w-screen h-screen absolute bottom-0 left-0 flex items-center justify-center">
		<Loader type="Rings" color="#319795" height={125} width={125} />
	</div>
);

export default RingsLoader;
