import React from 'react';

export const NotFound = () => {
	return (
		<div className="flex h-screen bg-dark-900 justify-center">
			{/* Page Not Found! */}
			<h1>404: page not found!</h1>
		</div>
	);
};
