import React, { Dispatch, ReactChild, SetStateAction } from 'react';
import Loader from 'react-loader-spinner';

export default function Modal({
	visible,
	setVisible,
	title,
	children,
	onActionButton,
	actionName,
	actionColor = 'green',
	actionDisabled = false,
	actionLoading,
	onCloseButton,
}: {
	visible: boolean;
	setVisible: Dispatch<SetStateAction<boolean>>;
	title: string;
	children: ReactChild;
	onActionButton?: () => any;
	actionName?: string;
	actionColor?: 'red' | 'green' | 'orange';
	actionDisabled?: boolean;
	actionLoading?: boolean;
	onCloseButton?: () => void;
}) {
	return (
		<>
			{visible ? (
				<>
					<div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
						<div className="relative w-auto my-6 mx-auto max-w-3xl">
							{/*content*/}
							<div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
								{/*header*/}
								<div className="flex items-start justify-between p-5 border-b border-solid border-gray-300 rounded-t">
									<h3 className="text-3xl text-gray-800 font-semibold">
										{title}
									</h3>
								</div>
								{/*body*/}
								{children}
								{/*footer*/}
								<div className="flex items-center justify-end p-6 border-t border-solid border-gray-300 rounded-b">
									<button
										className="text-gray-800 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1"
										type="button"
										style={{ transition: 'all .15s ease' }}
										onClick={() => {
											console.log('ici')
											if (onCloseButton) {
												onCloseButton();
											}
											setVisible(false);
										}}
									>
										Close
									</button>
									{onActionButton && (
										<button
											className={
												actionDisabled
													? `bg-${actionColor}-300 text-white font-bold uppercase text-sm px-6 py-3 rounded shadow outline-none focus:outline-none mr-1 mb-1`
													: `bg-${actionColor}-500 text-white active:bg-${actionColor}-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1`
											}
											type="button"
											style={{ transition: 'all .15s ease' }}
											onClick={() => {
												if (actionDisabled) return;
												onActionButton();
											}}
										>
											{actionLoading && (
												<Loader
													type="Rings"
													color="#fff"
													height={40}
													width={40}
												/>
											)}
											{!actionLoading && (actionName || 'Ok')}
										</button>
									)}
								</div>
							</div>
						</div>
					</div>
				</>
			) : null}
		</>
	);
}
