import React from 'react';
import { capitalizeName } from '../utils/string';
import Loader from '../components/Main/Loader';
import { useErrorModal } from '../hooks/useErrorModal';
import moment from 'moment';
import { useMeQuery } from '../generated/gql';
import DailyStats from '../components/DailyStats/DailyStats';

const yesterday = new Date();
yesterday.setUTCDate(yesterday.getUTCDate() - 1);

export default function Home() {
	const { data, loading, error } = useMeQuery({
		fetchPolicy: 'cache-first',
	});

	useErrorModal({ error });

	if (loading) {
		return <Loader />;
	}

	return (
		<div>
			<div>
				<h1>Welcome, {capitalizeName(data?.me?.firstName!)}</h1>
				<h2>Yesterday, {moment(yesterday).format('LL')}</h2>
			</div>
			<DailyStats />
		</div>
	);
}
