import React, { useCallback, useState } from 'react';
import { useErrorModal } from '../hooks/useErrorModal';
import Loader from '../components/Main/Loader';
import {useListPublicWodCategoriesQuery } from '../generated/gql';
import WodCategoriesTable from '../components/WodCategories/WodCategoriesTable';
import AddCategory from '../components/WodCategories/AddCategory';

export default function WodsCategories() {
	const [refetchLoading, setRefetchLoading] = useState(false);
	const { data, error, loading, refetch } = useListPublicWodCategoriesQuery({
		nextFetchPolicy: "cache-only",
		variables: {
			showUnpublished: true,
		},
	});
	useErrorModal({
		error,
		onRetry: () => refetch(),
	});

	const onRefetch = useCallback(
		(variables: any) => {
			setRefetchLoading(true);
			refetch(variables).finally(() => {
				setRefetchLoading(false);
			});
		},
		[refetch]
	);

	if (loading) {
		return <Loader />;
	}

	if (error) {
		return null;
	}

	return (
		<div className="h-full">
			<h1>Wod Categories</h1>
			<AddCategory />
			{data?.listPublicWodCategories?.result && (
				<WodCategoriesTable
					wodCategories={data?.listPublicWodCategories?.result}
					limit={data?.listPublicWodCategories?.limit!}
					total={data?.listPublicWodCategories?.total!}
					offset={data?.listPublicWodCategories?.offset!}
					refetch={onRefetch}
					loading={loading || refetchLoading}
				/>
			)}
		</div>
	);
}
