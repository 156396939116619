import React, { useCallback, useState } from 'react';
import {
	ShowReportedChatMessageQuery,
	useDeleteReportedChatMessageMutation,
	useSetReportedMessageProcessedMutation,
	useShowReportedChatMessageQuery,
} from '../generated/gql';
import { useErrorModal } from '../hooks/useErrorModal';
import moment from 'moment';
import Loader from '../components/Main/Loader';
import Modal from '../components/Main/Modal';

const ReportedChatMessage = (props: any) => {
	const { error, data, loading } = useShowReportedChatMessageQuery({
		variables: {
			messageId: props?.match?.params?.id,
		},
	});
	useErrorModal({ error });

	if (loading) return <Loader />;

	return (
		<div>
			<h1>Reported message</h1>
			<div className="text-white">
				{data?.showReportedChatMessage?.previousMessages?.map((m, i) => {
					console.log(m);
					if (m!.sender === data?.showReportedChatMessage?.message?.sender) {
						return (
							<div
								key={i}
								className="bg-gray-200 w-full md:w-3/4 xl:w-1/2 rounded-lg my-4 px-3 py-2 text-dark-200"
							>
								<h4 className="text-dark-200">
									{m?.deleted ? 'Message has been deleted' : m!.content}
								</h4>
								{!m?.deleted && m?.attachment?.url && (
									<img className="w-1/3" src={m?.attachment?.url} alt="" />
								)}
								<p>{moment(m!.createdAt).format('LLL')}</p>
								<p className="text-right">Sender : {m!.sender}</p>
							</div>
						);
					}

					return (
						<div
							key={i}
							className="bg-blue-500 w-full md:w-3/4 xl:w-1/2 rounded-lg my-4 px-3 py-2"
						>
							<h4>{m?.deleted ? 'Message has been deleted' : m!.content}</h4>
							{!m?.deleted && m?.attachment?.url && (
								<img className="w-1/3" src={m?.attachment?.url} alt="" />
							)}
							<p>{moment(m!.createdAt).format('LLL')}</p>
							<p className="text-right">Sender : {m!.sender}</p>
						</div>
					);
				})}
			</div>
			<div className="bg-gray-200 w-full md:w-3/4 xl:w-1/2 rounded-lg my-4 px-3 py-2 border-4 border-red-500">
				<h4 className="text-dark-200">
					{data?.showReportedChatMessage?.message?.deleted
						? 'Message has been deleted'
						: data?.showReportedChatMessage?.message?.content}
				</h4>
				{!data?.showReportedChatMessage?.message?.deleted &&
					data?.showReportedChatMessage?.message?.attachment?.url && (
						<img
							className="w-1/3"
							src={data?.showReportedChatMessage?.message?.attachment?.url}
							alt=""
						/>
					)}
				<p>{moment(data?.showReportedChatMessage?.createdAt).format('LLL')}</p>
				<p className="text-right">
					Sender : {data?.showReportedChatMessage?.message?.sender}
				</p>
			</div>
			<div>
				{!data?.showReportedChatMessage?.processed && (
					<MarkAsProcessed reportedMessage={data?.showReportedChatMessage} />
				)}
				<DeleteMessage reportedMessage={data?.showReportedChatMessage} />
			</div>
		</div>
	);
};

const MarkAsProcessed = ({ reportedMessage }: any) => {
	const [
		mutate,
		{ error, loading, data },
	] = useSetReportedMessageProcessedMutation();

	useErrorModal({ error });

	const onClick = useCallback(() => {
		mutate({
			variables: {
				reportId: reportedMessage.id,
			},
		}).catch(() => {});
	}, [reportedMessage, mutate]);

	if (data) {
		return null;
	}

	return (
		<button className="bg-gray-200 px-3 py-2 mx-3" onClick={onClick}>
			{loading && '...'}
			{!loading && 'Mark as processed'}
		</button>
	);
};

const DeleteMessage = ({
	reportedMessage,
}: {
	reportedMessage: ShowReportedChatMessageQuery['showReportedChatMessage'];
}) => {
	const [
		mutate,
		{ error, loading, data },
	] = useDeleteReportedChatMessageMutation();
	const [modalVisible, setModalVisible] = useState(false);
	const [inputValue, setInputValue] = useState('');

	useErrorModal({ error });

	const onClick = useCallback(() => {
		setModalVisible(true);
	}, [setModalVisible]);

	const onAction = useCallback(() => {
		mutate({
			variables: {
				messageId: reportedMessage?.message?.id!,
			},
		}).then(() => {
			setModalVisible(true);
		});
	}, [reportedMessage, mutate]);

	if (data) return null;

	if (reportedMessage?.message?.deleted) return null;

	return (
		<div className="inline-block">
			<Modal
				visible={modalVisible}
				setVisible={() => {
					setModalVisible(false);
				}}
				title="Delete chat message"
				onActionButton={onAction}
				actionName="Delete"
				actionColor="red"
				actionDisabled={inputValue !== 'Delete'}
				actionLoading={loading}
			>
				<div className="relative p-6 flex-auto">
					<p className="my-4 text-gray-600 text-lg leading-relaxed">
						Are you sure you want to delete this message ?
					</p>
					<p className="my-4 text-gray-600 text-lg leading-relaxed">
						Please, type :{' '}
						<span className="font-semibold text-gray-700">"Delete"</span>
					</p>
					<input
						value={inputValue}
						onChange={(e) => {
							setInputValue(e.target.value);
						}}
						type="text"
						placeholder="Nickname"
						className="px-3 py-3 placeholder-gray-400 text-gray-700 relative bg-white bg-white rounded text-sm shadow outline-none focus:outline-none focus:shadow-outline w-full bg-gray-200"
					/>
					{error && <p className="text-red-600">An error has occured :(</p>}
				</div>
			</Modal>
			<button
				className="bg-red-700 px-3 py-2 mx-3 text-white"
				onClick={onClick}
			>
				{loading && '...'}
				{!loading && 'Delete this message'}
			</button>
		</div>
	);
};

export default ReportedChatMessage;
