const config = {
	Auth: {
		region: 'eu-central-1',
		userPoolId: process.env.REACT_APP_USER_POOL_ID,
		userPoolWebClientId: process.env.REACT_APP_USER_POOL_WEB_CLIENT_ID,
		authenticationFlowType: 'USER_SRP_AUTH',
		AdvancedSecurityDataCollectionFlag: false,
	},
	oauth: {
		domain: process.env.REACT_APP_AUTH_DOMAIN,
		scope: ['profile', 'openid', 'email', 'aws.cognito.signin.user.admin'],
		AdvancedSecurityDataCollectionFlag: false,
		redirectSignIn: process.env.REACT_APP_DOMAIN_NAME,
		redirectSignOut: process.env.REACT_APP_DOMAIN_NAME,
		responseType: 'code',
	},
};

export default config;
