import React from 'react';
import { Redirect, Route } from 'react-router-dom';

export default function UnauthenticatedRoute({
	component: C,
	appProps,
	...rest
}: any) {
	return (
		<Route
			{...rest}
			render={(props) =>
				!appProps.isAuthenticated ? (
					<C {...props} {...appProps} />
				) : (
					<Redirect to="/" />
				)
			}
		/>
	);
}
