import React from 'react';
import ReportedMessages from '../components/Moderation/ReportedMessages';
import ReportedComments from '../components/Moderation/ReportedComments';
import { ArchiveProgram } from '../components/Moderation/ArchiveProgram';

const Moderation = () => {
	return (
		<div>
			<h1>Moderation</h1>
			<h2>Chat messages</h2>
			<ReportedMessages />
			<h2>Comments</h2>
			<ReportedComments />
			<h2>Archive program</h2>
			<ArchiveProgram />
		</div>
	);
};

export default Moderation;
