import React, { useCallback, useEffect, useState } from 'react';
import { useErrorModal } from '../hooks/useErrorModal';
import { useListPublicWodsLazyQuery } from '../generated/gql';
import SelectCategory from '../components/Wodbook/SelectCategory';
import WodTable from '../components/Wodbook/WodTable';
import { useHistory } from 'react-router-dom';
import Loader from '../components/Main/Loader';

export default function WodBook() {
	const history = useHistory();
	const [refetchLoading, setRefetchLoading] = useState(false);
	const [listPublicWods, { data, error, loading, refetch }] = useListPublicWodsLazyQuery();
	useErrorModal({
		error,
		onRetry: () => refetch && refetch(),
	});

	const onRefetch = useCallback(
		(variables: any) => {
			setRefetchLoading(true);
			if(refetch)
				refetch(variables).finally(() => {
					setRefetchLoading(false);
				});
		},
		[refetch]
	);

	const onCategoryChange = (categoryId: string) => {
		listPublicWods({
			variables: {
				categoryId: categoryId,
			},
		});
	}

	if (error) {
		return null;
	}

	return (
		<div className="h-full">
			<h1>WodBook</h1>
			<div className="flex justify-end items-center">
				<button className="text-white mr-10 p-3" onClick={() => history.push('/wodBook/create')}>Create WOD</button>
				<SelectCategory onValueChange={onCategoryChange}/>
			</div>
			{data?.listPublicWods?.result && (
				<WodTable
					wods={data?.listPublicWods?.result}
					limit={data?.listPublicWods?.limit!}
					total={data?.listPublicWods?.total!}
					offset={data?.listPublicWods?.offset!}
					refetch={onRefetch}
					loading={loading || refetchLoading}
				/>
			)}
		</div>
	);
}
