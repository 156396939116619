import React, { useCallback, useMemo } from 'react';
import { useTable } from 'react-table';
import Loader from 'react-loader-spinner';
import { capitalizeName } from '../../utils/string';
import { useHistory } from 'react-router-dom';
import useWindowDimensions from '../../hooks/useWindowDimensions';

const WodTable = ({
	wods,
	total,
	limit,
	offset,
	refetch,
	loading,
}: {
	wods: any;
	total: number;
	limit: number;
	offset: number;
	refetch: (variables: any) => any;
	loading: boolean;
}) => {
	const history = useHistory();
	const dimensions = useWindowDimensions();
	const columns = useMemo(() => {
		const cols = [
			{
				Header: 'id',
				accessor: 'id', // accessor is the "key" in the data
				hiddenSmall: false,
			},
			{
				Header: 'name',
				accessor: 'name', // accessor is the "key" in the data
				hiddenSmall: false,
			},
			{
				Header: 'Published',
				accessor: 'published', // accessor is the "key" in the data
				hiddenSmall: false,
			},
		];
		if (dimensions.width < 1210) return cols.filter((col) => !col.hiddenSmall);
		return cols;
	}, [dimensions]);

	const data = useMemo(() => {
		return (
			wods?.map((wod: any) => ({
				...wod,
				name: capitalizeName(wod?.exercise?.nameTranslations[0]?.name),
				published: wod?.published ? '✔' : '❌'
			})) || []
		);
	}, [wods]);

	const {
		getTableProps,
		getTableBodyProps,
		headerGroups,
		rows,
		prepareRow,
	} = useTable({ columns, data });

	const maxPage = useMemo(() => {
		return Math.ceil(total / limit);
	}, [total, limit]);

	const currentPage = useMemo(() => {
		return offset / limit + 1;
	}, [limit, offset]);

	const loadNextPage = useCallback(() => {
		refetch({
			offset: offset + limit,
			limit: limit,
		});
	}, [offset, limit, refetch]);
	const loadPreviousPage = useCallback(() => {
		refetch({
			offset: offset - limit,
			limit,
		});
	}, [offset, limit, refetch]);

	const onRowSelected = useCallback(
		(row) => {
			history.push('/wodbook/' + row.original.id);
		},
		[history]
	);

	return (
		<div>
			<table
				{...getTableProps()}
				className="bg-dark-400 w-full text-white my-3"
			>
				<thead>
					{headerGroups.map((headerGroup) => (
						<tr className="text-left" {...headerGroup.getHeaderGroupProps()}>
							{headerGroup.headers.map((column) => (
								<th
									className={`p-4 w-1/${columns.length}`}
									{...column.getHeaderProps()}
								>
									{column.render('Header')}
								</th>
							))}
						</tr>
					))}
				</thead>
				<tbody {...getTableBodyProps()}>
					{!loading && rows?.length === 0 && (
						<tr>
							<td colSpan={columns.length}>
								<div className="flex justify-center items-center py-20">
									<p className="text-xl">No result :(</p>
								</div>
							</td>
						</tr>
					)}
					{loading && (
						<tr>
							<td colSpan={columns.length}>
								<div className="flex justify-center items-center py-20">
									<Loader type="Rings" color="#319795" height={85} width={85} />
								</div>
							</td>
						</tr>
					)}
					{!loading &&
						rows.map((row) => {
							prepareRow(row);
							return (
								<tr
									className="border-dark-900 border-2 hover:bg-dark-900 cursor-pointer"
									{...row.getRowProps()}
									onClick={() => onRowSelected(row)}
								>
									{row.cells.map((cell) => {
										return (
											<td className="p-4" {...cell.getCellProps()}>
												{cell.render('Cell')}
											</td>
										);
									})}
								</tr>
							);
						})}
				</tbody>
			</table>
			<div className="flex justify-between">
				<p className="text-white">Total : {total}</p>
				<div className="flex">
					<p className="text-white mx-4">
						Page : {currentPage} / {maxPage}
					</p>
					{currentPage > 1 && (
						<button className="text-white mx-2" onClick={loadPreviousPage}>
							Previous page
						</button>
					)}
					{currentPage < maxPage && (
						<button className="text-white mx-2" onClick={loadNextPage}>
							Next page
						</button>
					)}
				</div>
			</div>
		</div>
	);
};

export default WodTable;
