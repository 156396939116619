import React from 'react';
import { useUpdateProgramMutation } from '../../generated/gql';

export const ArchiveProgram = () => {
	const [updateProgram, { error, loading, data }] = useUpdateProgramMutation();
	const [programId, setProgramId] = React.useState('');

	if (loading) {
		return <p className="text-white">Archiving program ...</p>;
	}

	if (data) {
		return <p className="text-white">Program archived successfully</p>;
	}

	if (error) {
		return <p className="text-red-700">Error: {error.message}</p>;
	}

	return (
		<div>
			<div>
				<input
					type="text"
					onChange={(e) => {
						setProgramId(e.target.value);
					}}
					className="placeholder-gray-600 border px-2 h-8"
					placeholder="Program id"
				></input>
				<button
					className="ml-3 text-white hover:text-dark-800"
					onClick={() => {
						updateProgram({
							variables: {
								id: programId,
							},
						});
					}}
				>
					Archive
				</button>
			</div>
		</div>
	);
};
