import React, { useCallback, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Auth } from 'aws-amplify';
import TextInput from '../Form/TextInput';
import { FaFacebook } from 'react-icons/fa';
import { AiFillGoogleCircle } from 'react-icons/ai';
import { IoLogoAppleAppstore } from 'react-icons/io5';
import { CognitoHostedUIIdentityProvider } from '@aws-amplify/auth/lib/types';
import Loader from 'react-loader-spinner';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';

export default function LoginForm({ onLogin }: any) {
	const { register, handleSubmit, errors, setError } = useForm();
	const [loading, setLoading] = useState(false);

	const onSubmit = useCallback(
		(data: { email: string; password: string }) => {
			setLoading(true);
			Auth.signIn(data.email, data.password)
				.then(() => {
					onLogin();
				})
				.catch((err) => {
					console.error(err);
					setError('password', {
						type: 'validation',
						message: 'Invalid credentials',
					});
				})
				.finally(() => {
					setLoading(false);
				});
		},
		[onLogin, setError, setLoading]
	);

	const onPressSocialButton = useCallback(
		(provider: CognitoHostedUIIdentityProvider) => {
			Auth.federatedSignIn({ provider })
				.catch((e) => console.error('[AUTH] SOCIAL LOGIN ERROR', e))
				.then(() => {
					console.log('[AUTH] SOCIAL LOGIN SUCCESS');
				});
		},
		[]
	);

	return (
		<div className="flex w-full max-w-xs relative">
			{loading && (
				<div className="w-full h-full absolute bg-white opacity-75 flex items-center justify-center rounded">
					<Loader type="Rings" color="#319795" height={80} width={80} />
				</div>
			)}
			<form
				onSubmit={handleSubmit(onSubmit)}
				className="bg-white shadow-md rounded w-full px-8 pt-6 pb-8"
			>
				<TextInput
					errors={errors}
					register={register}
					name="email"
					placeholder="E-mail"
					rules={{ required: 'E-mail is required' }}
					type="text"
				/>
				<TextInput
					errors={errors}
					register={register}
					name="password"
					placeholder="Password"
					rules={{ required: 'Password is required' }}
					type="password"
				/>
				<div className="flex items-center mt-6">
					<button
						className="text-white bg-primary-500 hover:bg-primary-700 font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
						type="submit"
					>
						Sign In
					</button>
					<button
						onClick={(e) => {
							e.preventDefault();
							onPressSocialButton(CognitoHostedUIIdentityProvider.Facebook);
						}}
					>
						<FaFacebook size={25} className="mx-2 ml-4" color="#3742fa" />
					</button>
					<button
						onClick={(e) => {
							e.preventDefault();
							onPressSocialButton(CognitoHostedUIIdentityProvider.Google);
						}}
					>
						<AiFillGoogleCircle size={25} className="mx-2" color="#ff6348" />
					</button>
					<button
						onClick={(e) => {
							e.preventDefault();
							onPressSocialButton(CognitoHostedUIIdentityProvider.Apple);
						}}
					>
						<IoLogoAppleAppstore size={25} className="mx-2" color="#2f3542" />
					</button>
				</div>
			</form>
		</div>
	);
}
