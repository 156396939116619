import React, { useState } from 'react';
import { ListPublicWodsDocument, useDeletePublicWodMutation } from '../../generated/gql';
import update from 'immutability-helper';
import { useHistory } from 'react-router-dom';
import Modal from '../Main/Modal';


export const DeletePublicWodModal = ({
																setWodToDelete,
																wodToDelete,
																wodName,
																categoryId
															}: any) => {
	const [inputValue, setInputValue] = useState('');
	const [deletePublicWod, { error, loading }] = useDeletePublicWodMutation({
		update(cache, { data }) {
			const queryData: any = cache.readQuery({
				query: ListPublicWodsDocument,
				variables: {
					categoryId,
				},
			});

			if (queryData?.listPublicWods) {
				const index = queryData?.listPublicWods?.result.findIndex(
					(u: any) => u.id === data?.deletePublicWod
				);

				if (index === -1) return;

				cache.writeQuery({
					query: ListPublicWodsDocument,
					variables: {
						categoryId,
					},
					data: {
						listPublicWods: update(queryData.listPublicWods, {
							result: {
								$splice: [[index, 1]],
							},
							total: {
								$apply: (x: number) => x - 1,
							},
						}),
					}
				});
			}
		},
	});

	const history = useHistory();

	if (!wodToDelete) return null;

	return (
		<Modal
			visible={!!wodToDelete}
			setVisible={() => {
				setWodToDelete(null);
			}}
			title="Delete Public WOD"
			onActionButton={() => {
				deletePublicWod({
					variables: {
						wodId: wodToDelete?.id,
					},
				})
					.then(() => {
						setWodToDelete(null);
						history.push('/wodbook');
					})
					.catch((err) => {
						console.log(err);
					});
			}}
			actionName="Delete"
			actionColor="red"
			actionDisabled={inputValue !== `${wodName}`}
			actionLoading={loading}
		>
			<div className="relative p-6 flex-auto">
				<p className="my-4 text-gray-600 text-lg leading-relaxed">
					Are you sure you want to delete this WOD ?
				</p>
				<p className="my-4 text-gray-600 text-lg leading-relaxed">
					Please, type this WOD name :{' '}
					<span className="font-semibold text-gray-700">
						{wodName}
					</span>
				</p>
				<input
					value={inputValue}
					onChange={(e) => {
						setInputValue(e.target.value);
					}}
					type="text"
					placeholder="Wod Name"
					className="px-3 py-3 placeholder-gray-400 text-gray-700 relative bg-white bg-white rounded text-sm shadow outline-none focus:outline-none focus:shadow-outline w-full bg-gray-200"
				/>
				{error && <p className="text-red-600">An error has occured :(</p>}
			</div>
		</Modal>
	);
}
