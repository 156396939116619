import React from 'react';
import Navbar from '../components/Layout/Navbar';
import Sidebar from '../components/Layout/Sidebar';

const Main = ({ children }: { children: React.Component }) => {
	return (
		<div id="main" className="relative flex flex-col min-h-screen bg-dark-900">
			<Navbar />
			<div className="flex flex-1">
				<Sidebar />
				<div className="mx-10 mt-20 mb-10 w-full">{children}</div>
			</div>
		</div>
	);
};

export default Main;
