import React from 'react';
import DailyStat from './DailyStat';
import { useShowDailyStatsQuery } from '../../generated/gql';
import { useErrorModal } from '../../hooks/useErrorModal';
import Loader from 'react-loader-spinner';

const date = new Date();
date.setUTCDate(date.getUTCDate() - 1);

const DailyStats = () => {
	const { error, data, loading, refetch } = useShowDailyStatsQuery({
		variables: {
			date,
		},
	});

	useErrorModal({
		error,
		onRetry: () => refetch,
	});

	if (loading) {
		return <Loader type="Rings" color="#319795" height={85} width={85} />;
	}

	return (
		<div className="mt-3 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-5">
			<DailyStat
				number={data?.showDailyStats?.createdProfiles?.count!}
				title="New accounts"
				previousNumber={
					data?.showDailyStats?.createdProfiles?.previousDayCount!
				}
			/>
			<DailyStat
				number={data?.showDailyStats?.createdPrograms?.count!}
				previousNumber={
					data?.showDailyStats?.createdPrograms?.previousDayCount!
				}
				title="New programs"
			/>
			<DailyStat
				number={data?.showDailyStats?.addedScores?.count!}
				title="New scores added"
				previousNumber={data?.showDailyStats?.addedScores?.previousDayCount!}
			/>
			<DailyStat
				number={data?.showDailyStats?.createdSessions?.count!}
				title="New sessions created"
				previousNumber={
					data?.showDailyStats?.createdSessions?.previousDayCount!
				}
			/>
		</div>
	);
};

export default DailyStats;
