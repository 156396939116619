import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Date: any;
  /** The `Upload` scalar type represents a file upload. */
  Upload: any;
};

export type ChronoConfiguration = AhapChronoConfiguration | AmrapChronoConfiguration | EmomChronoConfiguration | ForTimeChronoConfiguration | TabataChronoConfiguration;

export type AddedScoresDailyStats = {
  __typename?: 'AddedScoresDailyStats';
  count?: Maybe<Scalars['Int']>;
  previousDayCount?: Maybe<Scalars['Int']>;
};

export type AhapChronoConfiguration = {
  __typename?: 'AhapChronoConfiguration';
  ahaps?: Maybe<Array<Maybe<AhapConfiguration>>>;
  type?: Maybe<ChronoConfigurationType>;
};

export type AhapConfiguration = {
  __typename?: 'AhapConfiguration';
  restTime?: Maybe<Scalars['Int']>;
  timeLimit?: Maybe<Scalars['Int']>;
};

export type AmrapChronoConfiguration = {
  __typename?: 'AmrapChronoConfiguration';
  roundConfiguration?: Maybe<ChronoRoundConfiguration>;
  setConfiguration?: Maybe<ChronoSetConfiguration>;
  type?: Maybe<ChronoConfigurationType>;
};

export type BirthDate = {
  __typename?: 'BirthDate';
  private?: Maybe<Scalars['Boolean']>;
  value?: Maybe<Scalars['Date']>;
};

export type ChatAvatar = {
  __typename?: 'ChatAvatar';
  url?: Maybe<Scalars['String']>;
};

export type ChatChannel = {
  __typename?: 'ChatChannel';
  /** Default : program avatar or partner profile picture */
  avatar?: Maybe<ChatAvatar>;
  blockedBy?: Maybe<Scalars['ID']>;
  coach?: Maybe<Scalars['ID']>;
  id: Scalars['ID'];
  latestMessage?: Maybe<LatestChannelMessage>;
  members?: Maybe<Array<Maybe<User>>>;
  name?: Maybe<Scalars['String']>;
  pubnub_id?: Maybe<Scalars['String']>;
  totalMembers?: Maybe<Scalars['Int']>;
  type?: Maybe<ChatChannelType>;
  unreadMessagesCount?: Maybe<Scalars['Int']>;
};

export type ChatChannelsStat = {
  __typename?: 'ChatChannelsStat';
  duo?: Maybe<Scalars['Int']>;
  program?: Maybe<Scalars['Int']>;
};

export type ChatMember = {
  __typename?: 'ChatMember';
  birthDate?: Maybe<Scalars['Date']>;
  firstName?: Maybe<Scalars['String']>;
  followedPrograms?: Maybe<Array<Maybe<Program>>>;
  foot_size?: Maybe<Scalars['Int']>;
  gender?: Maybe<Gender>;
  height?: Maybe<Scalars['Int']>;
  id: Scalars['ID'];
  isCoach?: Maybe<Scalars['Boolean']>;
  lastName?: Maybe<Scalars['String']>;
  location?: Maybe<UserLocation>;
  nickname?: Maybe<Scalars['String']>;
  ownedPrograms?: Maybe<Array<Maybe<Program>>>;
  personalRecords?: Maybe<ListPersonalRecordsResultTc>;
  profile_picture?: Maybe<ProfilePicture>;
  pubnub_uuid?: Maybe<Scalars['String']>;
  sportsRoom_name?: Maybe<Scalars['String']>;
  tshirt_size?: Maybe<TShirtSize>;
  weight?: Maybe<Scalars['Float']>;
};


export type ChatMemberPersonalRecordsArgs = {
  input?: Maybe<ListPersonalRecordsInput>;
};

export type ChatMessage = {
  __typename?: 'ChatMessage';
  attachment?: Maybe<ChatMessageAttachment>;
  channel: Scalars['ID'];
  content?: Maybe<Scalars['String']>;
  createdAt: Scalars['Date'];
  deleted?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  sender: Scalars['ID'];
  updatedAt: Scalars['Date'];
};

export type ChatMessageAttachment = {
  __typename?: 'ChatMessageAttachment';
  type?: Maybe<ChatMessageAttachmentType>;
  url?: Maybe<Scalars['String']>;
};

export type ChatMessagesStat = {
  __typename?: 'ChatMessagesStat';
  image?: Maybe<Scalars['Int']>;
  text?: Maybe<Scalars['Int']>;
  total?: Maybe<Scalars['Int']>;
};

export type ChronoRoundConfiguration = {
  __typename?: 'ChronoRoundConfiguration';
  number?: Maybe<Scalars['Int']>;
  restTime?: Maybe<Scalars['Int']>;
  timeLimit?: Maybe<Scalars['Int']>;
};

export type ChronoSetConfiguration = {
  __typename?: 'ChronoSetConfiguration';
  number?: Maybe<Scalars['Int']>;
  restTime?: Maybe<Scalars['Int']>;
};

export type City = {
  __typename?: 'City';
  private?: Maybe<Scalars['Boolean']>;
  value?: Maybe<Scalars['String']>;
};

export type Comment = {
  __typename?: 'Comment';
  attachment?: Maybe<CommentAttachment>;
  author?: Maybe<User>;
  canLike?: Maybe<Scalars['Boolean']>;
  content?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Date']>;
  id: Scalars['ID'];
  likes?: Maybe<Scalars['Int']>;
  replies?: Maybe<CommentReplies>;
  replyable?: Maybe<Scalars['Boolean']>;
  type?: Maybe<CommentType>;
};


export type CommentRepliesArgs = {
  input?: Maybe<CommentRepliesInput>;
};

export type CommentAttachment = {
  __typename?: 'CommentAttachment';
  type?: Maybe<CommentAttachmentType>;
  url?: Maybe<Scalars['String']>;
};

export type CommentLikedNotificationSettings = {
  __typename?: 'CommentLikedNotificationSettings';
  disabled?: Maybe<Scalars['Boolean']>;
};

export type CommentReplies = {
  __typename?: 'CommentReplies';
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  result?: Maybe<Array<Maybe<Comment>>>;
  total?: Maybe<Scalars['Int']>;
};

export type CommentReplyAddedNotificationSettings = {
  __typename?: 'CommentReplyAddedNotificationSettings';
  disabled?: Maybe<Scalars['Boolean']>;
};

export type Country = {
  __typename?: 'Country';
  private?: Maybe<Scalars['Boolean']>;
  value?: Maybe<Scalars['String']>;
};

export type CreatedProfilesDailyStats = {
  __typename?: 'CreatedProfilesDailyStats';
  count?: Maybe<Scalars['Int']>;
  previousDayCount?: Maybe<Scalars['Int']>;
};

export type CreatedProgramsDailyStats = {
  __typename?: 'CreatedProgramsDailyStats';
  count?: Maybe<Scalars['Int']>;
  previousDayCount?: Maybe<Scalars['Int']>;
};

export type CreatedSessionsDailyStats = {
  __typename?: 'CreatedSessionsDailyStats';
  count?: Maybe<Scalars['Int']>;
  previousDayCount?: Maybe<Scalars['Int']>;
};

export type DailyStats = {
  __typename?: 'DailyStats';
  addedScores?: Maybe<AddedScoresDailyStats>;
  createdProfiles?: Maybe<CreatedProfilesDailyStats>;
  createdPrograms?: Maybe<CreatedProgramsDailyStats>;
  createdSessions?: Maybe<CreatedSessionsDailyStats>;
};

export type EmomChronoConfiguration = {
  __typename?: 'EmomChronoConfiguration';
  emoms?: Maybe<Array<Maybe<EmomConfiguration>>>;
  type?: Maybe<ChronoConfigurationType>;
};

export type EmomConfiguration = {
  __typename?: 'EmomConfiguration';
  everyTime?: Maybe<Scalars['Int']>;
  restTime?: Maybe<Scalars['Int']>;
  roundsNumber?: Maybe<Scalars['Int']>;
  whileTime?: Maybe<Scalars['Int']>;
};

export type ExerciseBlock = {
  __typename?: 'ExerciseBlock';
  /** For text block */
  content?: Maybe<Scalars['String']>;
  /** For list block */
  elements?: Maybe<Array<Maybe<Scalars['String']>>>;
  id: Scalars['ID'];
  /** For image block */
  image?: Maybe<SessionExerciseBlockImage>;
  order?: Maybe<Scalars['Int']>;
  type?: Maybe<SessionExerciseBlockType>;
};

export type ExerciseExpectedScore = {
  __typename?: 'ExerciseExpectedScore';
  sort?: Maybe<SortType>;
  type?: Maybe<ScoreValueType>;
};

export type ExerciseScore = {
  __typename?: 'ExerciseScore';
  type?: Maybe<ScoreValueType>;
  value?: Maybe<Scalars['Float']>;
};

export type ExerciseScoreRankTc = {
  __typename?: 'ExerciseScoreRankTC';
  member?: Maybe<User>;
  position?: Maybe<Scalars['Int']>;
  score?: Maybe<Scalars['Float']>;
};

export type FootSize = {
  __typename?: 'FootSize';
  private?: Maybe<Scalars['Boolean']>;
  value?: Maybe<Scalars['Int']>;
};

export type ForTimeChronoConfiguration = {
  __typename?: 'ForTimeChronoConfiguration';
  setConfiguration?: Maybe<ChronoSetConfiguration>;
  timeLimit?: Maybe<Scalars['Int']>;
  type?: Maybe<ChronoConfigurationType>;
};

export type Height = {
  __typename?: 'Height';
  private?: Maybe<Scalars['Boolean']>;
  value?: Maybe<Scalars['Int']>;
};

export type LatestChannelMessage = {
  __typename?: 'LatestChannelMessage';
  attachment?: Maybe<ChatMessageAttachment>;
  content?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Date']>;
  deleted?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  sender?: Maybe<User>;
  updatedAt?: Maybe<Scalars['Date']>;
};

export type LeaveProgramResult = {
  __typename?: 'LeaveProgramResult';
  programId?: Maybe<Scalars['String']>;
};

export type ListChatChannelMembersResult = {
  __typename?: 'ListChatChannelMembersResult';
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  result?: Maybe<Array<Maybe<ChatMember>>>;
  total?: Maybe<Scalars['Int']>;
};

export type ListChatChannelMessagesResult = {
  __typename?: 'ListChatChannelMessagesResult';
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  result?: Maybe<Array<Maybe<ChatMessage>>>;
  total?: Maybe<Scalars['Int']>;
};

export type ListChatChannelsResult = {
  __typename?: 'ListChatChannelsResult';
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  result?: Maybe<Array<Maybe<ChatChannel>>>;
  total?: Maybe<Scalars['Int']>;
};

export type ListCommentRepliesResult = {
  __typename?: 'ListCommentRepliesResult';
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  result?: Maybe<Array<Maybe<Comment>>>;
  total?: Maybe<Scalars['Int']>;
};

export type ListInvitableProgramUsersResult = {
  __typename?: 'ListInvitableProgramUsersResult';
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  result?: Maybe<Array<Maybe<User>>>;
  total?: Maybe<Scalars['Int']>;
};

export type ListOwnedUsersResult = {
  __typename?: 'ListOwnedUsersResult';
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  result?: Maybe<Array<Maybe<OwnedUser>>>;
  total?: Maybe<Scalars['Int']>;
};

export type ListPersonalRecordsResultTc = {
  __typename?: 'ListPersonalRecordsResultTC';
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  result?: Maybe<Array<Maybe<PersonalRecord>>>;
  total?: Maybe<Scalars['Int']>;
};

export type ListProgramInvitationsResult = {
  __typename?: 'ListProgramInvitationsResult';
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  result?: Maybe<Array<Maybe<ProgramInvitation>>>;
  total?: Maybe<Scalars['Int']>;
};

export type ListProgramJoinRequestsResult = {
  __typename?: 'ListProgramJoinRequestsResult';
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  result?: Maybe<Array<Maybe<ProgramJoinRequest>>>;
  total?: Maybe<Scalars['Int']>;
};

export type ListProgramSessionCommentsResult = {
  __typename?: 'ListProgramSessionCommentsResult';
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  result?: Maybe<Array<Maybe<Comment>>>;
  total?: Maybe<Scalars['Int']>;
};

export type ListProgramSessionsResult = {
  __typename?: 'ListProgramSessionsResult';
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  result?: Maybe<Array<Maybe<ProgramSession>>>;
  total?: Maybe<Scalars['Int']>;
};

export type ListProgramUsersResult = {
  __typename?: 'ListProgramUsersResult';
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  result?: Maybe<Array<Maybe<ProgramMember>>>;
  total?: Maybe<Scalars['Int']>;
};

export type ListProgramsResult = {
  __typename?: 'ListProgramsResult';
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  result?: Maybe<Array<Maybe<Program>>>;
  total?: Maybe<Scalars['Int']>;
};

export type ListPublicWodCategoriesResult = {
  __typename?: 'ListPublicWodCategoriesResult';
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  result?: Maybe<Array<Maybe<PublicWodCategory>>>;
  total?: Maybe<Scalars['Int']>;
};

export type ListPublicWodUserScoresResult = {
  __typename?: 'ListPublicWodUserScoresResult';
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  result?: Maybe<Array<Maybe<ScoreEntry>>>;
  total?: Maybe<Scalars['Int']>;
};

export type ListPublicWodsResult = {
  __typename?: 'ListPublicWodsResult';
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  result?: Maybe<Array<Maybe<PublicWod>>>;
  total?: Maybe<Scalars['Int']>;
};

export type ListReportedChatMessagesResult = {
  __typename?: 'ListReportedChatMessagesResult';
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  result?: Maybe<Array<Maybe<ReportedChatMessage>>>;
  total?: Maybe<Scalars['Int']>;
};

export type ListReportedCommentsResult = {
  __typename?: 'ListReportedCommentsResult';
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  result?: Maybe<Array<Maybe<ReportedComment>>>;
  total?: Maybe<Scalars['Int']>;
};

export type ListSessionExerciseFeedbacksResult = {
  __typename?: 'ListSessionExerciseFeedbacksResult';
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  result?: Maybe<Array<Maybe<SessionExerciseFeedBack>>>;
  total?: Maybe<Scalars['Int']>;
};

export type ListSessionExerciseScoreRanksResult = {
  __typename?: 'ListSessionExerciseScoreRanksResult';
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  result?: Maybe<Array<Maybe<ExerciseScoreRankTc>>>;
  total?: Maybe<Scalars['Int']>;
  userRank?: Maybe<ExerciseScoreRankTc>;
};

export type ListUsersResult = {
  __typename?: 'ListUsersResult';
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  result?: Maybe<Array<Maybe<User>>>;
  total?: Maybe<Scalars['Int']>;
};

export type ListWodBookEntriesResult = {
  __typename?: 'ListWodBookEntriesResult';
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  result?: Maybe<Array<Maybe<WodBookEntry>>>;
  total?: Maybe<Scalars['Int']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  addAhapChronoConfiguration?: Maybe<SessionExercise>;
  addAmrapChronoConfiguration?: Maybe<SessionExercise>;
  addDeviceToken?: Maybe<Scalars['String']>;
  addEmomChronoConfiguration?: Maybe<SessionExercise>;
  addForTimeChronoConfiguration?: Maybe<SessionExercise>;
  addPersonalRecordScores?: Maybe<PersonalRecord>;
  addProgramAvatar?: Maybe<ProgramAvatar>;
  addProgramSessionComment?: Maybe<Comment>;
  addProgramSessionCommentReply?: Maybe<Comment>;
  addPublicWodAhapChronoConfiguration?: Maybe<PublicWod>;
  addPublicWodAmrapChronoConfiguration?: Maybe<PublicWod>;
  addPublicWodBlock?: Maybe<ExerciseBlock>;
  addPublicWodEmomChronoConfiguration?: Maybe<PublicWod>;
  addPublicWodForTimeChronoConfiguration?: Maybe<PublicWod>;
  addPublicWodScores?: Maybe<ScoreEntry>;
  addPublicWodTabataChronoConfiguration?: Maybe<PublicWod>;
  addSessionExerciseBlock?: Maybe<SessionExercise>;
  addSessionExerciseFeedback?: Maybe<SessionExerciseFeedBack>;
  addSessionExerciseScores?: Maybe<SessionExerciseUserScores>;
  addTabataChronoConfiguration?: Maybe<SessionExercise>;
  addUserProfilePicture?: Maybe<ProfilePicture>;
  addWodBookEntryBlock?: Maybe<WodBookEntry>;
  addWodBookEntryScores?: Maybe<WodBookEntry>;
  /** Returns blocked member id */
  blockChatMember?: Maybe<Scalars['String']>;
  createChatChannel?: Maybe<ChatChannel>;
  createPersonalRecord?: Maybe<PersonalRecord>;
  createProgram?: Maybe<Program>;
  createProgramInvitation?: Maybe<ProgramInvitation>;
  createProgramJoinRequest?: Maybe<ProgramJoinRequest>;
  createProgramSession?: Maybe<ProgramSession>;
  createPublicWod?: Maybe<PublicWod>;
  createPublicWodCategory?: Maybe<PublicWodCategory>;
  createSessionExercise?: Maybe<SessionExercise>;
  createUser?: Maybe<OwnedUser>;
  createWodBookEntry?: Maybe<WodBookEntry>;
  /** Returns deleted chat message id */
  deleteChatMessage?: Maybe<Scalars['String']>;
  /** Returns deleted user id */
  deleteOwnedUser?: Maybe<Scalars['String']>;
  /** Returns deleted personal record id */
  deletePersonalRecord?: Maybe<Scalars['String']>;
  /** Returns delete program id */
  deleteProgram?: Maybe<Scalars['String']>;
  /** Returns deleted program session id */
  deleteProgramSession?: Maybe<Scalars['String']>;
  /** Returns deleted WOD id */
  deletePublicWod?: Maybe<Scalars['String']>;
  deletePublicWodBlock?: Maybe<Scalars['String']>;
  /** Returns deleted WOD category id */
  deletePublicWodCategory?: Maybe<Scalars['String']>;
  /** Returns deleted public wod score id */
  deletePublicWodScores?: Maybe<Scalars['String']>;
  deleteReportedChatMessage?: Maybe<ReportedChatMessage>;
  /** Returns deleted comment id */
  deleteReportedComment?: Maybe<Scalars['String']>;
  /** Returns deleted exercise id */
  deleteSessionExercise?: Maybe<Scalars['String']>;
  /** Returns deleted exercise block id */
  deleteSessionExerciseBlock?: Maybe<Scalars['String']>;
  deleteUser?: Maybe<OwnedUser>;
  /** Returns deleted entry id */
  deleteWodBookEntry?: Maybe<Scalars['String']>;
  /** Returns deleted blockId */
  deleteWodBookEntryBlock?: Maybe<Scalars['String']>;
  deleteWodBookEntryScores?: Maybe<WodBookEntry>;
  /** Returns joined program id */
  joinOpenProgram?: Maybe<Scalars['String']>;
  /** Returns leaved chat channel id */
  leaveChatChannel?: Maybe<Scalars['String']>;
  leaveProgram?: Maybe<LeaveProgramResult>;
  likeProgramSessionComment?: Maybe<Comment>;
  publishProgramSession?: Maybe<ProgramSession>;
  /** Returns removed member id */
  removeChatMember?: Maybe<Scalars['String']>;
  /** Returns deleted comment id */
  removeComment?: Maybe<Scalars['String']>;
  removeProgramSessionCommentLike?: Maybe<Comment>;
  removeProgramUser?: Maybe<RemovedProgramUserResult>;
  replyToProgramInvitation?: Maybe<Program>;
  replyToProgramJoinRequest?: Maybe<Program>;
  /** Returns reported chat message id */
  reportChatMessage?: Maybe<Scalars['String']>;
  /** Returns reported comment id */
  reportComment?: Maybe<Scalars['String']>;
  saveSessionExerciseToWodBook?: Maybe<WodBookEntry>;
  sendChatImage?: Maybe<SendChatMessageResultTc>;
  sendChatMessage?: Maybe<SendChatMessageResultTc>;
  /** Returns given date */
  setChannelLatestReceipt?: Maybe<Scalars['Date']>;
  setReportedCommentProcessed?: Maybe<ReportedComment>;
  setReportedMessageProcessed?: Maybe<ReportedChatMessage>;
  setUserPrivateFields?: Maybe<OwnedUser>;
  setWodBookEntryExpectedScores?: Maybe<WodBookEntry>;
  toggleOwnedUserNotification?: Maybe<OwnedUser>;
  toggleProgramNotification?: Maybe<Program>;
  updateOwnedUserEmail?: Maybe<OwnedUser>;
  updateProgram?: Maybe<Program>;
  updateProgramSession?: Maybe<ProgramSession>;
  updatePublicWod?: Maybe<PublicWod>;
  updatePublicWodBlock?: Maybe<ExerciseBlock>;
  updatePublicWodBlocksOrder?: Maybe<PublicWod>;
  updatePublicWodCategory?: Maybe<PublicWodCategory>;
  updateSessionExercise?: Maybe<SessionExercise>;
  updateSessionExerciseBlock?: Maybe<SessionExercise>;
  updateSessionExerciseBlocksOrder?: Maybe<SessionExercise>;
  updateUser?: Maybe<OwnedUser>;
  updateWodBookEntry?: Maybe<WodBookEntry>;
  updateWodBookEntryBlock?: Maybe<WodBookEntry>;
  updateWodBookEntryBlocksOrder?: Maybe<WodBookEntry>;
  verifyUserEmail?: Maybe<OwnedUser>;
};


export type MutationAddAhapChronoConfigurationArgs = {
  input?: Maybe<AddAhapChronoConfigurationInput>;
};


export type MutationAddAmrapChronoConfigurationArgs = {
  input?: Maybe<AddAmrapChronoConfigurationInput>;
};


export type MutationAddDeviceTokenArgs = {
  input?: Maybe<AddDeviceTokenInput>;
};


export type MutationAddEmomChronoConfigurationArgs = {
  input?: Maybe<AddEmomChronoConfigurationInput>;
};


export type MutationAddForTimeChronoConfigurationArgs = {
  input?: Maybe<AddForTimeChronoConfigurationInput>;
};


export type MutationAddPersonalRecordScoresArgs = {
  input?: Maybe<AddPersonalRecordScoresInput>;
};


export type MutationAddProgramAvatarArgs = {
  file: Scalars['Upload'];
  programId: Scalars['ID'];
};


export type MutationAddProgramSessionCommentArgs = {
  input?: Maybe<AddProgramSessionCommentInput>;
};


export type MutationAddProgramSessionCommentReplyArgs = {
  input?: Maybe<AddProgramSessionCommentReplyInput>;
};


export type MutationAddPublicWodAhapChronoConfigurationArgs = {
  input?: Maybe<AddPublicWodAhapChronoConfigurationInput>;
};


export type MutationAddPublicWodAmrapChronoConfigurationArgs = {
  input?: Maybe<AddPublicWodAmrapChronoConfigurationInput>;
};


export type MutationAddPublicWodBlockArgs = {
  input?: Maybe<AddPublicWodBlockInput>;
};


export type MutationAddPublicWodEmomChronoConfigurationArgs = {
  input?: Maybe<AddPublicWodEmomChronoConfigurationInput>;
};


export type MutationAddPublicWodForTimeChronoConfigurationArgs = {
  input?: Maybe<AddPublicWodForTimeChronoConfigurationInput>;
};


export type MutationAddPublicWodScoresArgs = {
  input?: Maybe<AddPublicWodScoresInput>;
};


export type MutationAddPublicWodTabataChronoConfigurationArgs = {
  input?: Maybe<AddPublicWodTabataChronoConfigurationInput>;
};


export type MutationAddSessionExerciseBlockArgs = {
  input?: Maybe<AddSessionExerciseBlockInput>;
};


export type MutationAddSessionExerciseFeedbackArgs = {
  input?: Maybe<AddSessionExerciseFeedbackInput>;
};


export type MutationAddSessionExerciseScoresArgs = {
  input?: Maybe<AddSessionExerciseScoresInput>;
};


export type MutationAddTabataChronoConfigurationArgs = {
  input?: Maybe<AddTabataChronoConfigurationInput>;
};


export type MutationAddUserProfilePictureArgs = {
  file: Scalars['Upload'];
};


export type MutationAddWodBookEntryBlockArgs = {
  input?: Maybe<AddWodBookEntryBlockInput>;
};


export type MutationAddWodBookEntryScoresArgs = {
  input?: Maybe<AddWodBookEntryScoresInput>;
};


export type MutationBlockChatMemberArgs = {
  input?: Maybe<BlockChatMemberInput>;
};


export type MutationCreateChatChannelArgs = {
  input?: Maybe<CreateChatChannelInput>;
};


export type MutationCreatePersonalRecordArgs = {
  input?: Maybe<CreatePersonalRecordInput>;
};


export type MutationCreateProgramArgs = {
  input?: Maybe<CreateProgramInput>;
};


export type MutationCreateProgramInvitationArgs = {
  input?: Maybe<CreateProgramInvitationInput>;
};


export type MutationCreateProgramJoinRequestArgs = {
  input?: Maybe<CreateProgramJoinRequestInput>;
};


export type MutationCreateProgramSessionArgs = {
  input?: Maybe<CreateProgramSessionInput>;
};


export type MutationCreatePublicWodArgs = {
  input?: Maybe<CreatePublicWodInput>;
};


export type MutationCreatePublicWodCategoryArgs = {
  input?: Maybe<CreatePublicWodCategoryInput>;
};


export type MutationCreateSessionExerciseArgs = {
  input?: Maybe<CreateSessionExerciseInput>;
};


export type MutationCreateUserArgs = {
  input?: Maybe<CreateUserInput>;
};


export type MutationCreateWodBookEntryArgs = {
  input?: Maybe<CreateWodBookEntryInput>;
};


export type MutationDeleteChatMessageArgs = {
  input?: Maybe<DeleteMessageInput>;
};


export type MutationDeleteOwnedUserArgs = {
  input?: Maybe<DeleteOwnedUserInput>;
};


export type MutationDeletePersonalRecordArgs = {
  input?: Maybe<DeletePersonalRecordInput>;
};


export type MutationDeleteProgramArgs = {
  input?: Maybe<DeleteProgramInput>;
};


export type MutationDeleteProgramSessionArgs = {
  input?: Maybe<DeleteProgramSessionInput>;
};


export type MutationDeletePublicWodArgs = {
  input?: Maybe<DeletePublicWodInput>;
};


export type MutationDeletePublicWodBlockArgs = {
  input?: Maybe<DeletePublicWodBlockInput>;
};


export type MutationDeletePublicWodCategoryArgs = {
  input?: Maybe<DeletePublicWodCategoryInput>;
};


export type MutationDeletePublicWodScoresArgs = {
  input?: Maybe<DeletePublicWodScoresInput>;
};


export type MutationDeleteReportedChatMessageArgs = {
  input?: Maybe<DeleteReportedChatMessageInput>;
};


export type MutationDeleteReportedCommentArgs = {
  input?: Maybe<DeleteReportedCommentInput>;
};


export type MutationDeleteSessionExerciseArgs = {
  input?: Maybe<DeleteSessionExerciseInput>;
};


export type MutationDeleteSessionExerciseBlockArgs = {
  input?: Maybe<DeleteSessionExerciseBlockInput>;
};


export type MutationDeleteWodBookEntryArgs = {
  input?: Maybe<DeleteWodBookEntryInput>;
};


export type MutationDeleteWodBookEntryBlockArgs = {
  input?: Maybe<DeleteWodBookEntryBlockInput>;
};


export type MutationDeleteWodBookEntryScoresArgs = {
  input?: Maybe<DeleteWodBookEntryScoresInput>;
};


export type MutationJoinOpenProgramArgs = {
  input?: Maybe<JoinOpenProgramInput>;
};


export type MutationLeaveChatChannelArgs = {
  input?: Maybe<LeaveChatChannelInput>;
};


export type MutationLeaveProgramArgs = {
  input?: Maybe<LeaveProgramInput>;
};


export type MutationLikeProgramSessionCommentArgs = {
  input?: Maybe<LikeProgramSessionCommentInput>;
};


export type MutationPublishProgramSessionArgs = {
  input?: Maybe<PublishProgramSessionInput>;
};


export type MutationRemoveChatMemberArgs = {
  input?: Maybe<RemoveChatMemberInput>;
};


export type MutationRemoveCommentArgs = {
  input?: Maybe<RemoveCommentInput>;
};


export type MutationRemoveProgramSessionCommentLikeArgs = {
  input?: Maybe<RemoveProgramSessionCommentLikeInput>;
};


export type MutationRemoveProgramUserArgs = {
  input?: Maybe<RemoveProgramUserInput>;
};


export type MutationReplyToProgramInvitationArgs = {
  input?: Maybe<ReplyToProgramInvitationInput>;
};


export type MutationReplyToProgramJoinRequestArgs = {
  input?: Maybe<ReplyToProgramJoinRequestInput>;
};


export type MutationReportChatMessageArgs = {
  input?: Maybe<ReportChatMessageInput>;
};


export type MutationReportCommentArgs = {
  input?: Maybe<ReportCommentInput>;
};


export type MutationSaveSessionExerciseToWodBookArgs = {
  input?: Maybe<SaveSessionExerciseToWodBookInput>;
};


export type MutationSendChatImageArgs = {
  input?: Maybe<SendChatImageInput>;
};


export type MutationSendChatMessageArgs = {
  input?: Maybe<SendChatMessageInput>;
};


export type MutationSetChannelLatestReceiptArgs = {
  input?: Maybe<SetChannelLatestReceiptInput>;
};


export type MutationSetReportedCommentProcessedArgs = {
  input?: Maybe<SetReportedCommentProcessedInput>;
};


export type MutationSetReportedMessageProcessedArgs = {
  input?: Maybe<SetReportedChatMessageProcessedInput>;
};


export type MutationSetUserPrivateFieldsArgs = {
  input?: Maybe<SetUserPrivateFieldsInput>;
};


export type MutationSetWodBookEntryExpectedScoresArgs = {
  input?: Maybe<SetWodBookEntryExpectedScoresInput>;
};


export type MutationToggleOwnedUserNotificationArgs = {
  input?: Maybe<ToggleOwnedUserNotificationInput>;
};


export type MutationToggleProgramNotificationArgs = {
  input?: Maybe<ToggleProgramNotificationInput>;
};


export type MutationUpdateOwnedUserEmailArgs = {
  input?: Maybe<UpdateOwnedUserEmailInput>;
};


export type MutationUpdateProgramArgs = {
  input?: Maybe<UpdateProgramInput>;
};


export type MutationUpdateProgramSessionArgs = {
  input?: Maybe<UpdateProgramSessionInput>;
};


export type MutationUpdatePublicWodArgs = {
  input?: Maybe<UpdatePublicWodInput>;
};


export type MutationUpdatePublicWodBlockArgs = {
  input?: Maybe<UpdatePublicWodBlockInput>;
};


export type MutationUpdatePublicWodBlocksOrderArgs = {
  input?: Maybe<UpdatePublicWodBlocksOrderInput>;
};


export type MutationUpdatePublicWodCategoryArgs = {
  input?: Maybe<UpdatePublicWodCategoryInput>;
};


export type MutationUpdateSessionExerciseArgs = {
  input?: Maybe<UpdateSessionExerciseInput>;
};


export type MutationUpdateSessionExerciseBlockArgs = {
  input?: Maybe<UpdateSessionExerciseBlockInput>;
};


export type MutationUpdateSessionExerciseBlocksOrderArgs = {
  input?: Maybe<UpdateSessionExerciseBlocksOrderInput>;
};


export type MutationUpdateUserArgs = {
  input?: Maybe<UpdateUserInput>;
};


export type MutationUpdateWodBookEntryArgs = {
  input?: Maybe<UpdateWodBookEntryInput>;
};


export type MutationUpdateWodBookEntryBlockArgs = {
  input?: Maybe<UpdateWodBookEntryBlockInput>;
};


export type MutationUpdateWodBookEntryBlocksOrderArgs = {
  input?: Maybe<UpdateWodBookEntryBlocksOrderInput>;
};


export type MutationVerifyUserEmailArgs = {
  input?: Maybe<VerifyuserEmailInput>;
};

export type OwnedUser = {
  __typename?: 'OwnedUser';
  birthDate?: Maybe<BirthDate>;
  email?: Maybe<Scalars['String']>;
  email_verified?: Maybe<Scalars['Boolean']>;
  firstName?: Maybe<Scalars['String']>;
  followedPrograms?: Maybe<Array<Maybe<Program>>>;
  foot_size?: Maybe<FootSize>;
  gender?: Maybe<Gender>;
  height?: Maybe<Height>;
  id: Scalars['ID'];
  lastName?: Maybe<Scalars['String']>;
  location?: Maybe<OwnedUserLocation>;
  nickname?: Maybe<Scalars['String']>;
  ownedPrograms?: Maybe<Array<Maybe<Program>>>;
  personalRecords?: Maybe<ListPersonalRecordsResultTc>;
  preferences?: Maybe<OwnedUserPreferences>;
  profile_picture?: Maybe<ProfilePicture>;
  programInvitations?: Maybe<Array<Maybe<ProgramInvitation>>>;
  programJoinRequests?: Maybe<Array<Maybe<ProgramJoinRequest>>>;
  provider?: Maybe<Provider>;
  pubnub_uuid?: Maybe<Scalars['String']>;
  role?: Maybe<Role>;
  sportsRoom_name?: Maybe<SportsRoomName>;
  tshirt_size?: Maybe<TshirtSize>;
  weight?: Maybe<Weight>;
};


export type OwnedUserPersonalRecordsArgs = {
  input?: Maybe<ListPersonalRecordsInput>;
};

export type OwnedUserLocation = {
  __typename?: 'OwnedUserLocation';
  city?: Maybe<City>;
  country?: Maybe<Country>;
};

export type OwnedUserPreferences = {
  __typename?: 'OwnedUserPreferences';
  lang?: Maybe<Scalars['String']>;
  notificationSettings?: Maybe<UserNotificationSettings>;
};

export type PersonalRecord = {
  __typename?: 'PersonalRecord';
  category?: Maybe<Scalars['String']>;
  exercise?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  scoreEntries?: Maybe<Array<Maybe<ScoreEntry>>>;
};

export type ProfilePicture = {
  __typename?: 'ProfilePicture';
  url?: Maybe<Scalars['String']>;
};

export type Program = {
  __typename?: 'Program';
  avatar?: Maybe<ProgramAvatar>;
  creator?: Maybe<User>;
  description?: Maybe<Scalars['String']>;
  duration?: Maybe<Scalars['Int']>;
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  notesNumber?: Maybe<Scalars['Int']>;
  notificationSettings?: Maybe<ProgramNotificationSettings>;
  open?: Maybe<Scalars['Boolean']>;
  points?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  private?: Maybe<Scalars['Boolean']>;
  published?: Maybe<Scalars['Boolean']>;
  tags?: Maybe<Array<Maybe<Scalars['String']>>>;
  type?: Maybe<ProgramType>;
  users?: Maybe<Scalars['Int']>;
  usersLimit?: Maybe<Scalars['Int']>;
};

export type ProgramAvatar = {
  __typename?: 'ProgramAvatar';
  url?: Maybe<Scalars['String']>;
};

export type ProgramInvitation = {
  __typename?: 'ProgramInvitation';
  id: Scalars['ID'];
  program?: Maybe<Program>;
  recipient?: Maybe<User>;
};

export type ProgramJoinRequest = {
  __typename?: 'ProgramJoinRequest';
  applicant?: Maybe<User>;
  id: Scalars['ID'];
  program?: Maybe<Program>;
};

export type ProgramMember = {
  __typename?: 'ProgramMember';
  memberSince?: Maybe<Scalars['Date']>;
  profile?: Maybe<User>;
};

export type ProgramNotificationSettings = {
  __typename?: 'ProgramNotificationSettings';
  sessionCommentAdded?: Maybe<SessionCommentAddedNotificationSettings>;
};

export type ProgramSession = {
  __typename?: 'ProgramSession';
  exercises?: Maybe<Array<Maybe<SessionExercise>>>;
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  plannedAt?: Maybe<Scalars['Date']>;
  program?: Maybe<Program>;
};

export type PubNubGrantResult = {
  __typename?: 'PubNubGrantResult';
  channels?: Maybe<Array<Maybe<Scalars['String']>>>;
  granted?: Maybe<Scalars['Boolean']>;
  programChannels?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type PublicWod = {
  __typename?: 'PublicWod';
  category?: Maybe<PublicWodCategory>;
  createdAt?: Maybe<Scalars['Date']>;
  exercise?: Maybe<PublicWodExercise>;
  id: Scalars['ID'];
  latestUserScores?: Maybe<ListPublicWodUserScoresResult>;
  published?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['Date']>;
};


export type PublicWodLatestUserScoresArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export type PublicWodCategory = {
  __typename?: 'PublicWodCategory';
  createdAt?: Maybe<Scalars['Date']>;
  id: Scalars['ID'];
  published?: Maybe<Scalars['Boolean']>;
  translations?: Maybe<Array<Maybe<TranslatedCategory>>>;
  updatedAt?: Maybe<Scalars['Date']>;
};


export type PublicWodCategoryTranslationsArgs = {
  lang?: Maybe<Array<Maybe<AvailableLanguage>>>;
};

export type PublicWodExercise = {
  __typename?: 'PublicWodExercise';
  blockTranslations?: Maybe<Array<Maybe<TranslatedExerciseBlocks>>>;
  chronoConfiguration?: Maybe<ChronoConfiguration>;
  expectedScores?: Maybe<Array<Maybe<ExerciseExpectedScore>>>;
  nameTranslations?: Maybe<Array<Maybe<TranslatedExerciseName>>>;
};


export type PublicWodExerciseBlockTranslationsArgs = {
  lang?: Maybe<AvailableLanguage>;
};


export type PublicWodExerciseNameTranslationsArgs = {
  lang?: Maybe<AvailableLanguage>;
};

export type PublishedProgramsStat = {
  __typename?: 'PublishedProgramsStat';
  private?: Maybe<Scalars['Int']>;
  public?: Maybe<Scalars['Int']>;
};

export type Query = {
  __typename?: 'Query';
  grantPubNubAccess?: Maybe<PubNubGrantResult>;
  listChatChannelMembers?: Maybe<ListChatChannelMembersResult>;
  listChatChannelMessages?: Maybe<ListChatChannelMessagesResult>;
  listChatChannels?: Maybe<ListChatChannelsResult>;
  listCommentReplies?: Maybe<ListCommentRepliesResult>;
  listInvitableProgramUsers?: Maybe<ListInvitableProgramUsersResult>;
  listOwnedUsers?: Maybe<ListOwnedUsersResult>;
  listPersonalRecords?: Maybe<ListPersonalRecordsResultTc>;
  listProgramInvitations?: Maybe<ListProgramInvitationsResult>;
  listProgramJoinRequests?: Maybe<ListProgramJoinRequestsResult>;
  listProgramSessionComments?: Maybe<ListProgramSessionCommentsResult>;
  listProgramSessions?: Maybe<ListProgramSessionsResult>;
  listProgramUsers?: Maybe<ListProgramUsersResult>;
  listPrograms?: Maybe<ListProgramsResult>;
  listPublicWodCategories?: Maybe<ListPublicWodCategoriesResult>;
  listPublicWodUserScores?: Maybe<ListPublicWodUserScoresResult>;
  listPublicWods?: Maybe<ListPublicWodsResult>;
  listReportedChatMessages?: Maybe<ListReportedChatMessagesResult>;
  listReportedComments?: Maybe<ListReportedCommentsResult>;
  listSessionExerciseFeedbacks?: Maybe<ListSessionExerciseFeedbacksResult>;
  listSessionExerciseScoreRanks?: Maybe<ListSessionExerciseScoreRanksResult>;
  listUsers?: Maybe<ListUsersResult>;
  listWodBookEntries?: Maybe<ListWodBookEntriesResult>;
  me?: Maybe<OwnedUser>;
  showChatChannel?: Maybe<ChatChannel>;
  showComment?: Maybe<Comment>;
  showDailyStats?: Maybe<DailyStats>;
  showOwnedUser?: Maybe<OwnedUser>;
  showProgram?: Maybe<Program>;
  showProgramSession?: Maybe<ProgramSession>;
  showProgramSessionCommentsTotal?: Maybe<Scalars['Int']>;
  showPublicWod?: Maybe<PublicWod>;
  showPublicWodCategory?: Maybe<PublicWodCategory>;
  showReportedChatMessage?: Maybe<ReportedChatMessage>;
  showReportedComment?: Maybe<ReportedComment>;
  showTotalCreatedRessourcesEvolution?: Maybe<TotalCreatedRessourcesEvolution>;
  showTotalStats?: Maybe<TotalStats>;
  showUser?: Maybe<User>;
  showUserSessionExerciseFeedback?: Maybe<SessionExerciseFeedBack>;
  showUserSessionExerciseScores?: Maybe<SessionExerciseUserScores>;
  showWodBookEntry?: Maybe<WodBookEntry>;
};


export type QueryListChatChannelMembersArgs = {
  input?: Maybe<ListChatChannelMembersInput>;
};


export type QueryListChatChannelMessagesArgs = {
  input?: Maybe<ListChatChannelMessagesInput>;
};


export type QueryListChatChannelsArgs = {
  input?: Maybe<ListChatChannelsInput>;
};


export type QueryListCommentRepliesArgs = {
  input?: Maybe<ListCommentRepliesInput>;
};


export type QueryListInvitableProgramUsersArgs = {
  input?: Maybe<ListInvitableProgramUsersInput>;
};


export type QueryListOwnedUsersArgs = {
  input?: Maybe<ListOwnedUsersInput>;
};


export type QueryListPersonalRecordsArgs = {
  input?: Maybe<ListPersonalRecordsInput>;
};


export type QueryListProgramInvitationsArgs = {
  input?: Maybe<ListProgramInvitationsInput>;
};


export type QueryListProgramJoinRequestsArgs = {
  input?: Maybe<ListProgramJoinRequestsInput>;
};


export type QueryListProgramSessionCommentsArgs = {
  input?: Maybe<ListProgramSessionCommentsInput>;
};


export type QueryListProgramSessionsArgs = {
  input?: Maybe<ListProgramSessionsInput>;
};


export type QueryListProgramUsersArgs = {
  input?: Maybe<ListProgramUsersInput>;
};


export type QueryListProgramsArgs = {
  input?: Maybe<ListProgramsInput>;
};


export type QueryListPublicWodCategoriesArgs = {
  input?: Maybe<ListPublicWodCategoriesInput>;
};


export type QueryListPublicWodUserScoresArgs = {
  input?: Maybe<ListPublicWodUserScoresInput>;
};


export type QueryListPublicWodsArgs = {
  input?: Maybe<ListPublicWodsInput>;
};


export type QueryListReportedChatMessagesArgs = {
  input?: Maybe<ListReportedChatMessagesInput>;
};


export type QueryListReportedCommentsArgs = {
  input?: Maybe<ListReportedCommentsInput>;
};


export type QueryListSessionExerciseFeedbacksArgs = {
  input?: Maybe<ListSessionExercisesFeedbacksInput>;
};


export type QueryListSessionExerciseScoreRanksArgs = {
  input?: Maybe<ListSessionExerciseScoreRanksInput>;
};


export type QueryListUsersArgs = {
  input?: Maybe<ListUsersInput>;
};


export type QueryListWodBookEntriesArgs = {
  input?: Maybe<ListWodBookEntriesInput>;
};


export type QueryShowChatChannelArgs = {
  input?: Maybe<ShowChatChannelInput>;
};


export type QueryShowCommentArgs = {
  input?: Maybe<ShowCommentInput>;
};


export type QueryShowDailyStatsArgs = {
  input?: Maybe<ShowDailyStatsInput>;
};


export type QueryShowOwnedUserArgs = {
  input?: Maybe<ShowOwnedUserInput>;
};


export type QueryShowProgramArgs = {
  input?: Maybe<ShowProgramInput>;
};


export type QueryShowProgramSessionArgs = {
  input?: Maybe<ShowProgramSessionInput>;
};


export type QueryShowProgramSessionCommentsTotalArgs = {
  input?: Maybe<ShowProgramSessionCommentsTotalInput>;
};


export type QueryShowPublicWodArgs = {
  input?: Maybe<ShowPublicWodInput>;
};


export type QueryShowPublicWodCategoryArgs = {
  input?: Maybe<ShowPublicWodCategoryInput>;
};


export type QueryShowReportedChatMessageArgs = {
  input?: Maybe<ShowReportedChatMessageInput>;
};


export type QueryShowReportedCommentArgs = {
  input?: Maybe<ShowReportedCommentInput>;
};


export type QueryShowTotalCreatedRessourcesEvolutionArgs = {
  input?: Maybe<ShowTotalCreatedRessourcesEvolutionInput>;
};


export type QueryShowUserArgs = {
  input?: Maybe<ShowUserInput>;
};


export type QueryShowUserSessionExerciseFeedbackArgs = {
  input?: Maybe<ShowUserSessionExerciseFeedbackInput>;
};


export type QueryShowUserSessionExerciseScoresArgs = {
  input?: Maybe<ShowUserSessionExerciseScoresInput>;
};


export type QueryShowWodBookEntryArgs = {
  input?: Maybe<ShowWodBookEntryInput>;
};

export type RemovedProgramUserResult = {
  __typename?: 'RemovedProgramUserResult';
  programId?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
};

export type ReportedChatMessage = {
  __typename?: 'ReportedChatMessage';
  createdAt: Scalars['Date'];
  id: Scalars['ID'];
  message?: Maybe<ChatMessage>;
  previousMessages?: Maybe<Array<Maybe<ChatMessage>>>;
  processed?: Maybe<Scalars['Boolean']>;
  reportedBy?: Maybe<Scalars['ID']>;
  updatedAt: Scalars['Date'];
};

export type ReportedComment = {
  __typename?: 'ReportedComment';
  comment?: Maybe<Comment>;
  createdAt: Scalars['Date'];
  id: Scalars['ID'];
  processed?: Maybe<Scalars['Boolean']>;
  replyTo?: Maybe<Comment>;
  reportedBy?: Maybe<Scalars['ID']>;
  type?: Maybe<CommentType>;
  updatedAt: Scalars['Date'];
};

export type ScoreEntry = {
  __typename?: 'ScoreEntry';
  date?: Maybe<Scalars['Date']>;
  id: Scalars['ID'];
  scores?: Maybe<Array<Maybe<ExerciseScore>>>;
};

export type SendChatMessageResultTc = {
  __typename?: 'SendChatMessageResultTC';
  message?: Maybe<ChatMessage>;
  /** Given temporary id */
  tempId?: Maybe<Scalars['ID']>;
};

export type SessionCommentAddedNotificationSettings = {
  __typename?: 'SessionCommentAddedNotificationSettings';
  disabled?: Maybe<Scalars['Boolean']>;
};

export type SessionExercise = {
  __typename?: 'SessionExercise';
  blocks?: Maybe<Array<Maybe<ExerciseBlock>>>;
  chronoConfiguration?: Maybe<ChronoConfiguration>;
  expectedScores?: Maybe<Array<Maybe<ExerciseExpectedScore>>>;
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  notesNumber?: Maybe<Scalars['Int']>;
  points?: Maybe<Scalars['Float']>;
  savedToWodBook?: Maybe<Scalars['Boolean']>;
  userFeedback?: Maybe<SessionExerciseFeedBack>;
  userScores?: Maybe<Array<Maybe<ExerciseScore>>>;
};

export type SessionExerciseBlockImage = {
  __typename?: 'SessionExerciseBlockImage';
  url?: Maybe<Scalars['String']>;
};

export type SessionExerciseFeedBack = {
  __typename?: 'SessionExerciseFeedBack';
  comment?: Maybe<Scalars['String']>;
  exercise?: Maybe<SessionExercise>;
  feeling?: Maybe<Scalars['Float']>;
  member?: Maybe<User>;
  note?: Maybe<Scalars['Float']>;
};

export type SessionExerciseUserScores = {
  __typename?: 'SessionExerciseUserScores';
  scores?: Maybe<Array<Maybe<ExerciseScore>>>;
};

export type SportsRoomName = {
  __typename?: 'SportsRoomName';
  private?: Maybe<Scalars['Boolean']>;
  value?: Maybe<Scalars['String']>;
};

export type TabataChronoConfiguration = {
  __typename?: 'TabataChronoConfiguration';
  roundConfiguration?: Maybe<ChronoRoundConfiguration>;
  setConfiguration?: Maybe<ChronoSetConfiguration>;
  type?: Maybe<ChronoConfigurationType>;
};

export type TotalCreatedProfileForDate = {
  __typename?: 'TotalCreatedProfileForDate';
  count?: Maybe<Scalars['Int']>;
  date?: Maybe<Scalars['Date']>;
};

export type TotalCreatedRessourcesEvolution = {
  __typename?: 'TotalCreatedRessourcesEvolution';
  counts?: Maybe<Array<Maybe<TotalCreatedProfileForDate>>>;
  ressource?: Maybe<RessourceEvolutionAvailable>;
};

export type TotalStats = {
  __typename?: 'TotalStats';
  addedFeedbacks?: Maybe<Scalars['Int']>;
  addedScores?: Maybe<Scalars['Int']>;
  averageWodBookEntriesPerUser?: Maybe<Scalars['Float']>;
  chatChannels?: Maybe<ChatChannelsStat>;
  chatMessages?: Maybe<ChatMessagesStat>;
  chronoConfigurations?: Maybe<Scalars['Int']>;
  createdPersonalRecords?: Maybe<Scalars['Int']>;
  createdProfiles?: Maybe<Scalars['Int']>;
  createdPrograms?: Maybe<Scalars['Int']>;
  createdSessions?: Maybe<Scalars['Int']>;
  createdWodBookEntries?: Maybe<Scalars['Int']>;
  emailVerifiedProfiles?: Maybe<Scalars['Int']>;
  profilesWithPersonalData?: Maybe<Scalars['Int']>;
  profilesWithPicture?: Maybe<Scalars['Int']>;
  programSessionComments?: Maybe<Scalars['Int']>;
  publishedPrograms?: Maybe<PublishedProgramsStat>;
  unPublishedPrograms?: Maybe<Scalars['Int']>;
  usersProgramFollowing?: Maybe<UsersProgramFollowingStat>;
  usersWithAtLeastOnePersonalRecord?: Maybe<Scalars['Int']>;
  usersWithAtLeastOneWodBookEntry?: Maybe<Scalars['Int']>;
};

export type TranslatedCategory = {
  __typename?: 'TranslatedCategory';
  lang?: Maybe<AvailableLanguage>;
  name?: Maybe<Scalars['String']>;
};

export type TranslatedExerciseBlocks = {
  __typename?: 'TranslatedExerciseBlocks';
  blocks?: Maybe<Array<Maybe<ExerciseBlock>>>;
  lang?: Maybe<AvailableLanguage>;
};

export type TranslatedExerciseName = {
  __typename?: 'TranslatedExerciseName';
  lang?: Maybe<AvailableLanguage>;
  name?: Maybe<Scalars['String']>;
};

export type TshirtSize = {
  __typename?: 'TshirtSize';
  private?: Maybe<Scalars['Boolean']>;
  value?: Maybe<TShirtSize>;
};

export type User = {
  __typename?: 'User';
  birthDate?: Maybe<Scalars['Date']>;
  firstName?: Maybe<Scalars['String']>;
  followedPrograms?: Maybe<Array<Maybe<Program>>>;
  foot_size?: Maybe<Scalars['Int']>;
  gender?: Maybe<Gender>;
  height?: Maybe<Scalars['Int']>;
  id: Scalars['ID'];
  lastName?: Maybe<Scalars['String']>;
  location?: Maybe<UserLocation>;
  nickname?: Maybe<Scalars['String']>;
  ownedPrograms?: Maybe<Array<Maybe<Program>>>;
  personalRecords?: Maybe<ListPersonalRecordsResultTc>;
  profile_picture?: Maybe<ProfilePicture>;
  pubnub_uuid?: Maybe<Scalars['String']>;
  sportsRoom_name?: Maybe<Scalars['String']>;
  tshirt_size?: Maybe<TShirtSize>;
  weight?: Maybe<Scalars['Float']>;
};


export type UserPersonalRecordsArgs = {
  input?: Maybe<ListPersonalRecordsInput>;
};

export type UserLocation = {
  __typename?: 'UserLocation';
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
};

export type UserNotificationSettings = {
  __typename?: 'UserNotificationSettings';
  commentLiked?: Maybe<CommentLikedNotificationSettings>;
  commentReplyAdded?: Maybe<CommentReplyAddedNotificationSettings>;
};

export type UsersProgramFollowingStat = {
  __typename?: 'UsersProgramFollowingStat';
  fiveAndMore?: Maybe<Scalars['Int']>;
  four?: Maybe<Scalars['Int']>;
  one?: Maybe<Scalars['Int']>;
  three?: Maybe<Scalars['Int']>;
  two?: Maybe<Scalars['Int']>;
};

export type Weight = {
  __typename?: 'Weight';
  private?: Maybe<Scalars['Boolean']>;
  value?: Maybe<Scalars['Float']>;
};

export type WodBookEntry = {
  __typename?: 'WodBookEntry';
  createdAt?: Maybe<Scalars['Date']>;
  exercise?: Maybe<WodBookExercise>;
  id: Scalars['ID'];
  scoreEntries?: Maybe<Array<Maybe<ScoreEntry>>>;
  updatedAt?: Maybe<Scalars['Date']>;
};

export type WodBookExercise = {
  __typename?: 'WodBookExercise';
  blocks?: Maybe<Array<Maybe<ExerciseBlock>>>;
  createdAt?: Maybe<Scalars['Date']>;
  expectedScores?: Maybe<Array<Maybe<ExerciseExpectedScore>>>;
  name?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['Date']>;
};

export enum AvailableLanguage {
  En = 'en',
  Fr = 'fr'
}

/** Chat channel type */
export enum ChatChannelType {
  Duo = 'DUO',
  Program = 'PROGRAM'
}

/** Chat message attachment type */
export enum ChatMessageAttachmentType {
  Image = 'IMAGE'
}

export enum ChronoConfigurationType {
  Ahap = 'AHAP',
  Amrap = 'AMRAP',
  Emom = 'EMOM',
  Fortime = 'FORTIME',
  Tabata = 'TABATA'
}

/** Comment attachment type */
export enum CommentAttachmentType {
  Image = 'IMAGE'
}

export enum CommentType {
  Session = 'SESSION'
}

export enum DeviceTokenType {
  Apns = 'apns',
  Gcm = 'gcm'
}

export enum EvolutionRange {
  Day = 'DAY',
  Month = 'MONTH',
  Week = 'WEEK',
  Year = 'YEAR'
}

/** User gender */
export enum Gender {
  Female = 'FEMALE',
  Male = 'MALE',
  Other = 'OTHER'
}

export enum NotificationType {
  CommentLiked = 'COMMENT_LIKED',
  CommentReplyAdded = 'COMMENT_REPLY_ADDED'
}

export enum PrivateField {
  BirthDate = 'birthDate',
  City = 'city',
  Country = 'country',
  FootSize = 'foot_size',
  Height = 'height',
  SportsRoomName = 'sportsRoom_name',
  TshirtSize = 'tshirt_size',
  Weight = 'weight'
}

export enum ProgramNotificationType {
  SessionCommentAdded = 'SESSION_COMMENT_ADDED'
}

/** Program type */
export enum ProgramType {
  Group = 'GROUP',
  Individual = 'INDIVIDUAL',
  Specific = 'SPECIFIC'
}

/** SignIn provider */
export enum Provider {
  Cognito = 'Cognito',
  Facebook = 'Facebook',
  Google = 'Google',
  SignInWithApple = 'SignInWithApple'
}

export enum RessourceEvolutionAvailable {
  CreatedFeedbacks = 'CREATED_FEEDBACKS',
  CreatedMessages = 'CREATED_MESSAGES',
  CreatedPersonalRecords = 'CREATED_PERSONAL_RECORDS',
  CreatedProfiles = 'CREATED_PROFILES',
  CreatedPrograms = 'CREATED_PROGRAMS',
  CreatedProgramSessionComments = 'CREATED_PROGRAM_SESSION_COMMENTS',
  CreatedScores = 'CREATED_SCORES',
  CreatedSessions = 'CREATED_SESSIONS',
  CreatedWodbookEntries = 'CREATED_WODBOOK_ENTRIES'
}

export enum Role {
  Admin = 'admin',
  BusinessUser = 'business_user',
  User = 'user'
}

/** Score value type */
export enum ScoreValueType {
  Calories = 'CALORIES',
  Distance = 'DISTANCE',
  Duration = 'DURATION',
  Load = 'LOAD',
  Repetitions = 'REPETITIONS'
}

/** Session exercise block type */
export enum SessionExerciseBlockType {
  Image = 'IMAGE',
  List = 'LIST',
  Text = 'TEXT'
}

export enum SortType {
  Asc = 'ASC',
  Desc = 'DESC'
}

export enum TShirtSize {
  L = 'L',
  M = 'M',
  S = 'S',
  Xl = 'XL',
  Xs = 'XS',
  Xxl = 'XXL',
  Xxs = 'XXS',
  Xxxl = 'XXXL'
}

export type AddAhapChronoConfigurationInput = {
  ahaps?: Maybe<Array<Maybe<AhapConfigurationInput>>>;
  exerciseId: Scalars['ID'];
  sessionId: Scalars['ID'];
};

export type AddAmrapChronoConfigurationInput = {
  exerciseId: Scalars['ID'];
  roundConfiguration?: Maybe<ChronoRoundConfigurationInput>;
  sessionId: Scalars['ID'];
  setConfiguration?: Maybe<ChronoSetConfigurationInput>;
};

export type AddDeviceTokenInput = {
  token: Scalars['String'];
  type?: Maybe<DeviceTokenType>;
};

export type AddEmomChronoConfigurationInput = {
  emoms?: Maybe<Array<Maybe<EmomConfigurationInput>>>;
  exerciseId: Scalars['ID'];
  sessionId: Scalars['ID'];
};

export type AddForTimeChronoConfigurationInput = {
  exerciseId: Scalars['ID'];
  sessionId: Scalars['ID'];
  setConfiguration?: Maybe<ChronoSetConfigurationInput>;
  timeLimit?: Maybe<Scalars['Int']>;
};

export type AddPersonalRecordScoresInput = {
  date?: Maybe<Scalars['Date']>;
  id: Scalars['ID'];
  type?: Maybe<ScoreValueType>;
  value?: Maybe<Scalars['Float']>;
};

export type AddProgramSessionCommentInput = {
  content?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['Upload']>;
  sessionId: Scalars['ID'];
};

export type AddProgramSessionCommentReplyInput = {
  commentId: Scalars['ID'];
  content?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['Upload']>;
};

export type AddPublicWodAhapChronoConfigurationInput = {
  ahaps?: Maybe<Array<Maybe<AhapConfigurationInput>>>;
  wodId: Scalars['ID'];
};

export type AddPublicWodAmrapChronoConfigurationInput = {
  roundConfiguration?: Maybe<ChronoRoundConfigurationInput>;
  setConfiguration?: Maybe<ChronoSetConfigurationInput>;
  wodId: Scalars['ID'];
};

export type AddPublicWodBlockInput = {
  content?: Maybe<Scalars['String']>;
  elements?: Maybe<Array<Maybe<Scalars['String']>>>;
  image?: Maybe<Scalars['Upload']>;
  lang: AvailableLanguage;
  type: SessionExerciseBlockType;
  wodId: Scalars['ID'];
};

export type AddPublicWodEmomChronoConfigurationInput = {
  emoms?: Maybe<Array<Maybe<EmomConfigurationInput>>>;
  wodId: Scalars['ID'];
};

export type AddPublicWodForTimeChronoConfigurationInput = {
  setConfiguration?: Maybe<ChronoSetConfigurationInput>;
  timeLimit?: Maybe<Scalars['Int']>;
  wodId: Scalars['ID'];
};

export type AddPublicWodScoresInput = {
  date?: Maybe<Scalars['Date']>;
  scores?: Maybe<Array<Maybe<PublicWodScoreInput>>>;
  wodId: Scalars['ID'];
};

export type AddPublicWodTabataChronoConfigurationInput = {
  roundConfiguration?: Maybe<ChronoRoundConfigurationInput>;
  setConfiguration?: Maybe<ChronoSetConfigurationInput>;
  wodId: Scalars['ID'];
};

export type AddSessionExerciseBlockInput = {
  content?: Maybe<Scalars['String']>;
  elements?: Maybe<Array<Maybe<Scalars['String']>>>;
  exerciseId: Scalars['ID'];
  image?: Maybe<Scalars['Upload']>;
  sessionId: Scalars['ID'];
  type?: Maybe<SessionExerciseBlockType>;
};

export type AddSessionExerciseFeedbackInput = {
  comment?: Maybe<Scalars['String']>;
  exerciseId: Scalars['ID'];
  feeling?: Maybe<Scalars['Float']>;
  note?: Maybe<Scalars['Float']>;
  sessionId: Scalars['ID'];
};

export type AddSessionExerciseScoresInput = {
  exerciseId: Scalars['ID'];
  scores?: Maybe<Array<Maybe<SessionExerciseScoreInput>>>;
  sessionId: Scalars['ID'];
};

export type AddTabataChronoConfigurationInput = {
  exerciseId: Scalars['ID'];
  roundConfiguration?: Maybe<ChronoRoundConfigurationInput>;
  sessionId: Scalars['ID'];
  setConfiguration?: Maybe<ChronoSetConfigurationInput>;
};

export type AddWodBookEntryBlockInput = {
  content?: Maybe<Scalars['String']>;
  elements?: Maybe<Array<Maybe<Scalars['String']>>>;
  entryId: Scalars['ID'];
  image?: Maybe<Scalars['Upload']>;
  type?: Maybe<SessionExerciseBlockType>;
};

export type AddWodBookEntryScoresInput = {
  date?: Maybe<Scalars['Date']>;
  id: Scalars['ID'];
  scores?: Maybe<Array<Maybe<WodBookEntryScoreInput>>>;
};

export type AhapConfigurationInput = {
  restTime?: Maybe<Scalars['Int']>;
  timeLimit?: Maybe<Scalars['Int']>;
};

export type BlockChatMemberInput = {
  channelId: Scalars['ID'];
  memberId: Scalars['ID'];
};

export type BlockOrderInput = {
  blockId: Scalars['ID'];
  order?: Maybe<Scalars['Int']>;
};

export type ChronoRoundConfigurationInput = {
  number?: Maybe<Scalars['Int']>;
  restTime?: Maybe<Scalars['Int']>;
  timeLimit?: Maybe<Scalars['Int']>;
};

export type ChronoSetConfigurationInput = {
  number?: Maybe<Scalars['Int']>;
  restTime?: Maybe<Scalars['Int']>;
};

export type CommentRepliesInput = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export type CreateChatChannelInput = {
  membersIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
};

export type CreatePersonalRecordInput = {
  category?: Maybe<Scalars['String']>;
  date?: Maybe<Scalars['Date']>;
  exercise?: Maybe<Scalars['String']>;
  type?: Maybe<ScoreValueType>;
  value?: Maybe<Scalars['Float']>;
};

export type CreateProgramInput = {
  avatar?: Maybe<Scalars['Upload']>;
  description?: Maybe<Scalars['String']>;
  duration?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  open?: Maybe<Scalars['Boolean']>;
  price?: Maybe<Scalars['Float']>;
  private?: Maybe<Scalars['Boolean']>;
  tags?: Maybe<Array<Maybe<Scalars['String']>>>;
  type?: Maybe<ProgramType>;
  usersLimit?: Maybe<Scalars['Int']>;
};

export type CreateProgramInvitationInput = {
  program: Scalars['ID'];
  recipient: Scalars['ID'];
};

export type CreateProgramJoinRequestInput = {
  program: Scalars['ID'];
};

export type CreateProgramSessionInput = {
  name?: Maybe<Scalars['String']>;
  plannedAt?: Maybe<Scalars['Date']>;
  programId: Scalars['ID'];
};

export type CreatePublicWodCategoryInput = {
  name?: Maybe<PublicWodCategoryNameInput>;
};

export type CreatePublicWodInput = {
  categoryId: Scalars['ID'];
  exercise?: Maybe<PublicWodExerciseInput>;
};

export type CreateSessionExerciseInput = {
  expectedScores?: Maybe<Array<Maybe<ExpectedScoreInput>>>;
  name?: Maybe<Scalars['String']>;
  sessionId: Scalars['ID'];
};

export type CreateUserInput = {
  birthDate?: Maybe<Scalars['Date']>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  foot_size?: Maybe<Scalars['Int']>;
  gender?: Maybe<Gender>;
  height?: Maybe<Scalars['Int']>;
  lang?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  nickname?: Maybe<Scalars['String']>;
  sportsRoom_name?: Maybe<Scalars['String']>;
  tshirt_size?: Maybe<TShirtSize>;
  weight?: Maybe<Scalars['Float']>;
};

export type CreateWodBookEntryInput = {
  exerciseName: Scalars['String'];
};

export type DeleteMessageInput = {
  messageId: Scalars['ID'];
};

export type DeleteOwnedUserInput = {
  id: Scalars['ID'];
};

export type DeletePersonalRecordInput = {
  id: Scalars['ID'];
};

export type DeleteProgramInput = {
  programId: Scalars['ID'];
};

export type DeleteProgramSessionInput = {
  id: Scalars['ID'];
};

export type DeletePublicWodBlockInput = {
  blockId: Scalars['ID'];
  lang: AvailableLanguage;
  wodId: Scalars['ID'];
};

export type DeletePublicWodCategoryInput = {
  /** WOD category id */
  id: Scalars['ID'];
};

export type DeletePublicWodInput = {
  wodId: Scalars['ID'];
};

export type DeletePublicWodScoresInput = {
  scoreEntryId: Scalars['ID'];
};

export type DeleteReportedChatMessageInput = {
  messageId: Scalars['ID'];
};

export type DeleteReportedCommentInput = {
  commentId: Scalars['ID'];
  type?: Maybe<CommentType>;
};

export type DeleteSessionExerciseBlockInput = {
  blockId: Scalars['ID'];
  exerciseId: Scalars['ID'];
  sessionId: Scalars['ID'];
};

export type DeleteSessionExerciseInput = {
  exerciseId: Scalars['ID'];
  sessionId: Scalars['ID'];
};

export type DeleteWodBookEntryBlockInput = {
  blockId: Scalars['ID'];
  entryId: Scalars['ID'];
};

export type DeleteWodBookEntryInput = {
  id: Scalars['ID'];
};

export type DeleteWodBookEntryScoresInput = {
  entryId: Scalars['ID'];
  scoreId: Scalars['ID'];
};

export type EmomConfigurationInput = {
  everyTime?: Maybe<Scalars['Int']>;
  restTime?: Maybe<Scalars['Int']>;
  roundsNumber?: Maybe<Scalars['Int']>;
  whileTime?: Maybe<Scalars['Int']>;
};

export type ExpectedScoreInput = {
  sort?: Maybe<SortType>;
  type: ScoreValueType;
};

export type JoinOpenProgramInput = {
  programId: Scalars['ID'];
};

export type LeaveChatChannelInput = {
  channelId: Scalars['ID'];
};

export type LeaveProgramInput = {
  programId: Scalars['ID'];
};

export type LikeProgramSessionCommentInput = {
  commentId: Scalars['ID'];
};

export type ListChatChannelMembersInput = {
  id: Scalars['ID'];
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export type ListChatChannelMessagesInput = {
  id: Scalars['ID'];
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export type ListChatChannelsInput = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export type ListCommentRepliesInput = {
  commentId: Scalars['ID'];
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  type?: Maybe<CommentType>;
};

export type ListInvitableProgramUsersInput = {
  filter?: Maybe<ProgramUsersFilterInput>;
  id: Scalars['ID'];
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export type ListOwnedUsersInput = {
  filter?: Maybe<OwnedUserFilterInput>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export type ListPersonalRecordsInput = {
  category?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  user?: Maybe<Scalars['ID']>;
};

export type ListProgramSessionCommentsInput = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  sessionId: Scalars['ID'];
};

export type ListProgramSessionsInput = {
  /** Day of month */
  day?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  /** Month number (January is 1 / December is 12) */
  month?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  programId?: Maybe<Scalars['ID']>;
  /** Full year (2020) */
  year?: Maybe<Scalars['Int']>;
};

export type ListProgramUsersInput = {
  filter?: Maybe<ProgramUsersFilterInput>;
  id: Scalars['ID'];
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export type ListProgramsInput = {
  filter?: Maybe<ProgramFilterInput>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export type ListPublicWodCategoriesInput = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  /** Only available for admin role */
  showUnpublished?: Maybe<Scalars['Boolean']>;
};

export type ListPublicWodUserScoresInput = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  wodId: Scalars['ID'];
};

export type ListPublicWodsInput = {
  categoryId?: Maybe<Scalars['ID']>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export type ListReportedChatMessagesInput = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  processed?: Maybe<Scalars['Boolean']>;
};

export type ListReportedCommentsInput = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  processed?: Maybe<Scalars['Boolean']>;
};

export type ListSessionExerciseScoreRanksInput = {
  exerciseId: Scalars['ID'];
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  sessionId: Scalars['ID'];
  type?: Maybe<ScoreValueType>;
};

export type ListSessionExercisesFeedbacksInput = {
  exerciseId: Scalars['ID'];
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  sessionId: Scalars['ID'];
};

export type ListUsersInput = {
  filter?: Maybe<UserFilterInput>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export type ListWodBookEntriesInput = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export type OwnedUserFilterInput = {
  nameOrNicknameOrEmail?: Maybe<StringFilterInput>;
};

export type PrivateFieldInput = {
  field?: Maybe<PrivateField>;
  private?: Maybe<Scalars['Boolean']>;
};

export type ProgramFilterInput = {
  nameOrTagsOrCreator?: Maybe<StringFilterInput>;
};

export type ProgramUsersFilterInput = {
  nameOrNickname?: Maybe<StringFilterInput>;
};

export type PublicWodCategoryNameInput = {
  en?: Maybe<Scalars['String']>;
  fr?: Maybe<Scalars['String']>;
};

export type PublicWodExerciseInput = {
  name?: Maybe<PublicWodExerciseNameInput>;
};

export type PublicWodExerciseNameInput = {
  en?: Maybe<Scalars['String']>;
  fr?: Maybe<Scalars['String']>;
};

export type PublicWodScoreInput = {
  type: ScoreValueType;
  value: Scalars['Float'];
};

export type PublishProgramSessionInput = {
  id: Scalars['ID'];
};

export type RemoveChatMemberInput = {
  channelId: Scalars['ID'];
  memberId: Scalars['ID'];
};

export type RemoveCommentInput = {
  commentId: Scalars['ID'];
  type?: Maybe<CommentType>;
};

export type RemoveProgramSessionCommentLikeInput = {
  commentId: Scalars['ID'];
};

export type RemoveProgramUserInput = {
  programId: Scalars['ID'];
  userId: Scalars['ID'];
};

export type ReportChatMessageInput = {
  messageId: Scalars['ID'];
};

export type ReportCommentInput = {
  commentId: Scalars['ID'];
  type?: Maybe<CommentType>;
};

export type SaveSessionExerciseToWodBookInput = {
  exerciseId: Scalars['ID'];
  sessionId: Scalars['ID'];
  title?: Maybe<Scalars['String']>;
};

export type SendChatImageInput = {
  channel: Scalars['ID'];
  content?: Maybe<Scalars['String']>;
  image: Scalars['Upload'];
  tempId?: Maybe<Scalars['ID']>;
};

export type SendChatMessageInput = {
  channel: Scalars['ID'];
  content: Scalars['String'];
  tempId?: Maybe<Scalars['ID']>;
};

export type SessionExerciseScoreInput = {
  type?: Maybe<ScoreValueType>;
  value?: Maybe<Scalars['Float']>;
};

export type SetChannelLatestReceiptInput = {
  channelId: Scalars['ID'];
  receivedAt?: Maybe<Scalars['Date']>;
};

export type SetReportedChatMessageProcessedInput = {
  reportId: Scalars['ID'];
};

export type SetReportedCommentProcessedInput = {
  commentId: Scalars['ID'];
  type?: Maybe<CommentType>;
};

export type SetUserPrivateFieldsInput = {
  fields?: Maybe<Array<Maybe<PrivateFieldInput>>>;
};

export type SetWodBookEntryExpectedScoresInput = {
  entryId: Scalars['ID'];
  expectedScores?: Maybe<Array<Maybe<ExpectedScoreInput>>>;
};

export type ShowChatChannelInput = {
  channelId: Scalars['ID'];
};

export type ShowCommentInput = {
  id: Scalars['ID'];
  type?: Maybe<CommentType>;
};

export type ShowDailyStatsInput = {
  date?: Maybe<Scalars['Date']>;
};

export type ShowOwnedUserInput = {
  id: Scalars['ID'];
};

export type ShowProgramInput = {
  id?: Maybe<Scalars['ID']>;
};

export type ShowProgramSessionCommentsTotalInput = {
  sessionId: Scalars['ID'];
};

export type ShowProgramSessionInput = {
  sessionId: Scalars['ID'];
};

export type ShowPublicWodCategoryInput = {
  id: Scalars['ID'];
};

export type ShowPublicWodInput = {
  wodId: Scalars['ID'];
};

export type ShowReportedChatMessageInput = {
  messageId: Scalars['ID'];
};

export type ShowReportedCommentInput = {
  commentId?: Maybe<Scalars['ID']>;
  type?: Maybe<CommentType>;
};

export type ShowTotalCreatedRessourcesEvolutionInput = {
  range?: Maybe<EvolutionRange>;
  ressource: RessourceEvolutionAvailable;
};

export type ShowUserInput = {
  id?: Maybe<Scalars['ID']>;
  pubnub_uuid?: Maybe<Scalars['String']>;
};

export type ShowUserSessionExerciseFeedbackInput = {
  exerciseId: Scalars['ID'];
  userId: Scalars['ID'];
};

export type ShowUserSessionExerciseScoresInput = {
  exerciseId: Scalars['ID'];
  userId: Scalars['ID'];
};

export type ShowWodBookEntryInput = {
  entryId: Scalars['ID'];
};

export type StringFilterInput = {
  contains?: Maybe<Scalars['String']>;
};

export type ToggleOwnedUserNotificationInput = {
  enabled?: Maybe<Scalars['Boolean']>;
  notification?: Maybe<NotificationType>;
};

export type ToggleProgramNotificationInput = {
  enabled?: Maybe<Scalars['Boolean']>;
  notification?: Maybe<ProgramNotificationType>;
  programId: Scalars['ID'];
};

export type UpdateOwnedUserEmailInput = {
  newEmail?: Maybe<Scalars['String']>;
  userId: Scalars['ID'];
};

export type UpdateProgramInput = {
  description?: Maybe<Scalars['String']>;
  duration?: Maybe<Scalars['Int']>;
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  open?: Maybe<Scalars['Boolean']>;
  price?: Maybe<Scalars['Float']>;
  private?: Maybe<Scalars['Boolean']>;
  published?: Maybe<Scalars['Boolean']>;
  tags?: Maybe<Array<Maybe<Scalars['String']>>>;
  type?: Maybe<ProgramType>;
  usersLimit?: Maybe<Scalars['Int']>;
};

export type UpdateProgramSessionInput = {
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  plannedAt?: Maybe<Scalars['Date']>;
  published?: Maybe<Scalars['Boolean']>;
};

export type UpdatePublicWodBlockInput = {
  blockId: Scalars['ID'];
  content?: Maybe<Scalars['String']>;
  elements?: Maybe<Array<Maybe<Scalars['String']>>>;
  image?: Maybe<Scalars['Upload']>;
  lang: AvailableLanguage;
  type: SessionExerciseBlockType;
  wodId: Scalars['ID'];
};

export type UpdatePublicWodBlocksOrderInput = {
  blockOrders?: Maybe<Array<Maybe<BlockOrderInput>>>;
  lang: AvailableLanguage;
  wodId: Scalars['ID'];
};

export type UpdatePublicWodCategoryInput = {
  /** WOD category id */
  id: Scalars['ID'];
  name?: Maybe<PublicWodCategoryNameInput>;
  published?: Maybe<Scalars['Boolean']>;
};

export type UpdatePublicWodInput = {
  /** New category id */
  categoryId?: Maybe<Scalars['ID']>;
  expectedScores?: Maybe<Array<Maybe<ExpectedScoreInput>>>;
  name?: Maybe<PublicWodExerciseNameInput>;
  wodId: Scalars['ID'];
};

export type UpdateSessionExerciseBlockInput = {
  blockId: Scalars['ID'];
  content?: Maybe<Scalars['String']>;
  elements?: Maybe<Array<Maybe<Scalars['String']>>>;
  exerciseId: Scalars['ID'];
  image?: Maybe<Scalars['Upload']>;
  sessionId: Scalars['ID'];
  type?: Maybe<SessionExerciseBlockType>;
};

export type UpdateSessionExerciseBlocksOrderInput = {
  blockOrders?: Maybe<Array<Maybe<BlockOrderInput>>>;
  exerciseId: Scalars['ID'];
  sessionId: Scalars['ID'];
};

export type UpdateSessionExerciseInput = {
  exerciseId: Scalars['ID'];
  expectedScores?: Maybe<Array<Maybe<ExpectedScoreInput>>>;
  name?: Maybe<Scalars['String']>;
  sessionId: Scalars['ID'];
};

export type UpdateUserInput = {
  birthDate?: Maybe<Scalars['Date']>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  foot_size?: Maybe<Scalars['Int']>;
  gender?: Maybe<Gender>;
  height?: Maybe<Scalars['Int']>;
  lang?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  sportsRoom_name?: Maybe<Scalars['String']>;
  tshirt_size?: Maybe<TShirtSize>;
  weight?: Maybe<Scalars['Float']>;
};

export type UpdateWodBookEntryBlockInput = {
  blockId: Scalars['ID'];
  content?: Maybe<Scalars['String']>;
  elements?: Maybe<Array<Maybe<Scalars['String']>>>;
  entryId: Scalars['ID'];
  image?: Maybe<Scalars['Upload']>;
  type?: Maybe<SessionExerciseBlockType>;
};

export type UpdateWodBookEntryBlocksOrderInput = {
  blockOrders?: Maybe<Array<Maybe<BlockOrderInput>>>;
  entryId: Scalars['ID'];
};

export type UpdateWodBookEntryInput = {
  entryId: Scalars['ID'];
  exerciseName?: Maybe<Scalars['String']>;
};

export type UserFilterInput = {
  nameOrNickname?: Maybe<StringFilterInput>;
};

export type VerifyuserEmailInput = {
  code?: Maybe<Scalars['String']>;
};

export type WodBookEntryScoreInput = {
  type: ScoreValueType;
  value: Scalars['Float'];
};

export type ListProgramInvitationsInput = {
  id: Scalars['ID'];
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export type ListProgramJoinRequestsInput = {
  id: Scalars['ID'];
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export type ReplyToProgramInvitationInput = {
  accept?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
};

export type ReplyToProgramJoinRequestInput = {
  accept?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
};



export type MeQueryVariables = Exact<{ [key: string]: never; }>;


export type MeQuery = (
  { __typename?: 'Query' }
  & { me?: Maybe<(
    { __typename?: 'OwnedUser' }
    & Pick<OwnedUser, 'firstName' | 'lastName' | 'role'>
  )> }
);

export type ListOwnedUsersQueryVariables = Exact<{
  filter?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
}>;


export type ListOwnedUsersQuery = (
  { __typename?: 'Query' }
  & { listOwnedUsers?: Maybe<(
    { __typename?: 'ListOwnedUsersResult' }
    & Pick<ListOwnedUsersResult, 'limit' | 'offset' | 'total'>
    & { result?: Maybe<Array<Maybe<(
      { __typename?: 'OwnedUser' }
      & Pick<OwnedUser, 'id' | 'firstName' | 'lastName' | 'nickname' | 'email' | 'email_verified'>
    )>>> }
  )> }
);

export type ShowOwnedUserQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type ShowOwnedUserQuery = (
  { __typename?: 'Query' }
  & { showOwnedUser?: Maybe<(
    { __typename?: 'OwnedUser' }
    & OwnedUserFieldsFragment
  )> }
);

export type OwnedUserFieldsFragment = (
  { __typename?: 'OwnedUser' }
  & Pick<OwnedUser, 'id' | 'firstName' | 'lastName' | 'nickname' | 'email' | 'email_verified' | 'gender' | 'provider' | 'pubnub_uuid' | 'role'>
  & { preferences?: Maybe<(
    { __typename?: 'OwnedUserPreferences' }
    & Pick<OwnedUserPreferences, 'lang'>
  )>, birthDate?: Maybe<(
    { __typename?: 'BirthDate' }
    & Pick<BirthDate, 'private' | 'value'>
  )>, followedPrograms?: Maybe<Array<Maybe<(
    { __typename?: 'Program' }
    & Pick<Program, 'id' | 'name'>
  )>>>, ownedPrograms?: Maybe<Array<Maybe<(
    { __typename?: 'Program' }
    & Pick<Program, 'id' | 'name'>
  )>>> }
);

export type DeleteOwnedUserMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteOwnedUserMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteOwnedUser'>
);

export type UpdateOwnedUserEmailMutationVariables = Exact<{
  userId: Scalars['ID'];
  newEmail?: Maybe<Scalars['String']>;
}>;


export type UpdateOwnedUserEmailMutation = (
  { __typename?: 'Mutation' }
  & { updateOwnedUserEmail?: Maybe<(
    { __typename?: 'OwnedUser' }
    & Pick<OwnedUser, 'id' | 'email' | 'email_verified'>
  )> }
);

export type UpdateProgramMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type UpdateProgramMutation = (
  { __typename?: 'Mutation' }
  & { updateProgram?: Maybe<(
    { __typename?: 'Program' }
    & Pick<Program, 'id' | 'name'>
  )> }
);

export type ListReportedChatMessagesQueryVariables = Exact<{
  processed?: Maybe<Scalars['Boolean']>;
}>;


export type ListReportedChatMessagesQuery = (
  { __typename?: 'Query' }
  & { listReportedChatMessages?: Maybe<(
    { __typename?: 'ListReportedChatMessagesResult' }
    & Pick<ListReportedChatMessagesResult, 'limit' | 'total' | 'offset'>
    & { result?: Maybe<Array<Maybe<(
      { __typename?: 'ReportedChatMessage' }
      & Pick<ReportedChatMessage, 'id' | 'processed' | 'reportedBy' | 'createdAt'>
      & { message?: Maybe<(
        { __typename?: 'ChatMessage' }
        & Pick<ChatMessage, 'id' | 'content' | 'channel'>
      )> }
    )>>> }
  )> }
);

export type ShowReportedChatMessageQueryVariables = Exact<{
  messageId: Scalars['ID'];
}>;


export type ShowReportedChatMessageQuery = (
  { __typename?: 'Query' }
  & { showReportedChatMessage?: Maybe<(
    { __typename?: 'ReportedChatMessage' }
    & Pick<ReportedChatMessage, 'id' | 'processed' | 'reportedBy' | 'createdAt'>
    & { message?: Maybe<(
      { __typename?: 'ChatMessage' }
      & Pick<ChatMessage, 'id' | 'deleted' | 'content' | 'channel' | 'sender'>
      & { attachment?: Maybe<(
        { __typename?: 'ChatMessageAttachment' }
        & Pick<ChatMessageAttachment, 'url'>
      )> }
    )>, previousMessages?: Maybe<Array<Maybe<(
      { __typename?: 'ChatMessage' }
      & Pick<ChatMessage, 'id' | 'channel' | 'content' | 'deleted' | 'sender' | 'createdAt'>
      & { attachment?: Maybe<(
        { __typename?: 'ChatMessageAttachment' }
        & Pick<ChatMessageAttachment, 'url'>
      )> }
    )>>> }
  )> }
);

export type SetReportedMessageProcessedMutationVariables = Exact<{
  reportId: Scalars['ID'];
}>;


export type SetReportedMessageProcessedMutation = (
  { __typename?: 'Mutation' }
  & { setReportedMessageProcessed?: Maybe<(
    { __typename?: 'ReportedChatMessage' }
    & Pick<ReportedChatMessage, 'id' | 'processed'>
  )> }
);

export type DeleteReportedChatMessageMutationVariables = Exact<{
  messageId: Scalars['ID'];
}>;


export type DeleteReportedChatMessageMutation = (
  { __typename?: 'Mutation' }
  & { deleteReportedChatMessage?: Maybe<(
    { __typename?: 'ReportedChatMessage' }
    & Pick<ReportedChatMessage, 'id' | 'processed'>
    & { message?: Maybe<(
      { __typename?: 'ChatMessage' }
      & Pick<ChatMessage, 'id' | 'deleted' | 'content'>
      & { attachment?: Maybe<(
        { __typename?: 'ChatMessageAttachment' }
        & Pick<ChatMessageAttachment, 'url'>
      )> }
    )> }
  )> }
);

export type ListReportedCommentsQueryVariables = Exact<{
  processed?: Maybe<Scalars['Boolean']>;
}>;


export type ListReportedCommentsQuery = (
  { __typename?: 'Query' }
  & { listReportedComments?: Maybe<(
    { __typename?: 'ListReportedCommentsResult' }
    & Pick<ListReportedCommentsResult, 'limit' | 'total' | 'offset'>
    & { result?: Maybe<Array<Maybe<(
      { __typename?: 'ReportedComment' }
      & Pick<ReportedComment, 'id' | 'processed' | 'reportedBy' | 'createdAt'>
      & { comment?: Maybe<(
        { __typename?: 'Comment' }
        & Pick<Comment, 'id' | 'type'>
      )> }
    )>>> }
  )> }
);

export type ShowDailyStatsQueryVariables = Exact<{
  date?: Maybe<Scalars['Date']>;
}>;


export type ShowDailyStatsQuery = (
  { __typename?: 'Query' }
  & { showDailyStats?: Maybe<(
    { __typename?: 'DailyStats' }
    & { createdProfiles?: Maybe<(
      { __typename?: 'CreatedProfilesDailyStats' }
      & Pick<CreatedProfilesDailyStats, 'count' | 'previousDayCount'>
    )>, createdPrograms?: Maybe<(
      { __typename?: 'CreatedProgramsDailyStats' }
      & Pick<CreatedProgramsDailyStats, 'count' | 'previousDayCount'>
    )>, addedScores?: Maybe<(
      { __typename?: 'AddedScoresDailyStats' }
      & Pick<AddedScoresDailyStats, 'count' | 'previousDayCount'>
    )>, createdSessions?: Maybe<(
      { __typename?: 'CreatedSessionsDailyStats' }
      & Pick<CreatedSessionsDailyStats, 'count' | 'previousDayCount'>
    )> }
  )> }
);

export type ShowTotalStatsQueryVariables = Exact<{ [key: string]: never; }>;


export type ShowTotalStatsQuery = (
  { __typename?: 'Query' }
  & { showTotalStats?: Maybe<(
    { __typename?: 'TotalStats' }
    & Pick<TotalStats, 'createdProfiles' | 'emailVerifiedProfiles' | 'createdPrograms' | 'unPublishedPrograms' | 'addedScores' | 'addedFeedbacks' | 'usersWithAtLeastOnePersonalRecord' | 'createdPersonalRecords' | 'createdSessions' | 'usersWithAtLeastOneWodBookEntry' | 'createdWodBookEntries' | 'averageWodBookEntriesPerUser' | 'profilesWithPicture' | 'profilesWithPersonalData' | 'chronoConfigurations' | 'programSessionComments'>
    & { chatChannels?: Maybe<(
      { __typename?: 'ChatChannelsStat' }
      & Pick<ChatChannelsStat, 'program' | 'duo'>
    )>, chatMessages?: Maybe<(
      { __typename?: 'ChatMessagesStat' }
      & Pick<ChatMessagesStat, 'total' | 'text' | 'image'>
    )>, publishedPrograms?: Maybe<(
      { __typename?: 'PublishedProgramsStat' }
      & Pick<PublishedProgramsStat, 'public' | 'private'>
    )>, usersProgramFollowing?: Maybe<(
      { __typename?: 'UsersProgramFollowingStat' }
      & Pick<UsersProgramFollowingStat, 'one' | 'two' | 'three' | 'four' | 'fiveAndMore'>
    )> }
  )> }
);

export type ShowTotalCreatedRessourcesEvolutionQueryVariables = Exact<{
  range?: Maybe<EvolutionRange>;
  ressource: RessourceEvolutionAvailable;
}>;


export type ShowTotalCreatedRessourcesEvolutionQuery = (
  { __typename?: 'Query' }
  & { showTotalCreatedRessourcesEvolution?: Maybe<(
    { __typename?: 'TotalCreatedRessourcesEvolution' }
    & { counts?: Maybe<Array<Maybe<(
      { __typename?: 'TotalCreatedProfileForDate' }
      & Pick<TotalCreatedProfileForDate, 'date' | 'count'>
    )>>> }
  )> }
);

export type ListPublicWodsQueryVariables = Exact<{
  categoryId?: Maybe<Scalars['ID']>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
}>;


export type ListPublicWodsQuery = (
  { __typename?: 'Query' }
  & { listPublicWods?: Maybe<(
    { __typename?: 'ListPublicWodsResult' }
    & Pick<ListPublicWodsResult, 'limit' | 'offset' | 'total'>
    & { result?: Maybe<Array<Maybe<(
      { __typename?: 'PublicWod' }
      & Pick<PublicWod, 'id' | 'createdAt' | 'updatedAt' | 'published'>
      & { category?: Maybe<(
        { __typename?: 'PublicWodCategory' }
        & Pick<PublicWodCategory, 'id'>
        & { translations?: Maybe<Array<Maybe<(
          { __typename?: 'TranslatedCategory' }
          & Pick<TranslatedCategory, 'name' | 'lang'>
        )>>> }
      )>, exercise?: Maybe<(
        { __typename?: 'PublicWodExercise' }
        & { blockTranslations?: Maybe<Array<Maybe<(
          { __typename?: 'TranslatedExerciseBlocks' }
          & { blocks?: Maybe<Array<Maybe<(
            { __typename?: 'ExerciseBlock' }
            & Pick<ExerciseBlock, 'content' | 'elements' | 'id' | 'order'>
            & { image?: Maybe<(
              { __typename?: 'SessionExerciseBlockImage' }
              & Pick<SessionExerciseBlockImage, 'url'>
            )> }
          )>>> }
        )>>>, expectedScores?: Maybe<Array<Maybe<(
          { __typename?: 'ExerciseExpectedScore' }
          & Pick<ExerciseExpectedScore, 'sort' | 'type'>
        )>>>, nameTranslations?: Maybe<Array<Maybe<(
          { __typename?: 'TranslatedExerciseName' }
          & Pick<TranslatedExerciseName, 'name'>
        )>>> }
      )> }
    )>>> }
  )> }
);

export type ShowPublicWodQueryVariables = Exact<{
  wodId: Scalars['ID'];
}>;


export type ShowPublicWodQuery = (
  { __typename?: 'Query' }
  & { showPublicWod?: Maybe<(
    { __typename?: 'PublicWod' }
    & Pick<PublicWod, 'id' | 'createdAt' | 'updatedAt' | 'published'>
    & { category?: Maybe<(
      { __typename?: 'PublicWodCategory' }
      & Pick<PublicWodCategory, 'id'>
      & { translations?: Maybe<Array<Maybe<(
        { __typename?: 'TranslatedCategory' }
        & Pick<TranslatedCategory, 'name' | 'lang'>
      )>>> }
    )>, exercise?: Maybe<(
      { __typename?: 'PublicWodExercise' }
      & { blockTranslations?: Maybe<Array<Maybe<(
        { __typename?: 'TranslatedExerciseBlocks' }
        & { blocks?: Maybe<Array<Maybe<(
          { __typename?: 'ExerciseBlock' }
          & Pick<ExerciseBlock, 'content' | 'elements' | 'id' | 'order'>
          & { image?: Maybe<(
            { __typename?: 'SessionExerciseBlockImage' }
            & Pick<SessionExerciseBlockImage, 'url'>
          )> }
        )>>> }
      )>>>, expectedScores?: Maybe<Array<Maybe<(
        { __typename?: 'ExerciseExpectedScore' }
        & Pick<ExerciseExpectedScore, 'sort' | 'type'>
      )>>>, nameTranslations?: Maybe<Array<Maybe<(
        { __typename?: 'TranslatedExerciseName' }
        & Pick<TranslatedExerciseName, 'name'>
      )>>> }
    )> }
  )> }
);

export type CreatePublicWodMutationVariables = Exact<{
  exercise?: Maybe<PublicWodExerciseInput>;
  categoryId: Scalars['ID'];
}>;


export type CreatePublicWodMutation = (
  { __typename?: 'Mutation' }
  & { createPublicWod?: Maybe<(
    { __typename?: 'PublicWod' }
    & Pick<PublicWod, 'id'>
  )> }
);

export type DeletePublicWodMutationVariables = Exact<{
  wodId: Scalars['ID'];
}>;


export type DeletePublicWodMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deletePublicWod'>
);

export type UpdatePublicWodMutationVariables = Exact<{
  categoryId?: Maybe<Scalars['ID']>;
  expectedScores?: Maybe<Array<Maybe<ExpectedScoreInput>> | Maybe<ExpectedScoreInput>>;
  name?: Maybe<PublicWodExerciseNameInput>;
  wodId: Scalars['ID'];
}>;


export type UpdatePublicWodMutation = (
  { __typename?: 'Mutation' }
  & { updatePublicWod?: Maybe<(
    { __typename?: 'PublicWod' }
    & Pick<PublicWod, 'id' | 'createdAt' | 'updatedAt' | 'published'>
    & { category?: Maybe<(
      { __typename?: 'PublicWodCategory' }
      & Pick<PublicWodCategory, 'id'>
      & { translations?: Maybe<Array<Maybe<(
        { __typename?: 'TranslatedCategory' }
        & Pick<TranslatedCategory, 'name' | 'lang'>
      )>>> }
    )>, exercise?: Maybe<(
      { __typename?: 'PublicWodExercise' }
      & { blockTranslations?: Maybe<Array<Maybe<(
        { __typename?: 'TranslatedExerciseBlocks' }
        & { blocks?: Maybe<Array<Maybe<(
          { __typename?: 'ExerciseBlock' }
          & Pick<ExerciseBlock, 'content' | 'elements' | 'id' | 'order'>
          & { image?: Maybe<(
            { __typename?: 'SessionExerciseBlockImage' }
            & Pick<SessionExerciseBlockImage, 'url'>
          )> }
        )>>> }
      )>>>, expectedScores?: Maybe<Array<Maybe<(
        { __typename?: 'ExerciseExpectedScore' }
        & Pick<ExerciseExpectedScore, 'sort' | 'type'>
      )>>>, nameTranslations?: Maybe<Array<Maybe<(
        { __typename?: 'TranslatedExerciseName' }
        & Pick<TranslatedExerciseName, 'name'>
      )>>> }
    )> }
  )> }
);

export type AddPublicWodBlockMutationVariables = Exact<{
  content?: Maybe<Scalars['String']>;
  elements?: Maybe<Array<Maybe<Scalars['String']>> | Maybe<Scalars['String']>>;
  image?: Maybe<Scalars['Upload']>;
  lang: AvailableLanguage;
  type: SessionExerciseBlockType;
  wodId: Scalars['ID'];
}>;


export type AddPublicWodBlockMutation = (
  { __typename?: 'Mutation' }
  & { addPublicWodBlock?: Maybe<(
    { __typename?: 'ExerciseBlock' }
    & Pick<ExerciseBlock, 'id' | 'content'>
  )> }
);

export type UpdatePublicWodBlockMutationVariables = Exact<{
  blockId: Scalars['ID'];
  content?: Maybe<Scalars['String']>;
  elements?: Maybe<Array<Maybe<Scalars['String']>> | Maybe<Scalars['String']>>;
  image?: Maybe<Scalars['Upload']>;
  lang: AvailableLanguage;
  type: SessionExerciseBlockType;
  wodId: Scalars['ID'];
}>;


export type UpdatePublicWodBlockMutation = (
  { __typename?: 'Mutation' }
  & { updatePublicWodBlock?: Maybe<(
    { __typename?: 'ExerciseBlock' }
    & Pick<ExerciseBlock, 'id' | 'content'>
  )> }
);

export type ListPublicWodCategoriesQueryVariables = Exact<{
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  showUnpublished?: Maybe<Scalars['Boolean']>;
}>;


export type ListPublicWodCategoriesQuery = (
  { __typename?: 'Query' }
  & { listPublicWodCategories?: Maybe<(
    { __typename?: 'ListPublicWodCategoriesResult' }
    & Pick<ListPublicWodCategoriesResult, 'limit' | 'offset' | 'total'>
    & { result?: Maybe<Array<Maybe<(
      { __typename?: 'PublicWodCategory' }
      & Pick<PublicWodCategory, 'id' | 'published'>
      & { translations?: Maybe<Array<Maybe<(
        { __typename?: 'TranslatedCategory' }
        & Pick<TranslatedCategory, 'lang' | 'name'>
      )>>> }
    )>>> }
  )> }
);

export type CreatePublicWodCategoryMutationVariables = Exact<{
  name?: Maybe<PublicWodCategoryNameInput>;
}>;


export type CreatePublicWodCategoryMutation = (
  { __typename?: 'Mutation' }
  & { createPublicWodCategory?: Maybe<(
    { __typename?: 'PublicWodCategory' }
    & Pick<PublicWodCategory, 'id' | 'published'>
    & { translations?: Maybe<Array<Maybe<(
      { __typename?: 'TranslatedCategory' }
      & Pick<TranslatedCategory, 'lang' | 'name'>
    )>>> }
  )> }
);

export type DeletePublicWodCategoryMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeletePublicWodCategoryMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deletePublicWodCategory'>
);

export type UpdatePublicWodCategoryMutationVariables = Exact<{
  id: Scalars['ID'];
  name?: Maybe<PublicWodCategoryNameInput>;
  published?: Maybe<Scalars['Boolean']>;
}>;


export type UpdatePublicWodCategoryMutation = (
  { __typename?: 'Mutation' }
  & { updatePublicWodCategory?: Maybe<(
    { __typename?: 'PublicWodCategory' }
    & Pick<PublicWodCategory, 'id' | 'published'>
    & { translations?: Maybe<Array<Maybe<(
      { __typename?: 'TranslatedCategory' }
      & Pick<TranslatedCategory, 'lang' | 'name'>
    )>>> }
  )> }
);

export const OwnedUserFieldsFragmentDoc = gql`
    fragment OwnedUserFields on OwnedUser {
  id
  firstName
  lastName
  nickname
  email
  email_verified
  preferences {
    lang
  }
  birthDate {
    private
    value
  }
  followedPrograms {
    id
    name
  }
  ownedPrograms {
    id
    name
  }
  gender
  provider
  pubnub_uuid
  role
}
    `;
export const MeDocument = gql`
    query me {
  me {
    firstName
    lastName
    role
  }
}
    `;

/**
 * __useMeQuery__
 *
 * To run a query within a React component, call `useMeQuery` and pass it any options that fit your needs.
 * When your component renders, `useMeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMeQuery({
 *   variables: {
 *   },
 * });
 */
export function useMeQuery(baseOptions?: Apollo.QueryHookOptions<MeQuery, MeQueryVariables>) {
        return Apollo.useQuery<MeQuery, MeQueryVariables>(MeDocument, baseOptions);
      }
export function useMeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MeQuery, MeQueryVariables>) {
          return Apollo.useLazyQuery<MeQuery, MeQueryVariables>(MeDocument, baseOptions);
        }
export type MeQueryHookResult = ReturnType<typeof useMeQuery>;
export type MeLazyQueryHookResult = ReturnType<typeof useMeLazyQuery>;
export type MeQueryResult = Apollo.QueryResult<MeQuery, MeQueryVariables>;
export const ListOwnedUsersDocument = gql`
    query listOwnedUsers($filter: String, $limit: Int, $offset: Int) {
  listOwnedUsers(
    input: {limit: $limit, offset: $offset, filter: {nameOrNicknameOrEmail: {contains: $filter}}}
  ) {
    limit
    offset
    total
    result {
      id
      firstName
      lastName
      nickname
      email
      email_verified
    }
  }
}
    `;

/**
 * __useListOwnedUsersQuery__
 *
 * To run a query within a React component, call `useListOwnedUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useListOwnedUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListOwnedUsersQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useListOwnedUsersQuery(baseOptions?: Apollo.QueryHookOptions<ListOwnedUsersQuery, ListOwnedUsersQueryVariables>) {
        return Apollo.useQuery<ListOwnedUsersQuery, ListOwnedUsersQueryVariables>(ListOwnedUsersDocument, baseOptions);
      }
export function useListOwnedUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListOwnedUsersQuery, ListOwnedUsersQueryVariables>) {
          return Apollo.useLazyQuery<ListOwnedUsersQuery, ListOwnedUsersQueryVariables>(ListOwnedUsersDocument, baseOptions);
        }
export type ListOwnedUsersQueryHookResult = ReturnType<typeof useListOwnedUsersQuery>;
export type ListOwnedUsersLazyQueryHookResult = ReturnType<typeof useListOwnedUsersLazyQuery>;
export type ListOwnedUsersQueryResult = Apollo.QueryResult<ListOwnedUsersQuery, ListOwnedUsersQueryVariables>;
export const ShowOwnedUserDocument = gql`
    query showOwnedUser($id: ID!) {
  showOwnedUser(input: {id: $id}) {
    ...OwnedUserFields
  }
}
    ${OwnedUserFieldsFragmentDoc}`;

/**
 * __useShowOwnedUserQuery__
 *
 * To run a query within a React component, call `useShowOwnedUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useShowOwnedUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useShowOwnedUserQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useShowOwnedUserQuery(baseOptions: Apollo.QueryHookOptions<ShowOwnedUserQuery, ShowOwnedUserQueryVariables>) {
        return Apollo.useQuery<ShowOwnedUserQuery, ShowOwnedUserQueryVariables>(ShowOwnedUserDocument, baseOptions);
      }
export function useShowOwnedUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ShowOwnedUserQuery, ShowOwnedUserQueryVariables>) {
          return Apollo.useLazyQuery<ShowOwnedUserQuery, ShowOwnedUserQueryVariables>(ShowOwnedUserDocument, baseOptions);
        }
export type ShowOwnedUserQueryHookResult = ReturnType<typeof useShowOwnedUserQuery>;
export type ShowOwnedUserLazyQueryHookResult = ReturnType<typeof useShowOwnedUserLazyQuery>;
export type ShowOwnedUserQueryResult = Apollo.QueryResult<ShowOwnedUserQuery, ShowOwnedUserQueryVariables>;
export const DeleteOwnedUserDocument = gql`
    mutation deleteOwnedUser($id: ID!) {
  deleteOwnedUser(input: {id: $id})
}
    `;
export type DeleteOwnedUserMutationFn = Apollo.MutationFunction<DeleteOwnedUserMutation, DeleteOwnedUserMutationVariables>;

/**
 * __useDeleteOwnedUserMutation__
 *
 * To run a mutation, you first call `useDeleteOwnedUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteOwnedUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteOwnedUserMutation, { data, loading, error }] = useDeleteOwnedUserMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteOwnedUserMutation(baseOptions?: Apollo.MutationHookOptions<DeleteOwnedUserMutation, DeleteOwnedUserMutationVariables>) {
        return Apollo.useMutation<DeleteOwnedUserMutation, DeleteOwnedUserMutationVariables>(DeleteOwnedUserDocument, baseOptions);
      }
export type DeleteOwnedUserMutationHookResult = ReturnType<typeof useDeleteOwnedUserMutation>;
export type DeleteOwnedUserMutationResult = Apollo.MutationResult<DeleteOwnedUserMutation>;
export type DeleteOwnedUserMutationOptions = Apollo.BaseMutationOptions<DeleteOwnedUserMutation, DeleteOwnedUserMutationVariables>;
export const UpdateOwnedUserEmailDocument = gql`
    mutation updateOwnedUserEmail($userId: ID!, $newEmail: String) {
  updateOwnedUserEmail(input: {newEmail: $newEmail, userId: $userId}) {
    id
    email
    email_verified
  }
}
    `;
export type UpdateOwnedUserEmailMutationFn = Apollo.MutationFunction<UpdateOwnedUserEmailMutation, UpdateOwnedUserEmailMutationVariables>;

/**
 * __useUpdateOwnedUserEmailMutation__
 *
 * To run a mutation, you first call `useUpdateOwnedUserEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOwnedUserEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOwnedUserEmailMutation, { data, loading, error }] = useUpdateOwnedUserEmailMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      newEmail: // value for 'newEmail'
 *   },
 * });
 */
export function useUpdateOwnedUserEmailMutation(baseOptions?: Apollo.MutationHookOptions<UpdateOwnedUserEmailMutation, UpdateOwnedUserEmailMutationVariables>) {
        return Apollo.useMutation<UpdateOwnedUserEmailMutation, UpdateOwnedUserEmailMutationVariables>(UpdateOwnedUserEmailDocument, baseOptions);
      }
export type UpdateOwnedUserEmailMutationHookResult = ReturnType<typeof useUpdateOwnedUserEmailMutation>;
export type UpdateOwnedUserEmailMutationResult = Apollo.MutationResult<UpdateOwnedUserEmailMutation>;
export type UpdateOwnedUserEmailMutationOptions = Apollo.BaseMutationOptions<UpdateOwnedUserEmailMutation, UpdateOwnedUserEmailMutationVariables>;
export const UpdateProgramDocument = gql`
    mutation updateProgram($id: ID!) {
  updateProgram(input: {id: $id, private: true}) {
    id
    name
  }
}
    `;
export type UpdateProgramMutationFn = Apollo.MutationFunction<UpdateProgramMutation, UpdateProgramMutationVariables>;

/**
 * __useUpdateProgramMutation__
 *
 * To run a mutation, you first call `useUpdateProgramMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProgramMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProgramMutation, { data, loading, error }] = useUpdateProgramMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUpdateProgramMutation(baseOptions?: Apollo.MutationHookOptions<UpdateProgramMutation, UpdateProgramMutationVariables>) {
        return Apollo.useMutation<UpdateProgramMutation, UpdateProgramMutationVariables>(UpdateProgramDocument, baseOptions);
      }
export type UpdateProgramMutationHookResult = ReturnType<typeof useUpdateProgramMutation>;
export type UpdateProgramMutationResult = Apollo.MutationResult<UpdateProgramMutation>;
export type UpdateProgramMutationOptions = Apollo.BaseMutationOptions<UpdateProgramMutation, UpdateProgramMutationVariables>;
export const ListReportedChatMessagesDocument = gql`
    query listReportedChatMessages($processed: Boolean) {
  listReportedChatMessages(input: {processed: $processed}) {
    limit
    total
    offset
    result {
      id
      message {
        id
        content
        channel
      }
      processed
      reportedBy
      createdAt
    }
  }
}
    `;

/**
 * __useListReportedChatMessagesQuery__
 *
 * To run a query within a React component, call `useListReportedChatMessagesQuery` and pass it any options that fit your needs.
 * When your component renders, `useListReportedChatMessagesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListReportedChatMessagesQuery({
 *   variables: {
 *      processed: // value for 'processed'
 *   },
 * });
 */
export function useListReportedChatMessagesQuery(baseOptions?: Apollo.QueryHookOptions<ListReportedChatMessagesQuery, ListReportedChatMessagesQueryVariables>) {
        return Apollo.useQuery<ListReportedChatMessagesQuery, ListReportedChatMessagesQueryVariables>(ListReportedChatMessagesDocument, baseOptions);
      }
export function useListReportedChatMessagesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListReportedChatMessagesQuery, ListReportedChatMessagesQueryVariables>) {
          return Apollo.useLazyQuery<ListReportedChatMessagesQuery, ListReportedChatMessagesQueryVariables>(ListReportedChatMessagesDocument, baseOptions);
        }
export type ListReportedChatMessagesQueryHookResult = ReturnType<typeof useListReportedChatMessagesQuery>;
export type ListReportedChatMessagesLazyQueryHookResult = ReturnType<typeof useListReportedChatMessagesLazyQuery>;
export type ListReportedChatMessagesQueryResult = Apollo.QueryResult<ListReportedChatMessagesQuery, ListReportedChatMessagesQueryVariables>;
export const ShowReportedChatMessageDocument = gql`
    query showReportedChatMessage($messageId: ID!) {
  showReportedChatMessage(input: {messageId: $messageId}) {
    id
    message {
      id
      deleted
      content
      attachment {
        url
      }
      channel
      sender
    }
    previousMessages {
      id
      channel
      content
      attachment {
        url
      }
      deleted
      sender
      createdAt
    }
    processed
    reportedBy
    createdAt
  }
}
    `;

/**
 * __useShowReportedChatMessageQuery__
 *
 * To run a query within a React component, call `useShowReportedChatMessageQuery` and pass it any options that fit your needs.
 * When your component renders, `useShowReportedChatMessageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useShowReportedChatMessageQuery({
 *   variables: {
 *      messageId: // value for 'messageId'
 *   },
 * });
 */
export function useShowReportedChatMessageQuery(baseOptions: Apollo.QueryHookOptions<ShowReportedChatMessageQuery, ShowReportedChatMessageQueryVariables>) {
        return Apollo.useQuery<ShowReportedChatMessageQuery, ShowReportedChatMessageQueryVariables>(ShowReportedChatMessageDocument, baseOptions);
      }
export function useShowReportedChatMessageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ShowReportedChatMessageQuery, ShowReportedChatMessageQueryVariables>) {
          return Apollo.useLazyQuery<ShowReportedChatMessageQuery, ShowReportedChatMessageQueryVariables>(ShowReportedChatMessageDocument, baseOptions);
        }
export type ShowReportedChatMessageQueryHookResult = ReturnType<typeof useShowReportedChatMessageQuery>;
export type ShowReportedChatMessageLazyQueryHookResult = ReturnType<typeof useShowReportedChatMessageLazyQuery>;
export type ShowReportedChatMessageQueryResult = Apollo.QueryResult<ShowReportedChatMessageQuery, ShowReportedChatMessageQueryVariables>;
export const SetReportedMessageProcessedDocument = gql`
    mutation setReportedMessageProcessed($reportId: ID!) {
  setReportedMessageProcessed(input: {reportId: $reportId}) {
    id
    processed
  }
}
    `;
export type SetReportedMessageProcessedMutationFn = Apollo.MutationFunction<SetReportedMessageProcessedMutation, SetReportedMessageProcessedMutationVariables>;

/**
 * __useSetReportedMessageProcessedMutation__
 *
 * To run a mutation, you first call `useSetReportedMessageProcessedMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetReportedMessageProcessedMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setReportedMessageProcessedMutation, { data, loading, error }] = useSetReportedMessageProcessedMutation({
 *   variables: {
 *      reportId: // value for 'reportId'
 *   },
 * });
 */
export function useSetReportedMessageProcessedMutation(baseOptions?: Apollo.MutationHookOptions<SetReportedMessageProcessedMutation, SetReportedMessageProcessedMutationVariables>) {
        return Apollo.useMutation<SetReportedMessageProcessedMutation, SetReportedMessageProcessedMutationVariables>(SetReportedMessageProcessedDocument, baseOptions);
      }
export type SetReportedMessageProcessedMutationHookResult = ReturnType<typeof useSetReportedMessageProcessedMutation>;
export type SetReportedMessageProcessedMutationResult = Apollo.MutationResult<SetReportedMessageProcessedMutation>;
export type SetReportedMessageProcessedMutationOptions = Apollo.BaseMutationOptions<SetReportedMessageProcessedMutation, SetReportedMessageProcessedMutationVariables>;
export const DeleteReportedChatMessageDocument = gql`
    mutation deleteReportedChatMessage($messageId: ID!) {
  deleteReportedChatMessage(input: {messageId: $messageId}) {
    id
    message {
      id
      deleted
      content
      attachment {
        url
      }
    }
    processed
  }
}
    `;
export type DeleteReportedChatMessageMutationFn = Apollo.MutationFunction<DeleteReportedChatMessageMutation, DeleteReportedChatMessageMutationVariables>;

/**
 * __useDeleteReportedChatMessageMutation__
 *
 * To run a mutation, you first call `useDeleteReportedChatMessageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteReportedChatMessageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteReportedChatMessageMutation, { data, loading, error }] = useDeleteReportedChatMessageMutation({
 *   variables: {
 *      messageId: // value for 'messageId'
 *   },
 * });
 */
export function useDeleteReportedChatMessageMutation(baseOptions?: Apollo.MutationHookOptions<DeleteReportedChatMessageMutation, DeleteReportedChatMessageMutationVariables>) {
        return Apollo.useMutation<DeleteReportedChatMessageMutation, DeleteReportedChatMessageMutationVariables>(DeleteReportedChatMessageDocument, baseOptions);
      }
export type DeleteReportedChatMessageMutationHookResult = ReturnType<typeof useDeleteReportedChatMessageMutation>;
export type DeleteReportedChatMessageMutationResult = Apollo.MutationResult<DeleteReportedChatMessageMutation>;
export type DeleteReportedChatMessageMutationOptions = Apollo.BaseMutationOptions<DeleteReportedChatMessageMutation, DeleteReportedChatMessageMutationVariables>;
export const ListReportedCommentsDocument = gql`
    query listReportedComments($processed: Boolean) {
  listReportedComments(input: {processed: $processed}) {
    limit
    total
    offset
    result {
      id
      comment {
        id
        type
      }
      processed
      reportedBy
      createdAt
    }
  }
}
    `;

/**
 * __useListReportedCommentsQuery__
 *
 * To run a query within a React component, call `useListReportedCommentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListReportedCommentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListReportedCommentsQuery({
 *   variables: {
 *      processed: // value for 'processed'
 *   },
 * });
 */
export function useListReportedCommentsQuery(baseOptions?: Apollo.QueryHookOptions<ListReportedCommentsQuery, ListReportedCommentsQueryVariables>) {
        return Apollo.useQuery<ListReportedCommentsQuery, ListReportedCommentsQueryVariables>(ListReportedCommentsDocument, baseOptions);
      }
export function useListReportedCommentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListReportedCommentsQuery, ListReportedCommentsQueryVariables>) {
          return Apollo.useLazyQuery<ListReportedCommentsQuery, ListReportedCommentsQueryVariables>(ListReportedCommentsDocument, baseOptions);
        }
export type ListReportedCommentsQueryHookResult = ReturnType<typeof useListReportedCommentsQuery>;
export type ListReportedCommentsLazyQueryHookResult = ReturnType<typeof useListReportedCommentsLazyQuery>;
export type ListReportedCommentsQueryResult = Apollo.QueryResult<ListReportedCommentsQuery, ListReportedCommentsQueryVariables>;
export const ShowDailyStatsDocument = gql`
    query showDailyStats($date: Date) {
  showDailyStats(input: {date: $date}) {
    createdProfiles {
      count
      previousDayCount
    }
    createdPrograms {
      count
      previousDayCount
    }
    addedScores {
      count
      previousDayCount
    }
    createdSessions {
      count
      previousDayCount
    }
  }
}
    `;

/**
 * __useShowDailyStatsQuery__
 *
 * To run a query within a React component, call `useShowDailyStatsQuery` and pass it any options that fit your needs.
 * When your component renders, `useShowDailyStatsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useShowDailyStatsQuery({
 *   variables: {
 *      date: // value for 'date'
 *   },
 * });
 */
export function useShowDailyStatsQuery(baseOptions?: Apollo.QueryHookOptions<ShowDailyStatsQuery, ShowDailyStatsQueryVariables>) {
        return Apollo.useQuery<ShowDailyStatsQuery, ShowDailyStatsQueryVariables>(ShowDailyStatsDocument, baseOptions);
      }
export function useShowDailyStatsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ShowDailyStatsQuery, ShowDailyStatsQueryVariables>) {
          return Apollo.useLazyQuery<ShowDailyStatsQuery, ShowDailyStatsQueryVariables>(ShowDailyStatsDocument, baseOptions);
        }
export type ShowDailyStatsQueryHookResult = ReturnType<typeof useShowDailyStatsQuery>;
export type ShowDailyStatsLazyQueryHookResult = ReturnType<typeof useShowDailyStatsLazyQuery>;
export type ShowDailyStatsQueryResult = Apollo.QueryResult<ShowDailyStatsQuery, ShowDailyStatsQueryVariables>;
export const ShowTotalStatsDocument = gql`
    query showTotalStats {
  showTotalStats {
    createdProfiles
    emailVerifiedProfiles
    chatChannels {
      program
      duo
    }
    chatMessages {
      total
      text
      image
    }
    createdPrograms
    publishedPrograms {
      public
      private
    }
    unPublishedPrograms
    addedScores
    addedFeedbacks
    usersWithAtLeastOnePersonalRecord
    createdPersonalRecords
    createdSessions
    usersWithAtLeastOneWodBookEntry
    createdWodBookEntries
    averageWodBookEntriesPerUser
    profilesWithPicture
    profilesWithPersonalData
    usersProgramFollowing {
      one
      two
      three
      four
      fiveAndMore
    }
    chronoConfigurations
    programSessionComments
  }
}
    `;

/**
 * __useShowTotalStatsQuery__
 *
 * To run a query within a React component, call `useShowTotalStatsQuery` and pass it any options that fit your needs.
 * When your component renders, `useShowTotalStatsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useShowTotalStatsQuery({
 *   variables: {
 *   },
 * });
 */
export function useShowTotalStatsQuery(baseOptions?: Apollo.QueryHookOptions<ShowTotalStatsQuery, ShowTotalStatsQueryVariables>) {
        return Apollo.useQuery<ShowTotalStatsQuery, ShowTotalStatsQueryVariables>(ShowTotalStatsDocument, baseOptions);
      }
export function useShowTotalStatsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ShowTotalStatsQuery, ShowTotalStatsQueryVariables>) {
          return Apollo.useLazyQuery<ShowTotalStatsQuery, ShowTotalStatsQueryVariables>(ShowTotalStatsDocument, baseOptions);
        }
export type ShowTotalStatsQueryHookResult = ReturnType<typeof useShowTotalStatsQuery>;
export type ShowTotalStatsLazyQueryHookResult = ReturnType<typeof useShowTotalStatsLazyQuery>;
export type ShowTotalStatsQueryResult = Apollo.QueryResult<ShowTotalStatsQuery, ShowTotalStatsQueryVariables>;
export const ShowTotalCreatedRessourcesEvolutionDocument = gql`
    query showTotalCreatedRessourcesEvolution($range: EvolutionRange, $ressource: RessourceEvolutionAvailable!) {
  showTotalCreatedRessourcesEvolution(
    input: {range: $range, ressource: $ressource}
  ) {
    counts {
      date
      count
    }
  }
}
    `;

/**
 * __useShowTotalCreatedRessourcesEvolutionQuery__
 *
 * To run a query within a React component, call `useShowTotalCreatedRessourcesEvolutionQuery` and pass it any options that fit your needs.
 * When your component renders, `useShowTotalCreatedRessourcesEvolutionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useShowTotalCreatedRessourcesEvolutionQuery({
 *   variables: {
 *      range: // value for 'range'
 *      ressource: // value for 'ressource'
 *   },
 * });
 */
export function useShowTotalCreatedRessourcesEvolutionQuery(baseOptions: Apollo.QueryHookOptions<ShowTotalCreatedRessourcesEvolutionQuery, ShowTotalCreatedRessourcesEvolutionQueryVariables>) {
        return Apollo.useQuery<ShowTotalCreatedRessourcesEvolutionQuery, ShowTotalCreatedRessourcesEvolutionQueryVariables>(ShowTotalCreatedRessourcesEvolutionDocument, baseOptions);
      }
export function useShowTotalCreatedRessourcesEvolutionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ShowTotalCreatedRessourcesEvolutionQuery, ShowTotalCreatedRessourcesEvolutionQueryVariables>) {
          return Apollo.useLazyQuery<ShowTotalCreatedRessourcesEvolutionQuery, ShowTotalCreatedRessourcesEvolutionQueryVariables>(ShowTotalCreatedRessourcesEvolutionDocument, baseOptions);
        }
export type ShowTotalCreatedRessourcesEvolutionQueryHookResult = ReturnType<typeof useShowTotalCreatedRessourcesEvolutionQuery>;
export type ShowTotalCreatedRessourcesEvolutionLazyQueryHookResult = ReturnType<typeof useShowTotalCreatedRessourcesEvolutionLazyQuery>;
export type ShowTotalCreatedRessourcesEvolutionQueryResult = Apollo.QueryResult<ShowTotalCreatedRessourcesEvolutionQuery, ShowTotalCreatedRessourcesEvolutionQueryVariables>;
export const ListPublicWodsDocument = gql`
    query listPublicWods($categoryId: ID, $limit: Int, $offset: Int) {
  listPublicWods(input: {limit: $limit, offset: $offset, categoryId: $categoryId}) {
    limit
    offset
    total
    result {
      id
      category {
        id
        translations {
          name
          lang
        }
      }
      createdAt
      updatedAt
      exercise {
        blockTranslations {
          blocks {
            content
            elements
            id
            image {
              url
            }
            order
          }
        }
        expectedScores {
          sort
          type
        }
        nameTranslations {
          name
        }
      }
      published
    }
  }
}
    `;

/**
 * __useListPublicWodsQuery__
 *
 * To run a query within a React component, call `useListPublicWodsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListPublicWodsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListPublicWodsQuery({
 *   variables: {
 *      categoryId: // value for 'categoryId'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useListPublicWodsQuery(baseOptions?: Apollo.QueryHookOptions<ListPublicWodsQuery, ListPublicWodsQueryVariables>) {
        return Apollo.useQuery<ListPublicWodsQuery, ListPublicWodsQueryVariables>(ListPublicWodsDocument, baseOptions);
      }
export function useListPublicWodsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListPublicWodsQuery, ListPublicWodsQueryVariables>) {
          return Apollo.useLazyQuery<ListPublicWodsQuery, ListPublicWodsQueryVariables>(ListPublicWodsDocument, baseOptions);
        }
export type ListPublicWodsQueryHookResult = ReturnType<typeof useListPublicWodsQuery>;
export type ListPublicWodsLazyQueryHookResult = ReturnType<typeof useListPublicWodsLazyQuery>;
export type ListPublicWodsQueryResult = Apollo.QueryResult<ListPublicWodsQuery, ListPublicWodsQueryVariables>;
export const ShowPublicWodDocument = gql`
    query showPublicWod($wodId: ID!) {
  showPublicWod(input: {wodId: $wodId}) {
    id
    category {
      id
      translations {
        name
        lang
      }
    }
    createdAt
    updatedAt
    exercise {
      blockTranslations {
        blocks {
          content
          elements
          id
          image {
            url
          }
          order
        }
      }
      expectedScores {
        sort
        type
      }
      nameTranslations {
        name
      }
    }
    published
  }
}
    `;

/**
 * __useShowPublicWodQuery__
 *
 * To run a query within a React component, call `useShowPublicWodQuery` and pass it any options that fit your needs.
 * When your component renders, `useShowPublicWodQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useShowPublicWodQuery({
 *   variables: {
 *      wodId: // value for 'wodId'
 *   },
 * });
 */
export function useShowPublicWodQuery(baseOptions: Apollo.QueryHookOptions<ShowPublicWodQuery, ShowPublicWodQueryVariables>) {
        return Apollo.useQuery<ShowPublicWodQuery, ShowPublicWodQueryVariables>(ShowPublicWodDocument, baseOptions);
      }
export function useShowPublicWodLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ShowPublicWodQuery, ShowPublicWodQueryVariables>) {
          return Apollo.useLazyQuery<ShowPublicWodQuery, ShowPublicWodQueryVariables>(ShowPublicWodDocument, baseOptions);
        }
export type ShowPublicWodQueryHookResult = ReturnType<typeof useShowPublicWodQuery>;
export type ShowPublicWodLazyQueryHookResult = ReturnType<typeof useShowPublicWodLazyQuery>;
export type ShowPublicWodQueryResult = Apollo.QueryResult<ShowPublicWodQuery, ShowPublicWodQueryVariables>;
export const CreatePublicWodDocument = gql`
    mutation createPublicWod($exercise: PublicWodExerciseInput, $categoryId: ID!) {
  createPublicWod(input: {exercise: $exercise, categoryId: $categoryId}) {
    id
  }
}
    `;
export type CreatePublicWodMutationFn = Apollo.MutationFunction<CreatePublicWodMutation, CreatePublicWodMutationVariables>;

/**
 * __useCreatePublicWodMutation__
 *
 * To run a mutation, you first call `useCreatePublicWodMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePublicWodMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPublicWodMutation, { data, loading, error }] = useCreatePublicWodMutation({
 *   variables: {
 *      exercise: // value for 'exercise'
 *      categoryId: // value for 'categoryId'
 *   },
 * });
 */
export function useCreatePublicWodMutation(baseOptions?: Apollo.MutationHookOptions<CreatePublicWodMutation, CreatePublicWodMutationVariables>) {
        return Apollo.useMutation<CreatePublicWodMutation, CreatePublicWodMutationVariables>(CreatePublicWodDocument, baseOptions);
      }
export type CreatePublicWodMutationHookResult = ReturnType<typeof useCreatePublicWodMutation>;
export type CreatePublicWodMutationResult = Apollo.MutationResult<CreatePublicWodMutation>;
export type CreatePublicWodMutationOptions = Apollo.BaseMutationOptions<CreatePublicWodMutation, CreatePublicWodMutationVariables>;
export const DeletePublicWodDocument = gql`
    mutation deletePublicWod($wodId: ID!) {
  deletePublicWod(input: {wodId: $wodId})
}
    `;
export type DeletePublicWodMutationFn = Apollo.MutationFunction<DeletePublicWodMutation, DeletePublicWodMutationVariables>;

/**
 * __useDeletePublicWodMutation__
 *
 * To run a mutation, you first call `useDeletePublicWodMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePublicWodMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePublicWodMutation, { data, loading, error }] = useDeletePublicWodMutation({
 *   variables: {
 *      wodId: // value for 'wodId'
 *   },
 * });
 */
export function useDeletePublicWodMutation(baseOptions?: Apollo.MutationHookOptions<DeletePublicWodMutation, DeletePublicWodMutationVariables>) {
        return Apollo.useMutation<DeletePublicWodMutation, DeletePublicWodMutationVariables>(DeletePublicWodDocument, baseOptions);
      }
export type DeletePublicWodMutationHookResult = ReturnType<typeof useDeletePublicWodMutation>;
export type DeletePublicWodMutationResult = Apollo.MutationResult<DeletePublicWodMutation>;
export type DeletePublicWodMutationOptions = Apollo.BaseMutationOptions<DeletePublicWodMutation, DeletePublicWodMutationVariables>;
export const UpdatePublicWodDocument = gql`
    mutation updatePublicWod($categoryId: ID, $expectedScores: [ExpectedScoreInput], $name: PublicWodExerciseNameInput, $wodId: ID!) {
  updatePublicWod(
    input: {categoryId: $categoryId, expectedScores: $expectedScores, name: $name, wodId: $wodId}
  ) {
    id
    category {
      id
      translations {
        name
        lang
      }
    }
    createdAt
    updatedAt
    exercise {
      blockTranslations {
        blocks {
          content
          elements
          id
          image {
            url
          }
          order
        }
      }
      expectedScores {
        sort
        type
      }
      nameTranslations {
        name
      }
    }
    published
  }
}
    `;
export type UpdatePublicWodMutationFn = Apollo.MutationFunction<UpdatePublicWodMutation, UpdatePublicWodMutationVariables>;

/**
 * __useUpdatePublicWodMutation__
 *
 * To run a mutation, you first call `useUpdatePublicWodMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePublicWodMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePublicWodMutation, { data, loading, error }] = useUpdatePublicWodMutation({
 *   variables: {
 *      categoryId: // value for 'categoryId'
 *      expectedScores: // value for 'expectedScores'
 *      name: // value for 'name'
 *      wodId: // value for 'wodId'
 *   },
 * });
 */
export function useUpdatePublicWodMutation(baseOptions?: Apollo.MutationHookOptions<UpdatePublicWodMutation, UpdatePublicWodMutationVariables>) {
        return Apollo.useMutation<UpdatePublicWodMutation, UpdatePublicWodMutationVariables>(UpdatePublicWodDocument, baseOptions);
      }
export type UpdatePublicWodMutationHookResult = ReturnType<typeof useUpdatePublicWodMutation>;
export type UpdatePublicWodMutationResult = Apollo.MutationResult<UpdatePublicWodMutation>;
export type UpdatePublicWodMutationOptions = Apollo.BaseMutationOptions<UpdatePublicWodMutation, UpdatePublicWodMutationVariables>;
export const AddPublicWodBlockDocument = gql`
    mutation addPublicWodBlock($content: String, $elements: [String], $image: Upload, $lang: AvailableLanguage!, $type: SessionExerciseBlockType!, $wodId: ID!) {
  addPublicWodBlock(
    input: {content: $content, elements: $elements, image: $image, lang: $lang, type: $type, wodId: $wodId}
  ) {
    id
    content
  }
}
    `;
export type AddPublicWodBlockMutationFn = Apollo.MutationFunction<AddPublicWodBlockMutation, AddPublicWodBlockMutationVariables>;

/**
 * __useAddPublicWodBlockMutation__
 *
 * To run a mutation, you first call `useAddPublicWodBlockMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddPublicWodBlockMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addPublicWodBlockMutation, { data, loading, error }] = useAddPublicWodBlockMutation({
 *   variables: {
 *      content: // value for 'content'
 *      elements: // value for 'elements'
 *      image: // value for 'image'
 *      lang: // value for 'lang'
 *      type: // value for 'type'
 *      wodId: // value for 'wodId'
 *   },
 * });
 */
export function useAddPublicWodBlockMutation(baseOptions?: Apollo.MutationHookOptions<AddPublicWodBlockMutation, AddPublicWodBlockMutationVariables>) {
        return Apollo.useMutation<AddPublicWodBlockMutation, AddPublicWodBlockMutationVariables>(AddPublicWodBlockDocument, baseOptions);
      }
export type AddPublicWodBlockMutationHookResult = ReturnType<typeof useAddPublicWodBlockMutation>;
export type AddPublicWodBlockMutationResult = Apollo.MutationResult<AddPublicWodBlockMutation>;
export type AddPublicWodBlockMutationOptions = Apollo.BaseMutationOptions<AddPublicWodBlockMutation, AddPublicWodBlockMutationVariables>;
export const UpdatePublicWodBlockDocument = gql`
    mutation updatePublicWodBlock($blockId: ID!, $content: String, $elements: [String], $image: Upload, $lang: AvailableLanguage!, $type: SessionExerciseBlockType!, $wodId: ID!) {
  updatePublicWodBlock(
    input: {blockId: $blockId, content: $content, elements: $elements, image: $image, lang: $lang, type: $type, wodId: $wodId}
  ) {
    id
    content
  }
}
    `;
export type UpdatePublicWodBlockMutationFn = Apollo.MutationFunction<UpdatePublicWodBlockMutation, UpdatePublicWodBlockMutationVariables>;

/**
 * __useUpdatePublicWodBlockMutation__
 *
 * To run a mutation, you first call `useUpdatePublicWodBlockMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePublicWodBlockMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePublicWodBlockMutation, { data, loading, error }] = useUpdatePublicWodBlockMutation({
 *   variables: {
 *      blockId: // value for 'blockId'
 *      content: // value for 'content'
 *      elements: // value for 'elements'
 *      image: // value for 'image'
 *      lang: // value for 'lang'
 *      type: // value for 'type'
 *      wodId: // value for 'wodId'
 *   },
 * });
 */
export function useUpdatePublicWodBlockMutation(baseOptions?: Apollo.MutationHookOptions<UpdatePublicWodBlockMutation, UpdatePublicWodBlockMutationVariables>) {
        return Apollo.useMutation<UpdatePublicWodBlockMutation, UpdatePublicWodBlockMutationVariables>(UpdatePublicWodBlockDocument, baseOptions);
      }
export type UpdatePublicWodBlockMutationHookResult = ReturnType<typeof useUpdatePublicWodBlockMutation>;
export type UpdatePublicWodBlockMutationResult = Apollo.MutationResult<UpdatePublicWodBlockMutation>;
export type UpdatePublicWodBlockMutationOptions = Apollo.BaseMutationOptions<UpdatePublicWodBlockMutation, UpdatePublicWodBlockMutationVariables>;
export const ListPublicWodCategoriesDocument = gql`
    query listPublicWodCategories($limit: Int, $offset: Int, $showUnpublished: Boolean) {
  listPublicWodCategories(
    input: {limit: $limit, offset: $offset, showUnpublished: $showUnpublished}
  ) {
    limit
    offset
    total
    result {
      id
      published
      translations(lang: en) {
        lang
        name
      }
    }
  }
}
    `;

/**
 * __useListPublicWodCategoriesQuery__
 *
 * To run a query within a React component, call `useListPublicWodCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useListPublicWodCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListPublicWodCategoriesQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      showUnpublished: // value for 'showUnpublished'
 *   },
 * });
 */
export function useListPublicWodCategoriesQuery(baseOptions?: Apollo.QueryHookOptions<ListPublicWodCategoriesQuery, ListPublicWodCategoriesQueryVariables>) {
        return Apollo.useQuery<ListPublicWodCategoriesQuery, ListPublicWodCategoriesQueryVariables>(ListPublicWodCategoriesDocument, baseOptions);
      }
export function useListPublicWodCategoriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListPublicWodCategoriesQuery, ListPublicWodCategoriesQueryVariables>) {
          return Apollo.useLazyQuery<ListPublicWodCategoriesQuery, ListPublicWodCategoriesQueryVariables>(ListPublicWodCategoriesDocument, baseOptions);
        }
export type ListPublicWodCategoriesQueryHookResult = ReturnType<typeof useListPublicWodCategoriesQuery>;
export type ListPublicWodCategoriesLazyQueryHookResult = ReturnType<typeof useListPublicWodCategoriesLazyQuery>;
export type ListPublicWodCategoriesQueryResult = Apollo.QueryResult<ListPublicWodCategoriesQuery, ListPublicWodCategoriesQueryVariables>;
export const CreatePublicWodCategoryDocument = gql`
    mutation createPublicWodCategory($name: PublicWodCategoryNameInput) {
  createPublicWodCategory(input: {name: $name}) {
    id
    published
    translations(lang: en) {
      lang
      name
    }
  }
}
    `;
export type CreatePublicWodCategoryMutationFn = Apollo.MutationFunction<CreatePublicWodCategoryMutation, CreatePublicWodCategoryMutationVariables>;

/**
 * __useCreatePublicWodCategoryMutation__
 *
 * To run a mutation, you first call `useCreatePublicWodCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePublicWodCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPublicWodCategoryMutation, { data, loading, error }] = useCreatePublicWodCategoryMutation({
 *   variables: {
 *      name: // value for 'name'
 *   },
 * });
 */
export function useCreatePublicWodCategoryMutation(baseOptions?: Apollo.MutationHookOptions<CreatePublicWodCategoryMutation, CreatePublicWodCategoryMutationVariables>) {
        return Apollo.useMutation<CreatePublicWodCategoryMutation, CreatePublicWodCategoryMutationVariables>(CreatePublicWodCategoryDocument, baseOptions);
      }
export type CreatePublicWodCategoryMutationHookResult = ReturnType<typeof useCreatePublicWodCategoryMutation>;
export type CreatePublicWodCategoryMutationResult = Apollo.MutationResult<CreatePublicWodCategoryMutation>;
export type CreatePublicWodCategoryMutationOptions = Apollo.BaseMutationOptions<CreatePublicWodCategoryMutation, CreatePublicWodCategoryMutationVariables>;
export const DeletePublicWodCategoryDocument = gql`
    mutation deletePublicWodCategory($id: ID!) {
  deletePublicWodCategory(input: {id: $id})
}
    `;
export type DeletePublicWodCategoryMutationFn = Apollo.MutationFunction<DeletePublicWodCategoryMutation, DeletePublicWodCategoryMutationVariables>;

/**
 * __useDeletePublicWodCategoryMutation__
 *
 * To run a mutation, you first call `useDeletePublicWodCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePublicWodCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePublicWodCategoryMutation, { data, loading, error }] = useDeletePublicWodCategoryMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeletePublicWodCategoryMutation(baseOptions?: Apollo.MutationHookOptions<DeletePublicWodCategoryMutation, DeletePublicWodCategoryMutationVariables>) {
        return Apollo.useMutation<DeletePublicWodCategoryMutation, DeletePublicWodCategoryMutationVariables>(DeletePublicWodCategoryDocument, baseOptions);
      }
export type DeletePublicWodCategoryMutationHookResult = ReturnType<typeof useDeletePublicWodCategoryMutation>;
export type DeletePublicWodCategoryMutationResult = Apollo.MutationResult<DeletePublicWodCategoryMutation>;
export type DeletePublicWodCategoryMutationOptions = Apollo.BaseMutationOptions<DeletePublicWodCategoryMutation, DeletePublicWodCategoryMutationVariables>;
export const UpdatePublicWodCategoryDocument = gql`
    mutation updatePublicWodCategory($id: ID!, $name: PublicWodCategoryNameInput, $published: Boolean) {
  updatePublicWodCategory(input: {id: $id, name: $name, published: $published}) {
    id
    published
    translations(lang: en) {
      lang
      name
    }
  }
}
    `;
export type UpdatePublicWodCategoryMutationFn = Apollo.MutationFunction<UpdatePublicWodCategoryMutation, UpdatePublicWodCategoryMutationVariables>;

/**
 * __useUpdatePublicWodCategoryMutation__
 *
 * To run a mutation, you first call `useUpdatePublicWodCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePublicWodCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePublicWodCategoryMutation, { data, loading, error }] = useUpdatePublicWodCategoryMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *      published: // value for 'published'
 *   },
 * });
 */
export function useUpdatePublicWodCategoryMutation(baseOptions?: Apollo.MutationHookOptions<UpdatePublicWodCategoryMutation, UpdatePublicWodCategoryMutationVariables>) {
        return Apollo.useMutation<UpdatePublicWodCategoryMutation, UpdatePublicWodCategoryMutationVariables>(UpdatePublicWodCategoryDocument, baseOptions);
      }
export type UpdatePublicWodCategoryMutationHookResult = ReturnType<typeof useUpdatePublicWodCategoryMutation>;
export type UpdatePublicWodCategoryMutationResult = Apollo.MutationResult<UpdatePublicWodCategoryMutation>;
export type UpdatePublicWodCategoryMutationOptions = Apollo.BaseMutationOptions<UpdatePublicWodCategoryMutation, UpdatePublicWodCategoryMutationVariables>;