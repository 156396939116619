import React, { useCallback } from 'react';
import loginCover from '../assets/img/login-cover.jpg';
import hustleWhite from '../assets/img/hustle_white.png';
import LoginForm from '../components/Auth/LoginForm';
import { useHistory } from 'react-router-dom';

export default function LoginPage() {
	const history = useHistory();

	const onLogin = useCallback(() => {
		history.go(0);
	}, [history]);

	return (
		<div className="flex h-screen">
			<div className="flex-1 hidden lg:block relative">
				<div className="absolute flex justify-center items-center h-full w-full p-10">
					<img src={hustleWhite} alt="Hustle Up logo" />
				</div>
				<img
					className="h-full object-cover login-cover"
					src={loginCover}
					alt="Login cover"
				/>
			</div>
			<div className="flex flex-1 items-center justify-center">
				<LoginForm onLogin={onLogin} />
			</div>
		</div>
	);
}
