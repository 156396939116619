import React from 'react';
import Layout from '../../layout';
import { Redirect, Route } from 'react-router-dom';

export default function AuthenticatedRoute({
	component: C,
	layout,
	appProps,
	children,
	...rest
}: any) {
	if (!appProps.isAuthenticated) {
		return <Redirect to={`/login`} />;
	}

	if (C) {
		return (
			<Route
				{...rest}
				render={(props) => (
					<Layout type={layout}>
						<C {...props} {...appProps} />
					</Layout>
				)}
			/>
		);
	}
	return <Route {...rest}>{children}</Route>;
}
