import {
	useDeletePublicWodCategoryMutation,
	ListPublicWodCategoriesDocument,
} from '../../generated/gql';
import React from 'react';
import update from 'immutability-helper';
import Modal from '../Main/Modal';

export const DeleteWodCategoryModal = ({
																				 setWodCategoryToDelete,
																				 wodCategoryToDelete,
														}: {
	setWodCategoryToDelete: any;
	wodCategoryToDelete: any;
}) => {
	const [deletePublicWodCategory, { error, loading }] = useDeletePublicWodCategoryMutation({
		update(cache, { data }) {

			const queryData: any = cache.readQuery({
				query: ListPublicWodCategoriesDocument,
				variables:{
					showUnpublished: true,
				}
			});

			if (queryData?.listPublicWodCategories) {
				const index = queryData?.listPublicWodCategories?.result.findIndex(
					(u: any) => u.id === data?.deletePublicWodCategory
				);

				console.log('index', index)
				if (index === -1) return;

				cache.writeQuery({
					query: ListPublicWodCategoriesDocument,
					variables:{
						showUnpublished: true,
					},
					data: {
						listPublicWodCategories: update(queryData.listPublicWodCategories, {
							result: {
								$splice: [[index, 1]],
							},
							total: {
								$apply: (x: number) => x - 1,
							},
						}),
					},
				});
			}
		},
	});

	if (!wodCategoryToDelete) return null;

	return (
		<Modal
			visible={!!wodCategoryToDelete}
			setVisible={() => {
				setWodCategoryToDelete(null);
			}}
			title="Delete Wod Category"
			onActionButton={() => {
				deletePublicWodCategory({
					variables: {
						id: wodCategoryToDelete?.id,
					},
				})
					.then(() => {
						setWodCategoryToDelete(null);
					})
					.catch((err) => {
						console.log(err);
					});
			}}
			actionName="Delete"
			actionColor="red"
			actionLoading={loading}
		>
			<div className="relative p-6 flex-auto">
				<p className="my-4 text-gray-600 text-lg leading-relaxed">
					Are you sure you want to delete this WOD category ?
				</p>
				{error && <p className="text-red-600">An error has occured :(</p>}
			</div>
		</Modal>
	);
};
