import React, { useState } from 'react';
import { useListReportedCommentsQuery } from '../../generated/gql';
import { useErrorModal } from '../../hooks/useErrorModal';
import Loader from '../Main/Loader';
import ReportedCommentsTable from './ReportedCommentsTable';

const ReportedComments = () => {
	const [showProcessed, setShowProcessed] = useState(false);
	const { error, data, loading, refetch } = useListReportedCommentsQuery({
		variables: {
			processed: showProcessed,
		},
	});
	useErrorModal({ error });

	if (loading) return <Loader />;

	return (
		<div>
			<p className="text-white text-right">
				Show processed{' '}
				<input
					type="checkbox"
					checked={showProcessed}
					onChange={() => {
						setShowProcessed(!showProcessed);
					}}
				/>
			</p>
			<ReportedCommentsTable
				loading={loading}
				refetch={refetch}
				limit={data?.listReportedComments?.limit!}
				offset={data?.listReportedComments?.offset!}
				total={data?.listReportedComments?.total!}
				comments={data?.listReportedComments?.result!}
			/>
		</div>
	);
};

export default ReportedComments;
