import React from 'react';
import Main from './Main';

export enum LayoutName {
	MAIN = 'main',
}

const layoutMap = {
	main: Main,
};

const Layout = ({ type, children }: { type: LayoutName; children?: any }) => {
	const Layout = layoutMap[type] || layoutMap.main;
	return <Layout>{children}</Layout>;
};

export default Layout;
