import { Auth } from 'aws-amplify';
import {
	from,
	ApolloClient,
	InMemoryCache,
	createHttpLink,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';

const httpLink = createHttpLink({ uri: process.env.REACT_APP_API_URL });

const onErrorLink = onError(({ graphQLErrors, networkError }) => {
	if (graphQLErrors)
		graphQLErrors.map(({ message, locations, path }) =>
			console.log(
				`[GraphQL error]: Message: ${message}, Location: ${JSON.stringify(
					locations
				)}, Path: ${path}`
			)
		);
	if (networkError) console.error(`[Network error]: ${networkError}`);
});
const setAuthorizationLink = setContext(async (_request, _previousContext) => {
	const user = await Auth.currentAuthenticatedUser();
	const token = user.getSignInUserSession().getAccessToken().getJwtToken();
	return {
		headers: {
			authorization: token ? `Bearer ${token}` : '',
			client_type: 'admin-dashboard',
			app_version: '1.0.0',
		},
	};
});

export const apolloCache = new InMemoryCache();

const initApolloClient = () => {
	return new ApolloClient({
		uri: process.env.REACT_APP_API_URL,
		cache: apolloCache,
		link: from([onErrorLink, setAuthorizationLink, httpLink]),
	});
};

export default initApolloClient;
