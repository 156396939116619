import {
	EvolutionRange,
	RessourceEvolutionAvailable,
	useShowTotalCreatedRessourcesEvolutionLazyQuery,
} from '../generated/gql';
import React, { useEffect, useMemo, useState } from 'react';
import * as Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import Loader from '../components/Main/Loader';
import moment from 'moment';
import DayWeekMonthYearSelector from '../components/Evolution/DayWeekMonthYearSelector';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';

const ressources = {
	profile: {
		name: 'Created profiles',
		key: RessourceEvolutionAvailable.CreatedProfiles,
	},
	program: {
		name: 'Created programs',
		key: RessourceEvolutionAvailable.CreatedPrograms,
	},
	session: {
		name: 'Created sessions',
		key: RessourceEvolutionAvailable.CreatedSessions,
	},
	message: {
		name: 'Sent messages',
		key: RessourceEvolutionAvailable.CreatedMessages,
	},
	wodbookentry: {
		name: 'Created WodBook entries',
		key: RessourceEvolutionAvailable.CreatedWodbookEntries,
	},
	personalrecord: {
		name: 'Created personal records',
		key: RessourceEvolutionAvailable.CreatedPersonalRecords,
	},
	score: {
		name: 'Added scores',
		key: RessourceEvolutionAvailable.CreatedScores,
	},
	feedback: {
		name: 'Added feedbacks',
		key: RessourceEvolutionAvailable.CreatedFeedbacks,
	},
	sessioncomments: {
		name: 'Added session comments',
		key: RessourceEvolutionAvailable.CreatedProgramSessionComments,
	},
};

export const CreatedRessourcesEvolution = () => {
	const { search } = useLocation();

	const ressource = useMemo(() => {
		const params = queryString.parse(search);
		const ressourceType = params?.ressource as keyof typeof ressources;

		return ressources[ressourceType];
	}, [search]);

	const [selected, setSelected] = useState<EvolutionRange>(EvolutionRange.Week);
	const [
		query,
		{ data, loading, error },
	] = useShowTotalCreatedRessourcesEvolutionLazyQuery();

	useEffect(() => {
		if (!ressource) return;
		query({
			variables: {
				range: selected,
				ressource: ressource.key,
			},
		});
	}, [selected, query, ressource]);

	const options = useMemo(() => {
		if (!data) return null;

		let currentDate = new Date();
		currentDate.setUTCHours(0, 0, 0, 0);

		let dateFormat = 'DD/MM/YYYY';
		let dates: Date[] = [];
		if (selected === EvolutionRange.Week) {
			for (let i = 0; i <= 7; i++) {
				let dateToAdd = new Date();
				dateToAdd.setUTCHours(0, 0, 0, 0);
				dateToAdd.setUTCDate(currentDate.getUTCDate() - i);
				dates.push(dateToAdd);
			}
		}

		if (selected === EvolutionRange.Month) {
			for (let i = 0; i <= 30; i++) {
				let dateToAdd = new Date();
				dateToAdd.setUTCHours(0, 0, 0, 0);
				dateToAdd.setUTCDate(currentDate.getUTCDate() - i);
				dates.push(dateToAdd);
			}
		}

		if (selected === EvolutionRange.Day) {
			for (let i = 0; i <= 24; i++) {
				let dateToAdd = new Date();
				dateToAdd.setUTCHours(dateToAdd.getUTCHours() - i, 0, 0, 0);
				dates.push(dateToAdd);
			}
			dateFormat = 'DD/MM hh:00';
		}

		if (selected === EvolutionRange.Year) {
			for (let i = 0; i <= 12; i++) {
				let dateToAdd = new Date();
				dateToAdd.setUTCHours(0, 0, 0, 0);
				dateToAdd.setUTCMonth(dateToAdd.getUTCMonth() - i);
				dates.push(dateToAdd);
			}
			dateFormat = 'MM/YY';
		}

		dates = dates.sort((a, b) => a.getTime() - b.getTime());

		let dateMap = dates.map((x) => {
			let _data;
			if (selected === EvolutionRange.Year) {
				_data = data.showTotalCreatedRessourcesEvolution?.counts?.find(
					(c: any) =>
						new Date(c.date).getUTCMonth() === x.getUTCMonth() &&
						new Date(c.date).getUTCFullYear() === x.getUTCFullYear()
				);
			} else {
				_data = data.showTotalCreatedRessourcesEvolution?.counts?.find(
					(c: any) => new Date(c.date).getTime() === x.getTime()
				);
			}

			return _data?.count || 0;
		});

		return ({
			title: '',
			subtitle: false,
			colors: [
				'#2b908f',
				'#90ee7e',
				'#f45b5b',
				'#7798BF',
				'#aaeeee',
				'#ff0066',
				'#eeaaee',
				'#55BF3B',
				'#DF5353',
				'#7798BF',
				'#aaeeee',
			],
			chart: {
				backgroundColor: 'transparent',
				style: {
					fontFamily: "'Unica One', sans-serif",
				},
				plotBorderColor: '#606063',
			},
			xAxis: {
				gridLineColor: '#707073',
				labels: {
					style: {
						color: '#E0E0E3',
					},
				},
				categories: dates.map((date) => moment(date).format(dateFormat)),
				lineColor: '#707073',
				minorGridLineColor: '#505053',
				tickColor: '#707073',
				title: {
					style: {
						color: '#A0A0A3',
					},
				},
			},
			yAxis: {
				gridLineColor: '#707073',
				labels: {
					style: {
						color: '#E0E0E3',
					},
				},
				lineColor: '#707073',
				minorGridLineColor: '#505053',
				tickColor: '#707073',
				tickWidth: 1,
				title: {
					style: {
						color: '#A0A0A3',
					},
				},
			},
			tooltip: {
				backgroundColor: 'rgba(0, 0, 0, 0.85)',
				style: {
					color: '#F0F0F0',
				},
			},
			plotOptions: {
				series: {
					dataLabels: {
						color: '#F0F0F3',
						style: {
							fontSize: '13px',
						},
					},
					marker: {
						lineColor: '#333',
					},
				},
				boxplot: {
					fillColor: '#505053',
				},
				candlestick: {
					lineColor: 'white',
				},
				errorbar: {
					color: 'white',
				},
			},
			legend: {
				backgroundColor: 'rgba(0, 0, 0, 0.5)',
				itemStyle: {
					color: '#E0E0E3',
				},
				itemHoverStyle: {
					color: '#FFF',
				},
				itemHiddenStyle: {
					color: '#606063',
				},
				title: {
					style: {
						color: '#C0C0C0',
					},
				},
			},
			credits: {
				style: {
					color: '#666',
				},
			},
			labels: {
				style: {
					color: '#707073',
				},
			},
			drilldown: {
				activeAxisLabelStyle: {
					color: '#F0F0F3',
				},
				activeDataLabelStyle: {
					color: '#F0F0F3',
				},
			},
			navigation: {
				buttonOptions: {
					symbolStroke: '#DDDDDD',
					theme: {
						fill: '#505053',
					},
				},
			},
			// scroll charts
			rangeSelector: {
				buttonTheme: {
					fill: '#505053',
					stroke: '#000000',
					style: {
						color: '#CCC',
					},
					states: {
						hover: {
							fill: '#707073',
							stroke: '#000000',
							style: {
								color: 'white',
							},
						},
						select: {
							fill: '#000003',
							stroke: '#000000',
							style: {
								color: 'white',
							},
						},
					},
				},
				inputBoxBorderColor: '#505053',
				inputStyle: {
					backgroundColor: '#333',
					color: 'silver',
				},
				labelStyle: {
					color: 'silver',
				},
			},
			navigator: {
				handles: {
					backgroundColor: '#666',
					borderColor: '#AAA',
				},
				outlineColor: '#CCC',
				maskFill: 'rgba(255,255,255,0.1)',
				series: {
					color: '#7798BF',
					lineColor: '#A6C7ED',
				},
				xAxis: {
					gridLineColor: '#505053',
				},
			},
			scrollbar: {
				barBackgroundColor: '#808083',
				barBorderColor: '#808083',
				buttonArrowColor: '#CCC',
				buttonBackgroundColor: '#606063',
				buttonBorderColor: '#606063',
				rifleColor: '#FFF',
				trackBackgroundColor: '#404043',
				trackBorderColor: '#404043',
			},
			series: [
				{
					type: 'line',
					name: ressource?.name,
					data: dateMap,
				},
			],
		} as unknown) as Highcharts.Options;
	}, [data, selected, ressource]);

	if ((loading && !error) || !options) return <Loader />;

	return (
		<div>
			<h1>{ressource?.name} evolution</h1>
			<DayWeekMonthYearSelector
				selected={selected}
				onSelection={(selected) => {
					setSelected(selected);
				}}
			/>
			<HighchartsReact highcharts={Highcharts} options={options} />
		</div>
	);
};
