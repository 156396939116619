import React, { useCallback, useState, useEffect } from 'react';
import { useForm } from "react-hook-form";
import TextInput from '../components/Form/TextInput';
import SelectCategory from '../components/Wodbook/SelectCategory';
import {
	AvailableLanguage,
	SessionExerciseBlockType,
	useAddPublicWodBlockMutation,
	useCreatePublicWodMutation, useShowPublicWodQuery, useUpdatePublicWodMutation, useUpdatePublicWodBlockMutation,
} from '../generated/gql';
import { useErrorModal } from '../hooks/useErrorModal';
import { useHistory } from 'react-router-dom';
import Loader from '../components/Main/Loader';
import { capitalizeName } from '../utils/string';

type FormValues = {
	title: string;
	body: string;
	category: string;
	calories: boolean;
	distance: boolean;
	time: boolean;
	weight: boolean;
	repetitions: boolean
};

export default function WodsPublicEdit(props: any) {
	const history = useHistory();
	const [refetchLoading, setRefetchLoading] = useState(false);
	const [blockId, setBlockId] = useState('');
	const { register, handleSubmit, errors, setValue } = useForm<FormValues>();
	const [updatePublicWod, { error: errorUpdatePublicWod }] = useUpdatePublicWodMutation();
	useErrorModal({ error: errorUpdatePublicWod });
	const { data, error, loading, refetch } = useShowPublicWodQuery({
		variables: {
			wodId: props?.match?.params?.id,
		},
	});

	const [updatePublicWodBlock, { error: errorUpdatePublicWodBlock }] = useUpdatePublicWodBlockMutation({});
	useErrorModal({ error: errorUpdatePublicWodBlock });

	useEffect(() => {
		if (data?.showPublicWod) {
			console.log('data?.showPublicWod', data?.showPublicWod)
			// @ts-ignore
			const title = capitalizeName(data?.showPublicWod?.exercise?.nameTranslations[0]?.name)
			setValue('title', title);

			// @ts-ignore
			const body = data?.showPublicWod?.exercise?.blockTranslations[1]?.blocks[0]?.content;
			setValue('body', body);
			// @ts-ignore
			setBlockId(data?.showPublicWod?.exercise?.blockTranslations[1]?.blocks[0]?.id)
			// @ts-ignore
			const category = data?.showPublicWod?.category?.id
			setValue('category', category);

			const calories = data?.showPublicWod?.exercise?.expectedScores?.findIndex((score) => score?.type === 'CALORIES');
			setValue('calories', calories !== -1);

			const distance = data?.showPublicWod?.exercise?.expectedScores?.findIndex((score) => score?.type === 'DISTANCE');
			setValue('distance', distance !== -1);

			const time = data?.showPublicWod?.exercise?.expectedScores?.findIndex((score) => score?.type === 'DURATION');
			setValue('time', time !== -1);

			const weight = data?.showPublicWod?.exercise?.expectedScores?.findIndex((score) => score?.type === 'LOAD');
			setValue('weight', weight !== -1);

			const repetitions = data?.showPublicWod?.exercise?.expectedScores?.findIndex((score) => score?.type === 'REPETITIONS');
			setValue('repetitions', repetitions !== -1);
		}
	}, [data, setValue]);

	const onRefetch = useCallback(() => {
		setRefetchLoading(true);
		refetch().finally(() => {
			setRefetchLoading(false);
		});
	}, [refetch]);

	useErrorModal({
		error,
		onRetry: onRefetch,
	});

	const computExpectedScore = useCallback((calories, distance, repetitions, time, weight) => {
		let ExpectedScore = [];
		if(calories) ExpectedScore.push({sort: 'DESC', type: 'CALORIES'})
		if(distance) ExpectedScore.push({sort: 'DESC', type: 'DISTANCE'})
		if(repetitions) ExpectedScore.push({sort: 'DESC', type: 'REPETITIONS'})
		if(time) ExpectedScore.push({sort: 'ASC', type: 'DURATION'})
		if(weight) ExpectedScore.push({sort: 'DESC', type: 'LOAD'})

		return ExpectedScore;
	}, []);

	const onSubmit =
		(data: { title: string, category: string, body: string, calories: boolean, distance: boolean, repetitions: boolean, time: boolean, weight: boolean}) => {

			const expectedScores = computExpectedScore(data.calories, data.distance, data.repetitions, data.time, data.weight);

			updatePublicWod({
				variables: {
					wodId: props?.match?.params?.id as string,
					expectedScores: expectedScores as [],
					categoryId: data?.category,
					name: {
						fr: data?.title,
						en: data?.title
					}
				}
			}).then(r => {
				updatePublicWodBlock({
					variables: {
						blockId: blockId,
						content: data?.body,
						lang: 'en' as AvailableLanguage,
						type: 'TEXT' as SessionExerciseBlockType,
						wodId: props?.match?.params?.id as string,
					}
				}).then(r => {
					console.log('updatePublicWod', r)
					history.push('/wodbook/' + props?.match?.params?.id);
				})
					.catch((err) => {
						console.log(err);
					});
			}).catch(e => console.error(e))
		}

	if (loading || refetchLoading) {
		return <Loader />;
	}

	if (!data) {
		return null;
	}

	return (
		<div className="h-full">
			<h1>Create public WOD</h1>
			<div className="">
				<form onSubmit={handleSubmit(onSubmit)} className="flex flex-col">
					<TextInput
						errors={errors}
						register={register}
						name="title"
						placeholder="Title"
						rules={{ required: 'Title is required', maxLength: 50 }}
						type="text"
					/>
					<label
						className="block text-gray-700 text-sm font-bold mb-2"
						htmlFor={'Body'}
					>
						Body
					</label>
					<textarea placeholder={'WOD content'}  ref={register({required: true, maxLength: 5000})} name='body' rows={10} className="px-3 py-3 placeholder-gray-400 text-gray-700 relative bg-white bg-white rounded text-sm shadow outline-none focus:outline-none focus:shadow-outline mb-3" />
					<label
						className="block text-gray-700 text-sm font-bold"
						htmlFor={'Expected Scores'}
					>
						Expected Scores
					</label>
					<div className="flex flex-row justify-between">
						<div>
							<label htmlFor="Calories" className="text-white m-2">Calories</label>
							<input
								name={'calories'}
								ref={register}
								type="checkbox"
							/>
						</div>
						<div>
							<label htmlFor="Distance" className="text-white m-2">Distance</label>
							<input
								name={'distance'}
								ref={register}
								type="checkbox"
							/>
						</div>
						<div>
							<label htmlFor="Time" className="text-white m-2">Time</label>
							<input
								name={'time'}
								ref={register}
								type="checkbox"
							/>
						</div>
						<div>
							<label htmlFor="Weight" className="text-white m-2">Weight</label>
							<input
								name={'weight'}
								ref={register}
								type="checkbox"
							/>
						</div>
						<div>
							<label htmlFor="Repetitions" className="text-white m-2">Repetitions</label>
							<input
								name={'repetitions'}
								ref={register}
								type="checkbox"
							/>
						</div>
					</div>
					<label
						className="block text-gray-700 text-sm font-bold mb-2 mt-4"
						htmlFor={'Category'}
					>
						Category
					</label>
					<SelectCategory register={register} val={data?.showPublicWod?.category?.id}/>
					<div className="flex items-center mt-6">
					<button
						className="text-white bg-primary-500 hover:bg-primary-700 font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
						type="submit"
					>
						Edit
					</button>
					</div>
				</form>
			</div>
		</div>
	);
}
