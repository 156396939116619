import React, { useEffect, useState } from 'react';
import { useListPublicWodCategoriesQuery } from '../../generated/gql';
import { useErrorModal } from '../../hooks/useErrorModal';
import Loader from '../Main/Loader';

export default function SelectCategory({
	register,
	onValueChange = null,
	val = ''
}: any) {
	const [firstime, setFirstime] = useState(true);
	const [value, setValue] = useState( val);
	const { data, error, loading, refetch } = useListPublicWodCategoriesQuery({
		nextFetchPolicy: "cache-and-network",
		variables: {
			showUnpublished: false,
		},
	});
	useErrorModal({
		error,
		onRetry: () => refetch(),
	});
	useEffect(() => {
		// @ts-ignore
		if(data?.listPublicWodCategories?.result[0]?.id && onValueChange && firstime){
			console.log('icicicici')
			setFirstime(false);
			onValueChange(data?.listPublicWodCategories?.result[0]?.id)
			// @ts-ignore
			setValue(data?.listPublicWodCategories?.result[0]?.translations[0]?.name)
		}
		// @ts-ignore
	}, [data?.listPublicWodCategories?.result[0]?.id])


	if (loading) {
		return <Loader />;
	}

	if (error) {
		return null;
	}

	return (
		<select name="category" ref={register} value={value} className="p-3 mb-3 relative bg-white bg-white rounded text-sm shadow outline-none focus:outline-none focus:shadow-outline" onChange={e => {
			let {value} = e.target;
			console.log('value', value, onValueChange)
			// @ts-ignore
			setValue(value)
			if(onValueChange)
				onValueChange(value)
		}
		}>
			{
				data?.listPublicWodCategories?.result?.map((category, key): any => {
					return(
						// @ts-ignore
							<option value={category?.id} key={key}>{category?.translations[0]?.name}</option>
						)
				})
			}
		</select>
	);
}
