import React, { Dispatch, SetStateAction, useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
	PublicWod,
	useShowPublicWodQuery,
} from '../generated/gql';
import { useErrorModal } from '../hooks/useErrorModal';
import Loader from '../components/Main/Loader';
import { capitalizeName } from '../utils/string';
import update from 'immutability-helper';
import { DeletePublicWodModal } from '../components/Wodbook/DeletePublicWodModal';

export default function WodBookShow(props: any) {
	const history = useHistory();
	const [wodToDelete, setWodToDelete] = useState<PublicWod | null>(null);
	const [refetchLoading, setRefetchLoading] = useState(false);
	const { data, error, loading, refetch } = useShowPublicWodQuery({
		variables: {
			wodId: props?.match?.params?.id,
		},
	});

	const onRefetch = useCallback(() => {
		setRefetchLoading(true);
		refetch().finally(() => {
			setRefetchLoading(false);
		});
	}, [refetch]);

	useErrorModal({
		error,
		onRetry: onRefetch,
	});

	if (loading || refetchLoading) {
		return <Loader />;
	}

	if (!data) {
		return null;
	}

	return (
		<div className="xl:ml-32 md:ml-20">
			<DeletePublicWodModal
				setWodToDelete={setWodToDelete}
				wodToDelete={wodToDelete}
				wodName={
					// @ts-ignore
					data?.showPublicWod?.exercise?.nameTranslations[0]?.name
				}
				categoryId={data?.showPublicWod?.category?.id}
			/>
			<h2 className="text-white text-2xl">
				Public Wod
			</h2>
			<h1 className="text-white text-3xl font-bold">
				{
					// @ts-ignore
					capitalizeName(data?.showPublicWod?.exercise?.nameTranslations[0]?.name)
				}
			</h1>
			<div className="text-white text-xl">
				<p className="font-bold">Category:</p>
				<p>
					{
						// @ts-ignore
						' ' + capitalizeName(data?.showPublicWod?.category?.translations[0].name)
					}
				</p>
			</div>
			<div className="text-white text-xl my-5">
				<p className="font-bold">Block: </p>
				{
					// @ts-ignore
					data?.showPublicWod?.exercise?.blockTranslations[1]?.blocks[0]?.content.split('\n').map((item, key) => {
					return <p key={key}>{item}<br/></p>
				})}
			</div>
			<div className="text-white text-xl my-5">
				<p className="font-bold">Expected Scores: </p>
				<div className="flex flex-row">{
					// @ts-ignore
					data?.showPublicWod?.exercise?.expectedScores?.length > 0 ?
						data?.showPublicWod?.exercise?.expectedScores?.map((score, key) => {
							return(
								<p key={key} className="mr-5">{(score?.type)?.toLowerCase()}</p>
							)
						})
						:
						<p>no scores expected</p>
				}</div>
			</div>
			<div>
				<button
					className="text-red-500 outline-none focus:outline-none"
					onClick={() => {
							setWodToDelete(data?.showPublicWod!)
					}}
				>
					Delete Wod
				</button>
				<button
					className="ml-3 text-blue-500 outline-none focus:outline-none"
					onClick={() => {
								history.push('/wodbook/edit/' + props?.match?.params?.id)
					}}
				>
					Update Wod
				</button>
			</div>
		</div>
	);
}
