import { gql } from "@apollo/client";

export const ME = gql`
  query me {
    me {
      firstName
      lastName
      role
    }
  }
`;

export const LIST_OWNED_USERS = gql`
  query listOwnedUsers($filter: String, $limit: Int, $offset: Int) {
    listOwnedUsers(
      input: {
        limit: $limit
        offset: $offset
        filter: { nameOrNicknameOrEmail: { contains: $filter } }
      }
    ) {
      limit
      offset
      total
      result {
        id
        firstName
        lastName
        nickname
        email
        email_verified
      }
    }
  }
`;

export const SHOW_OWNED_USER = gql`
  query showOwnedUser($id: ID!) {
    showOwnedUser(input: { id: $id }) {
      id
      firstName
      lastName
      nickname
      email
      email_verified
      preferences {
        lang
      }
      birthDate {
        private
        value
      }
      followedPrograms {
        id
        name
      }
      ownedPrograms {
        id
        name
      }
      gender
      provider
      pubnub_uuid
      role
    }
  }
`;
