import {
	ListPublicWodCategoriesDocument, useUpdatePublicWodCategoryMutation,
} from '../../generated/gql';
import React, { useState } from 'react';
import update from 'immutability-helper';
import Modal from '../Main/Modal';
import { capitalizeName } from '../../utils/string';

export const EditWodCategoryModal = ({
															setWodCategoryToEdit,
															wodCategoryToEdit,
														}: {
	setWodCategoryToEdit: any;
	wodCategoryToEdit: any;
}) => {
	const [inputValue, setInputValue] = useState('');
	const [updatePublicWodCategory, { error, loading }] = useUpdatePublicWodCategoryMutation();

	if (!wodCategoryToEdit) return null;

	return (
		<Modal
			visible={!!wodCategoryToEdit}
			setVisible={() => {
				setWodCategoryToEdit(null);
			}}
			title="Edit Wod Category"
			onActionButton={() => {
				updatePublicWodCategory({
					variables: {
						id: wodCategoryToEdit?.id,
						name: {
							en: inputValue,
							fr: inputValue
						},
					},
				})
					.then(() => {
						setWodCategoryToEdit(null);
					})
					.catch((err) => {
						console.log(err);
					});
			}}
			actionName="Edit"
			actionColor="orange"
			actionDisabled={inputValue === ''}
			actionLoading={loading}
		>
			<div className="relative p-6 flex-auto">
				<p className="my-4 text-gray-600 text-lg leading-relaxed">
					What is the new name for category {capitalizeName(wodCategoryToEdit?.name)} ?
				</p>
				<input
					value={inputValue}
					onChange={(e) => {
						setInputValue(e.target.value);
					}}
					type="text"
					placeholder="Category name"
					className="px-3 py-3 placeholder-gray-400 text-gray-700 relative bg-white bg-white rounded text-sm shadow outline-none focus:outline-none focus:shadow-outline w-full bg-gray-200"
				/>
				{error && <p className="text-red-600">An error has occured :(</p>}
			</div>
		</Modal>
	);
};
