import React from 'react';
import TotalStatsCard from './TotalStatsCard';
import { useShowTotalStatsQuery } from '../../generated/gql';
import Loader from '../Main/Loader';
import { useErrorModal } from '../../hooks/useErrorModal';
import { useHistory } from 'react-router-dom';

const TotalStats = () => {
	const history = useHistory();
	const { error, loading, data, refetch } = useShowTotalStatsQuery();
	useErrorModal({
		error,
		onRetry: () => refetch(),
	});

	if (loading) {
		return <Loader />;
	}

	return (
		<div>
			<h2>Profile</h2>
			<div className="mt-3 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-6 gap-5">
				<TotalStatsCard
					name="Created profiles"
					number={data?.showTotalStats?.createdProfiles!}
					onClick={() => {
						history.push('/stats/evolution?ressource=profile');
					}}
				/>
				<TotalStatsCard
					name="Profiles with verified email"
					number={data?.showTotalStats?.emailVerifiedProfiles!}
				/>
				<TotalStatsCard
					name="Profiles with picture"
					number={data?.showTotalStats?.profilesWithPicture!}
				/>
				<TotalStatsCard
					name="Profiles with personal data"
					number={data?.showTotalStats?.profilesWithPersonalData!}
				/>
			</div>
			<h2>Chat</h2>
			<div className="mt-3 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-6 gap-5">
				<TotalStatsCard
					name="Private conversations"
					number={data?.showTotalStats?.chatChannels?.duo!}
				/>
				<TotalStatsCard
					name="Program conversations"
					number={data?.showTotalStats?.chatChannels?.program!}
				/>
				<TotalStatsCard
					name="Messages sent"
					number={data?.showTotalStats?.chatMessages?.total!}
					onClick={() => {
						history.push('/stats/evolution?ressource=message');
					}}
				/>
				<TotalStatsCard
					name="Textual messages"
					number={data?.showTotalStats?.chatMessages?.text!}
				/>
				<TotalStatsCard
					name="Images sent"
					number={data?.showTotalStats?.chatMessages?.image!}
				/>
			</div>
			<h2>Programs</h2>
			<div className="mt-3 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-6 gap-5">
				<TotalStatsCard
					name="Created programs"
					number={data?.showTotalStats?.createdPrograms!}
					onClick={() => {
						history.push('/stats/evolution?ressource=program');
					}}
				/>
				<TotalStatsCard
					name="Public published programs"
					number={data?.showTotalStats?.publishedPrograms?.public!}
				/>
				<TotalStatsCard
					name="Private published programs"
					number={data?.showTotalStats?.publishedPrograms?.private!}
				/>
				<TotalStatsCard
					name="Not published programs"
					number={data?.showTotalStats?.unPublishedPrograms!}
				/>
				<TotalStatsCard
					name="Added scores"
					number={data?.showTotalStats?.addedScores!}
					onClick={() => {
						history.push('/stats/evolution?ressource=score');
					}}
				/>
				<TotalStatsCard
					name="Added feedbacks"
					number={data?.showTotalStats?.addedFeedbacks!}
					onClick={() => {
						history.push('/stats/evolution?ressource=feedback');
					}}
				/>
				<TotalStatsCard
					name="Created program sessions"
					number={data?.showTotalStats?.createdSessions!}
					onClick={() => {
						history.push('/stats/evolution?ressource=session');
					}}
				/>
				<TotalStatsCard
					name="Added program session comments"
					number={data?.showTotalStats?.programSessionComments!}
					onClick={() => {
						history.push('/stats/evolution?ressource=sessioncomments');
					}}
				/>
				<TotalStatsCard
					name="Chrono configurations added"
					number={data?.showTotalStats?.chronoConfigurations!}
				/>
				<TotalStatsCard
					name="Users following one program"
					number={data?.showTotalStats?.usersProgramFollowing?.one!}
				/>
				<TotalStatsCard
					name="Users following two programs"
					number={data?.showTotalStats?.usersProgramFollowing?.two!}
				/>
				<TotalStatsCard
					name="Users following three programs"
					number={data?.showTotalStats?.usersProgramFollowing?.three!}
				/>
				<TotalStatsCard
					name="Users following four programs"
					number={data?.showTotalStats?.usersProgramFollowing?.four!}
				/>
				<TotalStatsCard
					name="Users following five programs and more"
					number={data?.showTotalStats?.usersProgramFollowing?.fiveAndMore!}
				/>
			</div>
			<h2>Personal records</h2>
			<div className="mt-3 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-6 gap-5">
				<TotalStatsCard
					name="Users with at least one personal record"
					number={data?.showTotalStats?.usersWithAtLeastOnePersonalRecord!}
				/>
				<TotalStatsCard
					name="Created personal records"
					number={data?.showTotalStats?.createdPersonalRecords!}
					onClick={() => {
						history.push('/stats/evolution?ressource=personalrecord');
					}}
				/>
			</div>
			<h2>WodBook</h2>
			<div className="mt-3 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-6 gap-5">
				<TotalStatsCard
					name="Users with at least one WodBook entry"
					number={data?.showTotalStats?.usersWithAtLeastOneWodBookEntry!}
				/>
				<TotalStatsCard
					name="Created WodBook entries"
					number={data?.showTotalStats?.createdWodBookEntries!}
					onClick={() => {
						history.push('/stats/evolution?ressource=wodbookentry');
					}}
				/>
				<TotalStatsCard
					name="WodBook entries per user"
					number={
						Math.round(
							data?.showTotalStats?.averageWodBookEntriesPerUser! * 100
						) / 100
					}
				/>
			</div>
		</div>
	);
};

export default TotalStats;
