import React from 'react';

export default function TextInput({
	name,
	errors,
	register,
	placeholder,
	rules,
	type,
}: any) {
	function getInputClasses(name: string) {
		let inputClasses =
			'shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline bg-gray-200';
		if (errors[name]) {
			inputClasses += ' border-red-500';
		}
		return inputClasses;
	}

	return (
		<div className="mb-4">
			<label
				className="block text-gray-700 text-sm font-bold mb-2"
				htmlFor={name}
			>
				{placeholder}
			</label>
			<input
				id={name}
				name={name}
				ref={register(rules)}
				className={getInputClasses(name)}
				type={type}
				placeholder={placeholder}
			/>
			{errors[name] && (
				<p className="text-red-500 text-xs italic">{errors[name].message}</p>
			)}
		</div>
	);
}
