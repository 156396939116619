export interface Error {
	title: string;
	body: string;
	onRetry?: () => void;
	onClose?: () => void;
}

export interface SystemState {
	authenticated: boolean;
	error: Error | null;
	sidebarToggled: boolean;
}

export const SYSTEM_STORE_ACTIONS = {
	SET_AUTHENTICATED: 'SET_AUTHENTICATED',
	SET_ERROR_MODAL: 'SET_ERROR_MODAL',
	TOGGLE_SIDEBAR: 'TOGGLE_SIDEBAR',
};

const initialState = {
	authenticated: false,
	error: null,
	sidebarToggled: false,
};

export default function SystemReducer(state = initialState, action: any) {
	switch (action.type) {
		case SYSTEM_STORE_ACTIONS.SET_AUTHENTICATED:
			return Object.assign({}, state, {
				authenticated: action.value,
			});
		case SYSTEM_STORE_ACTIONS.SET_ERROR_MODAL:
			return Object.assign({}, state, {
				error: action.value,
			});
		case SYSTEM_STORE_ACTIONS.TOGGLE_SIDEBAR:
			return Object.assign({}, state, {
				sidebarToggled: !state.sidebarToggled,
			});
		default:
			return state;
	}
}
