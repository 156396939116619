import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { NotFound } from '../pages/NotFound';
import UnauthenticatedRoute from './route/UnauthenticatedRoute';
import AuthenticatedRoute from './route/AuthenticatedRoute';
import LoginPage from '../pages/Login';
import User from '../pages/User';
import Users from '../pages/Users';
import WodsCategories from '../pages/WodsCategories';
import WodsPublicCreate from '../pages/WodsPublicCreate';
import WodBook from '../pages/WodBook';
import Stats from '../pages/Stats';
import { CreatedRessourcesEvolution } from '../pages/CreatedRessourcesEvolution';
import Moderation from '../pages/Moderation';
import ReportedChatMessage from '../pages/ReportedChatMessage';
import Home from '../pages/Home';
import WodBookShow from '../pages/WodBookShow';
import WodsPublicEdit from '../pages/WodsPublicEdit';


export const Router = ({ isAuthenticated }: { isAuthenticated: boolean }) => {
	return (
			<Switch>
				<UnauthenticatedRoute
					exact
					path="/login"
					component={LoginPage}
					appProps={{ isAuthenticated }}
				/>
				<AuthenticatedRoute
					exact
					path="/users/:id"
					appProps={{ isAuthenticated }}
					component={User}
				/>
				<AuthenticatedRoute
					exact
					path="/users"
					appProps={{ isAuthenticated }}
					component={Users}
				/>
				<AuthenticatedRoute
					exact
					path="/wodbook/categories"
					appProps={{ isAuthenticated }}
					component={WodsCategories}
				/>
				<AuthenticatedRoute
					exact
					path="/wodbook/create"
					appProps={{ isAuthenticated }}
					component={WodsPublicCreate}
				/>
				<AuthenticatedRoute
					exact
					path="/wodbook"
					appProps={{ isAuthenticated }}
					component={WodBook}
				/>
				<AuthenticatedRoute
					exact
					path="/wodbook/:id"
					appProps={{ isAuthenticated }}
					component={WodBookShow}
				/>
				<AuthenticatedRoute
					exact
					path="/wodbook/edit/:id"
					appProps={{ isAuthenticated }}
					component={WodsPublicEdit}
				/>
				<AuthenticatedRoute
					exact
					path="/stats"
					appProps={{ isAuthenticated }}
					component={Stats}
				/>
				<AuthenticatedRoute
					exact
					path="/stats/evolution"
					appProps={{ isAuthenticated }}
					component={CreatedRessourcesEvolution}
				/>
				<AuthenticatedRoute
					exact
					path="/moderation"
					appProps={{ isAuthenticated }}
					component={Moderation}
				/>
				<AuthenticatedRoute
					exact
					path="/moderation/message/:id"
					appProps={{ isAuthenticated }}
					component={ReportedChatMessage}
				/>
				<AuthenticatedRoute
					exact
					path="/"
					appProps={{ isAuthenticated }}
					component={Home}
				/>
				{/* List a generic 404-Not Found route here */}
				<Route path="*">
					<NotFound />
				</Route>
			</Switch>
	);
};
