import React from 'react';

const Card = ({
	children,
	active,
	onClick,
	setIsShown,
}: {
	children: any;
	active?: boolean;
	onClick?: () => void;
	setIsShown?: (shown: boolean) => void;
}) => {
	return (
		<div
			className={`group flex flex-col justify-between bg-dark-800 rounded p-5 ${
				(onClick || active) &&
				'border-2 border-gray-800 hover:border-indigo-600 cursor-pointer'
			}`}
			onClick={onClick}
			onMouseEnter={() => setIsShown && setIsShown(true)}
			onMouseLeave={() => setIsShown && setIsShown(false)}
		>
			{children}
		</div>
	);
};

export default Card;
