import { useUpdatePublicWodCategoryMutation, } from '../../generated/gql';
import React from 'react';
import Modal from '../Main/Modal';
import { capitalizeName } from '../../utils/string';

export const PublishWodCategoryModal = ({
																					setWodCategoryToPublish,
																					wodCategoryToPublish,
																		 }: {
	setWodCategoryToPublish: any;
	wodCategoryToPublish: any;
}) => {
	const [updatePublicWodCategory, { error, loading }] = useUpdatePublicWodCategoryMutation();

	if (!wodCategoryToPublish) return null;

	console.log('wodCategoryToPublish', wodCategoryToPublish)

	return (
		<Modal
			visible={!!wodCategoryToPublish}
			setVisible={() => {
				setWodCategoryToPublish(null);
			}}
			title={wodCategoryToPublish?.published ? "Unublish Wod Category" : "Publish Wod Category"}
			onActionButton={() => {
				updatePublicWodCategory({
					variables: {
						id: wodCategoryToPublish?.id,
						published: !wodCategoryToPublish?.published
					},
				})
					.then(() => {
						setWodCategoryToPublish(null);
					})
					.catch((err) => {
						console.log(err);
					});
			}}
			actionName={wodCategoryToPublish?.published ? "Unpublish" : "Publish"}
			actionColor={wodCategoryToPublish?.published ? "red" : "green"}
			actionLoading={loading}
		>
			<div className="relative p-6 flex-auto">
				<p className="my-4 text-gray-600 text-lg leading-relaxed">
					Do you want to {wodCategoryToPublish?.published ? 'unpublish' : 'publish'} "{capitalizeName(wodCategoryToPublish?.name)}"?
				</p>
				{error && <p className="text-red-600">An error has occured :(</p>}
			</div>
		</Modal>
	);
};
