import React, { useState } from 'react';
import Card from '../Main/Card';
import { BarChart } from 'react-feather';

const TotalStatsCard = ({
	name,
	number,
	onClick,
}: {
	name: string;
	number: number;
	onClick?: () => void;
}) => {
	const [isShown, setIsShown] = useState(false);

	return (
		<Card onClick={onClick} setIsShown={setIsShown}>
			<h2 className="text-2xl">{name}</h2>
			<div className="flex justify-between items-baseline">
				<span className="text-6xl">{number}</span>
				{onClick && <BarChart color={isShown ? '#5a67d8' : '#fff'} size={35} />}
			</div>
		</Card>
	);
};

export default TotalStatsCard;
