import React from 'react';
import TotalStats from '../components/TotalStats/TotalStats';

const Stats = () => {
	return (
		<div>
			<h1>Stats</h1>
			<TotalStats />
		</div>
	);
};

export default Stats;
