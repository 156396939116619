import React, { useMemo } from 'react';
import { BarChart2, Home, Users, Flag, Bookmark, Book } from 'react-feather';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { toggleSidebar } from '../../store/actions';
import { SystemState } from '../../store/SystemReducer';
import { Role, useMeQuery } from '../../generated/gql';
import Loader from '../Main/Loader';
import { useErrorModal } from '../../hooks/useErrorModal';

const SideBar = () => {
	const history = useHistory();
	const location = useLocation();
	const sidebarToggled = useSelector(
		(state: SystemState) => state.sidebarToggled
	);
	const dispatch = useDispatch();
	const { data, error, loading, refetch } = useMeQuery();
	useErrorModal({ error, onRetry: () => refetch() });

	const items = useMemo(() => {
		if (data?.me?.role === Role.BusinessUser) {
			return [
				{
					icon: Home,
					name: 'Home',
					to: '/',
				},
				{
					icon: BarChart2,
					name: 'Stats',
					to: '/stats',
				},
				{
					icon: Book,
					name: 'WodBook',
					to: '/wodBook',
				},
				{
					icon: Bookmark,
					name: 'Categories',
					to: '/wodBook/categories',
				},
			];
		}
		if (data?.me?.role === Role.Admin) {
			return [
				{
					icon: Home,
					name: 'Home',
					to: '/',
				},
				{
					icon: Users,
					name: 'Users',
					to: '/users',
				},
				{
					icon: BarChart2,
					name: 'Stats',
					to: '/stats',
				},
				{
					icon: Flag,
					name: 'Moderation',
					to: '/moderation',
				},
				{
					icon: Book,
					name: 'WodBook',
					to: '/wodBook',
				},
				{
					icon: Bookmark,
					name: 'Categories',
					to: '/wodBook/categories',
				},
			];
		}
		return [];
	}, [data]);

	if (loading) {
		return <Loader />;
	}

	return (
		<div
			className={`md:flex flex-col min-h-screen z-10 ${
				sidebarToggled ? 'flex' : 'hidden'
			}`}
		>
			<div className="flex flex-col h-full w-full md:w-48 text-gray-300 bg-dark-400 flex-shrink-0">
				<nav className="block flex-grow md:block px-4 pb-4 md:pb-0 md:overflow-y-auto">
					{items.map((item, index) => (
						<a
							key={index}
							href={item.to}
							onClick={(e) => {
								e.preventDefault();
								if (sidebarToggled) {
									dispatch(toggleSidebar());
								}
								history.push(item.to);
							}}
							className={`group flex items-center px-4 py-2 mt-2 text-sm font-semibold bg-transparent rounded-lg focus:outline-none focus:shadow-none hover:text-primary-400 focus:text-primary-400`}
						>
							<span
								className={`mr-5 group-hover:text-primary-400 focus:text-primary-400 ${
									location.pathname === item.to && 'text-primary-400'
								}`}
							>
								{<item.icon />}
							</span>
							<span
								className={`group-hover:text-primary-400 focus:text-primary-400 ${
									location.pathname === item.to && 'text-primary-400'
								}`}
							>
								{item.name}
							</span>
						</a>
					))}
				</nav>
			</div>
		</div>
	);
};

export default SideBar;
