import Card from '../Main/Card';
import React from 'react';
import { TrendingUp, TrendingDown, Minus } from 'react-feather';

const DailyStat = ({
	title,
	number,
	active,
	previousNumber,
}: {
	title: string;
	number: number;
	active?: boolean;
	previousNumber: number;
}) => {
	const icon =
		number === previousNumber
			? {
					name: Minus,
					color: '#fff',
			  }
			: number > previousNumber
			? { name: TrendingUp, color: '#56f885' }
			: {
					name: TrendingDown,
					color: '#ff5c2a',
			  };
	const Trending = icon.name;

	return (
		<Card active={active}>
			<h2 className="text-2xl">{title}</h2>
			<div className="flex items-center">
				<span className="text-6xl">{number}</span>
				<Trending size={35} color={icon.color} className="ml-5" />
			</div>
		</Card>
	);
};

export default DailyStat;
