import React, { Dispatch, SetStateAction, useCallback, useState } from 'react';
import Loader from '../components/Main/Loader';
import { capitalizeName } from '../utils/string';
import Modal from '../components/Main/Modal';
import { useErrorModal } from '../hooks/useErrorModal';
import update from 'immutability-helper';
import { useHistory } from 'react-router-dom';
import {
	ListOwnedUsersDocument,
	OwnedUser,
	ShowOwnedUserQuery,
	useDeleteOwnedUserMutation,
	useShowOwnedUserQuery,
	useUpdateOwnedUserEmailMutation,
} from '../generated/gql';
import { useForm } from 'react-hook-form';

const User = (props: any) => {
	const [userToDelete, setUserToDelete] = useState<OwnedUser | null>(null);
	const [userToUpdate, setUserToUpdate] = useState<OwnedUser | null>(null);
	const [refetchLoading, setRefetchLoading] = useState(false);
	const { data, error, loading, refetch } = useShowOwnedUserQuery({
		variables: {
			id: props?.match?.params?.id,
		},
	});

	const onRefetch = useCallback(() => {
		setRefetchLoading(true);
		refetch().finally(() => {
			setRefetchLoading(false);
		});
	}, [refetch]);

	useErrorModal({
		error,
		onRetry: onRefetch,
	});

	if (loading || refetchLoading) {
		return <Loader />;
	}

	if (!data) {
		return null;
	}

	return (
		<div className="xl:ml-32 md:ml-20">
			<DeleteAccountModal
				setUserToDelete={setUserToDelete}
				userToDelete={userToDelete}
			/>
			<UpdateEmailModal
				setUserToUpdate={setUserToUpdate}
				userToUpdate={userToUpdate}
			/>
			<h2 className="text-white text-2xl">
				{'@' + capitalizeName(data?.showOwnedUser?.nickname!)}
			</h2>
			<h1 className="text-white text-3xl font-bold">
				{capitalizeName(data?.showOwnedUser?.firstName!)}{' '}
				{capitalizeName(data?.showOwnedUser?.lastName!)}
			</h1>
			<p className="text-white text-xl">ID : {data?.showOwnedUser?.id}</p>
			<div className="text-white text-xl my-5">
				<p>Email : {data?.showOwnedUser?.email}</p>
				<p>
					Email verified : {data?.showOwnedUser?.email_verified ? '✔' : '❌'}
				</p>
				<p>Lang : {data?.showOwnedUser?.preferences?.lang}</p>
				<p>Provider : {data?.showOwnedUser?.provider}</p>
				<p>Pubnub UUID : {data?.showOwnedUser?.pubnub_uuid}</p>
			</div>
			<div className="text-white text-xl my-5">
				{data?.showOwnedUser?.followedPrograms!?.length > 0 && (
					<h2 className="text-xl font-bold">Followed programs :</h2>
				)}
				{data?.showOwnedUser?.followedPrograms?.map((program: any) => (
					<p key={program.id}>{program.name}</p>
				))}
			</div>
			<div className="text-white text-xl my-5">
				{data?.showOwnedUser?.ownedPrograms!?.length > 0 && (
					<h2 className="text-xl font-bold">Created programs :</h2>
				)}
				{data?.showOwnedUser?.ownedPrograms?.map((program: any) => (
					<p key={program.id}>{program.name}</p>
				))}
			</div>
			<div>
				<button
					className="text-red-500 outline-none focus:outline-none"
					onClick={() => {
						setUserToDelete(data?.showOwnedUser!);
					}}
				>
					Delete account
				</button>
				<button
					className="ml-3 text-blue-500 outline-none focus:outline-none"
					onClick={() => {
						setUserToUpdate(data?.showOwnedUser!);
					}}
				>
					Update user email
				</button>
			</div>
		</div>
	);
};

const DeleteAccountModal = ({
	setUserToDelete,
	userToDelete,
}: {
	userToDelete: ShowOwnedUserQuery['showOwnedUser'];
	setUserToDelete: Dispatch<SetStateAction<OwnedUser | null>>;
}) => {
	const [inputValue, setInputValue] = useState('');
	const [deleteOwnedUser, { error, loading }] = useDeleteOwnedUserMutation({
		update(cache, { data }) {
			const queryData: any = cache.readQuery({
				query: ListOwnedUsersDocument,
			});

			if (queryData?.listOwnedUsers) {
				const index = queryData?.listOwnedUsers?.result.findIndex(
					(u: any) => u.id === data?.deleteOwnedUser
				);

				if (index === -1) return;

				cache.writeQuery({
					query: ListOwnedUsersDocument,
					data: {
						listOwnedUsers: update(queryData.listOwnedUsers, {
							result: {
								$splice: [[index, 1]],
							},
							total: {
								$apply: (x: number) => x - 1,
							},
						}),
					},
				});
			}
		},
	});

	const history = useHistory();

	if (!userToDelete) return null;

	return (
		<Modal
			visible={!!userToDelete}
			setVisible={() => {
				setUserToDelete(null);
			}}
			title="Delete user account"
			onActionButton={() => {
				deleteOwnedUser({
					variables: {
						id: userToDelete?.id,
					},
				})
					.then(() => {
						setUserToDelete(null);
						history.push('/users');
					})
					.catch((err) => {
						console.log(err);
					});
			}}
			actionName="Delete"
			actionColor="red"
			actionDisabled={inputValue !== `@${userToDelete?.nickname}`}
			actionLoading={loading}
		>
			<div className="relative p-6 flex-auto">
				<p className="my-4 text-gray-600 text-lg leading-relaxed">
					Are you sure you want to delete this account ?
				</p>
				<p className="my-4 text-gray-600 text-lg leading-relaxed">
					Please, type this nickname :{' '}
					<span className="font-semibold text-gray-700">
						@{userToDelete?.nickname}
					</span>
				</p>
				<input
					value={inputValue}
					onChange={(e) => {
						setInputValue(e.target.value);
					}}
					type="text"
					placeholder="Nickname"
					className="px-3 py-3 placeholder-gray-400 text-gray-700 relative bg-white bg-white rounded text-sm shadow outline-none focus:outline-none focus:shadow-outline w-full bg-gray-200"
				/>
				{error && <p className="text-red-600">An error has occured :(</p>}
			</div>
		</Modal>
	);
};

const UpdateEmailModal = ({
	setUserToUpdate,
	userToUpdate,
}: {
	userToUpdate: ShowOwnedUserQuery['showOwnedUser'];
	setUserToUpdate: Dispatch<SetStateAction<OwnedUser | null>>;
}) => {
	const { register, errors, handleSubmit } = useForm();
	const [
		updateOwnedUserEmail,
		{ error, loading },
	] = useUpdateOwnedUserEmailMutation();

	const update = useCallback(
		(data) => {
			updateOwnedUserEmail({
				variables: {
					userId: userToUpdate?.id!,
					newEmail: data.email,
				},
			})
				.then(() => {
					setUserToUpdate(null);
				})
				.catch((e) => {
					console.error('An error has occured', e);
				});
		},
		[setUserToUpdate, updateOwnedUserEmail, userToUpdate]
	);

	if (!userToUpdate) return null;

	return (
		<Modal
			visible={!!userToUpdate}
			setVisible={() => {
				setUserToUpdate(null);
			}}
			title="Update user email"
			onActionButton={handleSubmit(update)}
			actionName="Update"
			actionColor="green"
			actionDisabled={Object.keys(errors).length !== 0}
			actionLoading={loading}
		>
			<div className="relative p-6 flex-auto">
				<p className="my-4 text-gray-600 text-lg leading-relaxed">
					Enter the new email address
				</p>
				<form>
					<input
						name="email"
						ref={register({
							required: true,
							pattern: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
						})}
						type="text"
						placeholder="Email address"
						className="px-3 py-3 placeholder-gray-400 text-gray-700 relative bg-white bg-white rounded text-sm shadow outline-none focus:outline-none focus:shadow-outline w-full bg-gray-200"
					/>
					{errors.email?.type && (
						<p className="my-2 text-red-600">
							{errors?.email?.type === 'pattern'
								? 'This email is invalid'
								: 'Required field'}
						</p>
					)}
					{error && <p className="text-red-600">An error has occured :(</p>}
				</form>
			</div>
		</Modal>
	);
};

export default User;
