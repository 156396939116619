import React, { useState } from 'react';
import { PlusCircle } from 'react-feather';
import { ListPublicWodCategoriesDocument, useCreatePublicWodCategoryMutation } from '../../generated/gql';
import { useErrorModal } from '../../hooks/useErrorModal';
import update from 'immutability-helper';

const AddCategory = () => {
	const [value, setValue] = useState('');
	const [createPublicWodCategory, { error }] = useCreatePublicWodCategoryMutation({
		update(cache, { data }) {
			const queryData: any = cache.readQuery({
				query: ListPublicWodCategoriesDocument,
				variables:{
					showUnpublished: true,
				}
			});

			if (queryData?.listPublicWodCategories) {
				cache.writeQuery({
					query: ListPublicWodCategoriesDocument,
					variables:{
						showUnpublished: true,
					},
					data: {
						listPublicWodCategories: update(queryData.listPublicWodCategories, {
							result: {
								$push: [data?.createPublicWodCategory],
							},
							total: {
								$apply: (x: number) => x + 1,
							},
						}),
					},
				});
			}
		},
	});
	useErrorModal({
		error,
	});

	return (
		<div className="flex justify-end items-center">
			<input
				className="px-3 py-3 placeholder-gray-400 text-gray-700 relative bg-white bg-white rounded text-sm shadow outline-none focus:outline-none focus:shadow-outline w-1/2 md:w-1/4 mr-3"
				type="text"
				value={value}
				placeholder={'Add Category'}
				onChange={(e) => {
					setValue(e.target.value);
				}}
			/>
			<div onClick={() => {
				if(value !== ''){
					createPublicWodCategory({
						variables: {
							name: {
								en: value,
								fr: value
							},
						},
					})
						.then(() => {
							setValue('');
						})
						.catch((err) => {
							console.log(err);
						});
				}
			}}>
				<PlusCircle color="#fff" className="mr-3" />
			</div>
		</div>
	);
};

export default AddCategory;
