import { SYSTEM_STORE_ACTIONS } from './SystemReducer';

export const setErrorModal = (
	data: {
		title: string;
		body: string;
		onRetry?: () => void;
		onClose?: () => void;
	} | null
) => {
	return {
		type: SYSTEM_STORE_ACTIONS.SET_ERROR_MODAL,
		value: data,
	};
};

export const toggleSidebar = () => {
	return {
		type: SYSTEM_STORE_ACTIONS.TOGGLE_SIDEBAR,
	};
};
