import React, { useCallback } from 'react';
import { EvolutionRange } from '../../generated/gql';

const DayWeekMonthYearSelector = ({
	onSelection,
	selected,
}: {
	onSelection: (selected: EvolutionRange) => void;
	selected: EvolutionRange;
}) => {
	return (
		<div className="text-right text-white my-5">
			<EvolutionSelectorButton
				name="Day"
				color="blue"
				range={EvolutionRange.Day}
				onSelection={onSelection}
				selected={selected === EvolutionRange.Day}
			/>
			<EvolutionSelectorButton
				name="Week"
				color="indigo"
				range={EvolutionRange.Week}
				onSelection={onSelection}
				selected={selected === EvolutionRange.Week}
			/>
			<EvolutionSelectorButton
				name="Month"
				color="purple"
				range={EvolutionRange.Month}
				onSelection={onSelection}
				selected={selected === EvolutionRange.Month}
			/>
			<EvolutionSelectorButton
				name="Year"
				color="pink"
				range={EvolutionRange.Year}
				onSelection={onSelection}
				selected={selected === EvolutionRange.Year}
			/>
		</div>
	);
};

const EvolutionSelectorButton = ({
	name,
	color,
	onSelection,
	range,
	selected,
}: {
	name: string;
	color: string;
	onSelection: (type: EvolutionRange) => void;
	range: EvolutionRange;
	selected: boolean;
}) => {
	const onClick = useCallback(() => {
		onSelection(range);
	}, [range, onSelection]);

	return (
		<button
			className={`mx-2 px-3 py-1 border-b-2 border-${color}-800 hover:bg-${color}-800 ${
				selected && 'bg-' + color + '-800'
			}`}
			onClick={onClick}
		>
			{name}
		</button>
	);
};

export default DayWeekMonthYearSelector;
