import React, { useCallback, useEffect, useState } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import './assets/tailwind.css';
import { ApolloProvider } from '@apollo/client';
import setupApolloClient from './api/ApolloClient';
import { ME } from './api/OwnedUser/queries';
import Modal from './components/Main/Modal';
import Loader from 'react-loader-spinner';
import { useErrorModal } from './hooks/useErrorModal';
import { useDispatch, useSelector } from 'react-redux';
import { setErrorModal } from './store/actions';
import { SystemState } from './store/SystemReducer';
import { Router } from './router/Router';

function App() {
	const [isAuthenticated, setAuthenticated] = useState(false);
	const [loading, setLoading] = useState(true);
	const [apolloClient] = useState(setupApolloClient());
	const error = useSelector((store: SystemState) => store.error);
	const { setError } = useErrorModal();
	const dispatch = useDispatch();

	useEffect(() => {
		Auth.currentAuthenticatedUser()
			.then((user) => {
				if (user) {
					apolloClient
						.query({
							query: ME,
						})
						.then((res) => {
							if (res?.data?.me?.role !== 'user') {
								setAuthenticated(true);
								setLoading(false);
							} else {
								setError({
									title: 'Unauthorized',
									body: 'You are not allowed to access this application',
									onClose: () => {
										Auth.signOut();
									},
								});
								setLoading(false);
							}
						})
						.catch(() => {
							setLoading(false);
						});
				}
			})
			.catch((err) => {
				console.error(err);
				setAuthenticated(false);
				setLoading(false);
			});
	}, [apolloClient, setError]);

	const onRetryPress = useCallback(() => {
		dispatch(setErrorModal(null));
		error?.onRetry!();
	}, [error, dispatch]);

	if (loading) {
		return (
			<div className="w-full h-full absolute flex items-center justify-center rounded">
				<Loader type="Rings" color="#319795" height={125} width={125} />
			</div>
		);
	}

	return (
		<div>
			<ApolloProvider client={apolloClient}>
				<Modal
					visible={!!error}
					setVisible={() => {
						dispatch(setErrorModal(null));
					}}
					title={error?.title!}
					actionName={error?.onRetry && 'Retry'}
					onActionButton={error?.onRetry && onRetryPress}
					onCloseButton={() => {
						console.log('la')
						dispatch(setErrorModal(null))
					}}
				>
					<div className="relative p-6 flex-auto">
						<p className="my-4 text-gray-600 text-lg leading-relaxed">
							{error?.body}
						</p>
					</div>
				</Modal>
				<BrowserRouter>
					<Router isAuthenticated={isAuthenticated} />
				</BrowserRouter>
			</ApolloProvider>
		</div>
	);
}

export default App;
