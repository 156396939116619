import React, { useCallback, useState } from 'react';
import UsersTable from '../components/Users/UsersTable';
import { useErrorModal } from '../hooks/useErrorModal';
import { useDebouncedCallback } from 'use-debounce';
import { Search } from 'react-feather';
import Loader from '../components/Main/Loader';
import { useListOwnedUsersQuery } from '../generated/gql';

export default function Users() {
	const [refetchLoading, setRefetchLoading] = useState(false);
	const [searchValue, setSearchValue] = useState('');
	const { data, error, loading, refetch } = useListOwnedUsersQuery();

	useErrorModal({
		error,
		onRetry: () => refetch(),
	});

	const onRefetch = useCallback(
		(variables: any) => {
			setRefetchLoading(true);
			refetch(variables).finally(() => {
				setRefetchLoading(false);
			});
		},
		[refetch]
	);

	const debounced = useDebouncedCallback((searchValue) => {
		onRefetch({
			limit: data?.listOwnedUsers?.limit,
			offset: 0,
			filter: searchValue,
		});
	}, 500);

	if (loading) {
		return <Loader />;
	}

	if (error) {
		return null;
	}

	return (
		<div className="h-full">
			<h1>Users</h1>
			<div className="flex justify-end items-center">
				<Search color="#fff" className="mr-3" />
				<input
					className="px-3 py-3 placeholder-gray-400 text-gray-700 relative bg-white bg-white rounded text-sm shadow outline-none focus:outline-none focus:shadow-outline w-1/2 md:w-1/4"
					type="text"
					value={searchValue}
					placeholder={'First name / Last name / Email / Nickname'}
					onChange={(e) => {
						setSearchValue(e.target.value);
						debounced.callback(e.target.value);
					}}
				/>
			</div>
			{data?.listOwnedUsers?.result && (
				<UsersTable
					users={data?.listOwnedUsers?.result}
					limit={data?.listOwnedUsers?.limit!}
					total={data?.listOwnedUsers?.total!}
					offset={data?.listOwnedUsers?.offset!}
					refetch={onRefetch}
					loading={loading || refetchLoading}
				/>
			)}
		</div>
	);
}
