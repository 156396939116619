import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { setErrorModal } from '../store/actions';
import { Error, SystemState } from '../store/SystemReducer';
import { ApolloError } from '@apollo/client';
import { useHistory } from 'react-router-dom';

export const useErrorModal = (err?: {
	error?: ApolloError;
	title?: string;
	body?: string;
	onRetry?: Error['onRetry'];
	onClose?: Error['onClose'];
}) => {
	const [_error, setError] = useState<Error | null>(null);
	const dispatch = useDispatch();
	const currentError = useSelector((state: SystemState) => state.error);
	const history = useHistory();

	useEffect(() => {
		if (!err || currentError) return;

		let { error, body, title, onRetry, onClose } = err;

		if (error?.message === 'NOT_AUTHORIZED') {
			onRetry = undefined;
			onClose = () => {
				history.push('/');
			};
			if (!body) {
				body = 'You are not authorized to access this page';
			}
		}

		if (!error?.networkError) {
			onRetry = undefined;
		}

		if (error) {
			dispatch(
				setErrorModal({
					title: title || 'Error',
					body: body || 'An error has occured',
					onRetry,
					onClose,
				})
			);
		}
	}, [err, dispatch, currentError, history]);

	useEffect(() => {
		if (_error) {
			dispatch(setErrorModal(_error as any));
		}
	}, [_error, dispatch]);

	return {
		setError,
	};
};
