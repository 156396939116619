import React, { useCallback, useState } from 'react';
import { useForm } from "react-hook-form";
import TextInput from '../components/Form/TextInput';
import SelectCategory from '../components/Wodbook/SelectCategory';
import {
	AvailableLanguage, ListPublicWodsDocument,
	SessionExerciseBlockType,
	useAddPublicWodBlockMutation,
	useCreatePublicWodMutation, useUpdatePublicWodMutation,
} from '../generated/gql';
import { useErrorModal } from '../hooks/useErrorModal';
import { useHistory } from 'react-router-dom';
import update from 'immutability-helper';

type FormValues = {
	title: string;
	body: string;
	category: string;
};

export default function WodsPublicCreate() {
	const history = useHistory();
	const [loading, setLoading] = useState(false);
	const { register, handleSubmit, errors, getValues } = useForm<FormValues>();
	const [createPublicWod, { error }] = useCreatePublicWodMutation({});
	useErrorModal({ error });
	const [addPublicWodBlock, { error: errorAddPublicWodBlock }] = useAddPublicWodBlockMutation({
		update(cache, { data }) {
			let categoryId = getValues('category')

			const queryData: any = cache.readQuery({
				query: ListPublicWodsDocument,
				variables: {
					categoryId,
				},
			});

			if (queryData?.listPublicWods) {
				cache.writeQuery({
					query: ListPublicWodsDocument,
					variables: {
						categoryId,
					},
					data: {
						listPublicWods: update(queryData.listPublicWods, {
							result: {
								$push: [data?.addPublicWodBlock],
							},
							total: {
								$apply: (x: number) => x + 1,
							},
						}),
					}
				});
			}
		},
	});
	useErrorModal({ error: errorAddPublicWodBlock });
	const [updatePublicWod, { error: errorUpdatePublicWod }] = useUpdatePublicWodMutation();
	useErrorModal({ error: errorUpdatePublicWod });

	const computExpectedScore = useCallback((calories, distance, repetitions, time, weight) => {
		let ExpectedScore = [];
		if(calories) ExpectedScore.push({sort: 'DESC', type: 'CALORIES'})
		if(distance) ExpectedScore.push({sort: 'DESC', type: 'DISTANCE'})
		if(repetitions) ExpectedScore.push({sort: 'DESC', type: 'REPETITIONS'})
		if(time) ExpectedScore.push({sort: 'ASC', type: 'DURATION'})
		if(weight) ExpectedScore.push({sort: 'DESC', type: 'LOAD'})

		return ExpectedScore;
	}, []);

	const onSubmit =
		(data: { title: string, category: string, body: string, calories: boolean, distance: boolean, repetitions: boolean, time: boolean, weight: boolean}) => {
			let wodId = '';
			setLoading(true);
			createPublicWod({
				variables: {
					categoryId: data?.category,
					exercise: {name: {fr: data?.title, en: data?.title}}
				},
			})
				.then((r) => {
					/*console.log('r1', r)*/
					const expectedScores = computExpectedScore(data.calories, data.distance, data.repetitions, data.time, data.weight);
					wodId = r?.data?.createPublicWod?.id as string;
					updatePublicWod({
						variables: {
							wodId,
							expectedScores: expectedScores as []
						}
					}).then(r => {
						/*console.log('r2', r)*/
						addPublicWodBlock({
							variables: {
								content: data?.body,
								lang: 'en' as AvailableLanguage,
								type: 'TEXT' as SessionExerciseBlockType,
								wodId,
							}
							}).then(r => {
								console.log('addPublicWodBlock', r)
								history.push('/wodbook/' + wodId);
								})
								.catch((err) => {
									console.log(err);
								});
						})
						.catch((err) => {
							console.log(err);
						});
				})
				.catch((err) => {
					console.log(err);
				}).finally(() => setLoading(false));
		}

	return (
		<div className="h-full">
			<h1>Create public WOD</h1>
			<div className="">
				<form onSubmit={handleSubmit(onSubmit)} className="flex flex-col">
					<TextInput
						errors={errors}
						register={register}
						name="title"
						placeholder="Title"
						rules={{ required: 'Title is required', maxLength: 50 }}
						type="text"
					/>
					<label
						className="block text-gray-700 text-sm font-bold mb-2"
						htmlFor={'Body'}
					>
						Body
					</label>
					<textarea placeholder={'WOD content'}  ref={register({required: true, maxLength: 5000})} name='body' rows={10} className="px-3 py-3 placeholder-gray-400 text-gray-700 relative bg-white bg-white rounded text-sm shadow outline-none focus:outline-none focus:shadow-outline mb-3" />
					<label
						className="block text-gray-700 text-sm font-bold"
						htmlFor={'Expected Scores'}
					>
						Expected Scores
					</label>
					<div className="flex flex-row justify-between">
						<div>
							<label htmlFor="Calories" className="text-white m-2">Calories</label>
							<input
								name={'calories'}
								ref={register}
								type="checkbox"
							/>
						</div>
						<div>
							<label htmlFor="Distance" className="text-white m-2">Distance</label>
							<input
								name={'distance'}
								ref={register}
								type="checkbox"
							/>
						</div>
						<div>
							<label htmlFor="Time" className="text-white m-2">Time</label>
							<input
								name={'time'}
								ref={register}
								type="checkbox"
							/>
						</div>
						<div>
							<label htmlFor="Weight" className="text-white m-2">Weight</label>
							<input
								name={'weight'}
								ref={register}
								type="checkbox"
							/>
						</div>
						<div>
							<label htmlFor="Repetitions" className="text-white m-2">Repetitions</label>
							<input
								name={'repetitions'}
								ref={register}
								type="checkbox"
							/>
						</div>
					</div>
					<label
						className="block text-gray-700 text-sm font-bold mb-2 mt-4"
						htmlFor={'Category'}
					>
						Category
					</label>
					<SelectCategory register={register} />
					<div className="flex items-center mt-6">
					<button
						className="text-white bg-primary-500 hover:bg-primary-700 font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
						type="submit"
					>
						Create
					</button>
					</div>
				</form>
			</div>
		</div>
	);
}
