import React, { useCallback, useState } from 'react';
import { Menu, X, ChevronDown, ChevronUp } from 'react-feather';
import { Auth } from 'aws-amplify';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { toggleSidebar } from '../../store/actions';
import { SystemState } from '../../store/SystemReducer';
import { useMeQuery } from '../../generated/gql';

const Navbar = () => {
	const { data } = useMeQuery({
		fetchPolicy: 'cache-first',
	});
	const [toggleProfile, setProfileToggled] = useState(false);
	const sidebarToggled = useSelector(
		(state: SystemState) => state.sidebarToggled
	);

	const toggledClass = !toggleProfile ? 'hidden' : '';

	return (
		<nav
			id="navbar"
			className="fixed flex w-full items-center justify-between md:justify-end px-6 py-4"
		>
			<SidebarButton sidebarToggled={sidebarToggled} />
			<Profile
				setProfileToggled={setProfileToggled}
				toggledClass={toggledClass}
				toggleProfile={toggleProfile}
				letter={data?.me?.firstName?.charAt(0).toUpperCase()!}
			/>
		</nav>
	);
};

const SidebarButton = ({ sidebarToggled }: { sidebarToggled: boolean }) => {
	const dispatch = useDispatch();
	let Button = Menu;
	if (sidebarToggled) {
		Button = X;
	}

	return (
		<button
			className="focus:outline-none md:hidden"
			onClick={() => {
				dispatch(toggleSidebar());
			}}
		>
			<Button color="#fff" size={28} />
		</button>
	);
};

const Profile = ({
	letter,
	setProfileToggled,
	toggleProfile,
	toggledClass,
}: {
	letter: string;
	setProfileToggled: any;
	toggleProfile: boolean;
	toggledClass: string;
}) => {
	const history = useHistory();

	const onLogout = useCallback(() => {
		Auth.signOut().then(() => {
			history.go(0);
		});
	}, [history]);

	const Chevron = toggleProfile ? ChevronUp : ChevronDown;

	return (
		<div
			onClick={() => {
				setProfileToggled(!toggleProfile);
			}}
			className="flex items-center relative cursor-pointer"
		>
			<div className="bg-gray-300 w-8 h-8 rounded-full flex items-center justify-center">
				<span className="text-gray-800 font-bold">{letter}</span>
			</div>
			<Chevron className="ml-1" color="#fff" size={16} />
			<div
				className={`bg-white p-3 rounded absolute right-0 ${toggledClass}`}
				style={{ top: 40 }}
			>
				<button onClick={onLogout}>Logout</button>
			</div>
		</div>
	);
};

export default Navbar;
